import { LitElement, html } from 'lit'
import { query } from 'lit/decorators.js'
import { SVG_WINDOWS } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import windowsSupportBannerStyles from './windows-support-banner.styles.scss'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { canShowTrialBanner } from '../../core/hooks/trial-banner'
import { type BannerComponent } from '@getgo/chameleon-web'

export class WindowSupportBanner extends LitElement {
  static readonly tagName = 'goto-windows-support'
  @query('chameleon-banner') private chameleonBanner!: BannerComponent

  learnMoreLink = 'https://support.goto.com/connect/help/how-do-i-download-and-install-the-goto-app'

  static get styles() {
    return windowsSupportBannerStyles
  }

  private getBannerMessage() {
    return getTranslation(`Your OS is no longer supported. For new GoTo updates, you'll need Windows 10 or higher.`)
  }

  private getBannerLinkText() {
    return getTranslation('Learn more')
  }

  private getClosable() {
    // If the user also has a trial banner, this banner must be closable
    if (canShowTrialBanner()) {
      return true
    }
    return false
  }

  render() {
    return html`<chameleon-banner ?closable=${this.getClosable()} @close=${() => this.chameleonBanner.remove()}>
      <chameleon-svg slot="icon">${unsafeSVG(SVG_WINDOWS)}</chameleon-svg>
      <span>${this.getBannerMessage()}</span>
      <a slot="action" class="windows-support-banner-link" href="${this.learnMoreLink}" target="_blank">
        ${this.getBannerLinkText()}
      </a>
    </chameleon-banner>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-windows-support': WindowSupportBanner
  }
}
