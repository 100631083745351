import { navigateToUrl as navigateToUrlFromSingleSpa } from 'single-spa'
import { getDocument, getWindow, navigateToExternal } from '../common/dom-helpers'
import { unrecoverableErrorBanner } from './display-error-banner'
import { getShellLogger } from './logger'
import { v4 as uuidv4 } from 'uuid'

export { encodeStringToBase64url, isEmptyOrNullOrUndefined, not } from './helpers/string'

export const isUserOnline = () => getWindow().navigator.onLine

export const areEquals = (a: readonly string[], b: readonly string[]): boolean =>
  a.length === b.length && !a.some((value, index) => b[index] !== value)

export const randomID = () => uuidv4()

/**
 * Convenience function to help load an external stylesheet into the runtime.
 *
 * This is mainly used for experiences that have external stylesheets and they need
 * to be loaded on mount.
 *
 * @param src the url to the external style sheet
 * @param parent the parent element
 */
export const loadCSS = (src: string, parent?: HTMLElement): Promise<HTMLLinkElement> =>
  new Promise((resolve, reject) => {
    const link = getDocument().createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = src
    link.media = 'all'
    link.onload = () => {
      resolve(link)
    }
    link.onerror = reject
    // sometimes we may need this to attach the link to the parent for us. Other times we just need the link to resolve and be returned so we can
    // attach it ourselves. In container slots we use repeat and we return a link element, the repeat attaches and detaches the link for us.
    if (parent) {
      parent.appendChild(link)
    }
  })

type LoadScriptOptions = {
  readonly async?: boolean
  readonly crossOrigin?: string
  readonly resolveWithGlobal?: string
  readonly parent?: HTMLElement
  readonly esModule?: boolean
}

/**
 * Calls a function to load a JS script, based on whether or not it is an ES Module.
 * This is mainly used by the experience/extension but it can be used to load any js script.
 * @param src - script url to load
 * @param options - set of options to load the script
 * @returns the script loaded
 */
export const loadScript = <T>(src: string, options: LoadScriptOptions = {}) => {
  const { esModule } = options
  if (esModule) {
    return loadESModule<T>(src, options)
  }
  return loadGlobalScript<T>(src, options)
}

/**
 * Load a JS script (for anything not an ES Module) and add it into the DOM.
 * This is mainly used by the experience/extension but it can be used to load any js script.
 * @param src - script url to load
 * @param options - set of options to load the script
 * @returns the script loaded
 */

export const loadGlobalScript = <T>(src: string, options: LoadScriptOptions = {}) =>
  new Promise<T>((resolve, reject) => {
    const { async = true, crossOrigin = null, resolveWithGlobal = '', parent = getDocument().body } = options

    const errorHandler = (e?: string | Event) => {
      unrecoverableErrorBanner()
      getShellLogger().error(e)
      reject(e)
    }
    const script = getDocument().createElement('script')
    script.src = src
    script.async = !!async
    script.crossOrigin = crossOrigin
    script.onload = () => {
      const win = getWindow() as { readonly [key: string]: any }
      resolve(resolveWithGlobal ? win[resolveWithGlobal] : undefined)
    }
    script.onerror = errorHandler

    parent.appendChild(script)
  })
/**
 * Load a JS script using ES Module.
 * This is mainly used by the experience/extension but it can be used to load any js script.
 * @param src - script url to load
 * @param options - set of options to load the script
 * @returns the script loaded
 */
export const loadESModule = <T>(src: string, options: LoadScriptOptions = {}) =>
  new Promise<T>((resolve, reject) => {
    const { crossOrigin = null, resolveWithGlobal = '', parent = getDocument().body } = options

    const uid = randomID()
    const moduleLoadedEventName = `shell:module_loaded:${uid}`
    const moduleFailedEventName = `shell:module_failed:${uid}`

    const removeEventListeners = () => {
      getWindow().removeEventListener(moduleFailedEventName, moduleFailedListener)
      getWindow().removeEventListener(moduleLoadedEventName, moduleLoadedListener)
    }

    const moduleLoadedListener = (moduleLoadedEvent: CustomEvent<{ resolveWithGlobal: string }>) => {
      const win = getWindow() as { readonly [key: string]: any }
      const { resolveWithGlobal } = moduleLoadedEvent.detail
      resolve(resolveWithGlobal ? win[resolveWithGlobal] : undefined)
      removeEventListeners()
    }

    const moduleFailedListener = (moduleFailedEvent: CustomEvent<{ error: Error }>) => {
      reject(moduleFailedEvent.detail.error)
      removeEventListeners()
    }

    getWindow().addEventListener(moduleLoadedEventName, moduleLoadedListener, { once: true })
    getWindow().addEventListener(moduleFailedEventName, moduleFailedListener, { once: true })

    const script = getDocument().createElement('script')
    script.type = 'module'
    script.innerHTML = `
                       try {
                         const moduleScript = await import('${src}');
                         if ('${resolveWithGlobal}' !== '') {
                           window['${resolveWithGlobal}'] = moduleScript;
                         }
                         window.dispatchEvent(new CustomEvent('${moduleLoadedEventName}', {detail: {resolveWithGlobal: '${resolveWithGlobal}'}}));
                       } catch(e) {
                         window.dispatchEvent(new CustomEvent('${moduleFailedEventName}', {detail: {error: e}}));
                       }
                      `
    script.crossOrigin = crossOrigin
    parent.appendChild(script)
  })

/**
 * Executes the navigation for a given url.
 * @param url the destination url as a string
 * @param event event triggering the navigation. If providing preventDefault is called to avoid uncontrolled navigation
 */
export const navigateToUrl = (url: string) => {
  navigateToUrlFromSingleSpa(url)
}

export const reloadPreviousUrl = () => {
  const { history } = getWindow()

  history.back()
  history.go()
}

export const reverseMap = (object: Record<string, string>) =>
  Object.entries(object).reduce(
    (inverseObj, [key, value]) => {
      inverseObj[value] = key
      return inverseObj
    },
    {} as Record<string, string>,
  )
/* istanbul ignore next */
export const noop = () => {}

export const autoResolve =
  <T>(value: T) =>
  (): Promise<T> =>
    Promise.resolve(value)

/**
 * A method to limit the number of times the app can navigate to a url within a certain time frame.
 * @param url the destination url as a string
 * @param maxCount the maximum number of times the user can navigate to the url within the time frame
 * @param maxTimeSinceLastReloadSecs the maximum amount of time allowed in seconds since the last navigation was attempted
 * @returns navigates the app to the url if the conditions are met
 * @throws an error if the app has reached the maximum number of navigations within the time frame
 */
export const navigateToUrlWithRateLimit = (
  url: string,
  maxCount: number = 2,
  maxTimeSinceLastReloadSecs: number = 10,
) => {
  const localStorageKey = `navigateToUrlWithRateLimit_${url}`
  const currentTimeInMs = Date.now()
  const existingAttempt = JSON.parse(
    localStorage.getItem(localStorageKey) || JSON.stringify({ count: 0, lastTimeInMs: currentTimeInMs }),
  )

  const timeSinceLastReload = currentTimeInMs - existingAttempt.lastTime

  // If the last time the app navigated to the url is greater than the max time frame, reset the count
  if (timeSinceLastReload > maxTimeSinceLastReloadSecs * 1000) {
    existingAttempt.count = 0
    existingAttempt.lastTime = currentTimeInMs
    localStorage.setItem(localStorageKey, JSON.stringify(existingAttempt))
  }

  if (existingAttempt.count < maxCount) {
    existingAttempt.count++
    localStorage.setItem(localStorageKey, JSON.stringify(existingAttempt))
    navigateToExternal(url)
  } else {
    // So the user can try again by manually refreshing the page
    localStorage.removeItem(localStorageKey)
    throw new Error(
      `User has reached the maximum number of navigations to ${url} within ${maxTimeSinceLastReloadSecs} seconds`,
    )
  }
}

