import { Container, Plugins } from '@getgo/container-client'
import { getOS } from '../../common/user-agent-helpers'
import { MacOSSupportBannerService } from '../../services/banner/macos-support-banner-service'
import { WindowsSupportBannerService } from '../../services/banner/windows-support-banner-service'
import { isMac, isWindowsContainer } from '../../services/container/helpers'

export const initializeWindowsSupportBanner = () => {
  if (canShowWindowsSupportBanner()) {
    const winSupportBannerService = new WindowsSupportBannerService()
    return winSupportBannerService.show()
  }
}

/**
 * Verifies if the user is on a windows desktop version below than 10
 * @returns true/false
 */
const canShowWindowsSupportBanner = (): boolean => {
  const osMajorVersion = Number(getOS().major)
  return isWindowsContainer() && osMajorVersion < 10
}

export const initializeMacOSSupportBanner = async () => {
  if (await isMacOSDeprecatedVersion()) {
    const macOSSupportBannerService = new MacOSSupportBannerService()
    return macOSSupportBannerService.show()
  }
}

// The `(await Plugins.ContainerInfo.getInfo()).osVersion` property contains the Darwin kernel version on Mac, and not the macOS version.
// See https://en.wikipedia.org/wiki/Darwin_(operating_system) for mapping between both versions.
const MIN_SUPPORTED_DARWIN_MAJOR_VERSION = 20 // corresponds to macOS 11.0

/**
 * Verifies if the user is on a deprecated mac desktop version
 * @returns true/false
 */
const isMacOSDeprecatedVersion = async () => {
  if (isMac() && Container.isPluginAvailable('ContainerInfo')) {
    const osVersion = (await Plugins.ContainerInfo.getInfo()).osVersion
    const majorDarwinVersionParsed = osVersion?.split('.')[0]
    if (majorDarwinVersionParsed && Number(majorDarwinVersionParsed) < MIN_SUPPORTED_DARWIN_MAJOR_VERSION) {
      return true
    }
  }
  return false
}
