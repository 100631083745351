import { html } from 'lit'
import { property } from 'lit/decorators.js'
import { ShellElement } from '../../common/shell-element'
import { emptyStateActionButtonClickedEvent } from './custom-events'
import type { GotoEmptyStateActionButton } from './model'

/**
 * Renders a `chameleon-empty-state` with the given properties.
 *
 * When an action button is clicked, it emits a CustomEvent with the action button
 * type as it's payload
 */
export class GotoEmptyState extends ShellElement {
  @property({ type: String }) svgKey = ''
  @property({ type: String }) title = ''
  @property({ type: String }) subTitle = ''
  @property({ type: Array }) actions: GotoEmptyStateActionButton[] = []

  private renderActions() {
    return this.actions.map(
      ({ type, label, variant = 'secondary' }) => html`
        <chameleon-button
          variant=${variant}
          @click=${() => this.dispatchEvent(emptyStateActionButtonClickedEvent(type))}
        >
          <!--  -->
          ${label}
          <!--  -->
        </chameleon-button>
      `,
    )
  }

  render() {
    return html`<!-- -->
      <topbar-logo class="standalone"></topbar-logo>
      <chameleon-empty-state>
        <chameleon-svg slot="icon">${this.svgKey}</chameleon-svg>
        <p slot="title">${this.title}</p>
        ${this.subTitle}
        <div slot="actions">${this.renderActions()}</div>
      </chameleon-empty-state>
      <!-- -->`
  }
}
