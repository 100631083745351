import { getTranslation } from './../../../services/i18n/i18nUtils'
import { LitElement, html, nothing, type PropertyValues } from 'lit'
import { state, query } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { repeat } from 'lit/directives/repeat.js'
import { t } from '../../../directives/translate'
import createIncidentStyles from './create-incident.styles.scss'
import { getDialogService } from '../../../services/dialog/public-facade'
import { FeatureFlagsVariations } from '../../../services/feature-flags/models'

import {
  getDataForSelectedService,
  getServices,
  postTicket,
  getFileInfo,
  displaySubmissionSuccessSnackbar,
} from './helpers'
import '../../date-picker/date-picker'
import {
  type ResolveServiceExtractData,
  ResolveApiCallResult,
  TrackingEventTicketName,
  type ResolveService,
  type ResolveCategory,
  type FileAttachmentInfo,
  type ResolvePriority,
  type TrackingEventTicket,
} from './models'
import { type GoToDatePicker } from '../../date-picker/date-picker'
import { type AlertComponent, type SelectComponent, type OptionComponent } from '@getgo/chameleon-web'
import '../../file-picker/file-picker'
import { SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons'
import { getShellAnalytics } from '../../../common/shell-api-helpers'
import { getFeatureFlagValue } from '../../../services/feature-flags'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

export class GoToCreateIncident extends LitElement {
  static readonly tagName = 'goto-create-incident'

  @query('#service-placeholder') private readonly servicePlaceholder!: OptionComponent
  @query('#create-incident-category') categorySelect: SelectComponent | undefined
  @query('#create-incident-priority') prioritySelect: SelectComponent | undefined
  @query('#category-placeholder') categoryPlaceholder: OptionComponent | undefined
  @query('#priority-placeholder') priorityPlaceholder: OptionComponent | undefined
  @query('goto-date-picker') datePicker: GoToDatePicker | undefined
  @query('chameleon-alert-v2') alertBox: AlertComponent | undefined

  @state() private selectedFiles: File[] = []
  @state() private availableServices: readonly ResolveService[] = []
  @state() private serviceData: ResolveServiceExtractData = {} as ResolveServiceExtractData
  @state() private categories: readonly ResolveCategory[] = []
  @state() private priorities: readonly ResolvePriority[] = []
  @state() private subject = ''
  @state() private description = ''
  @state() private selectedServiceId = 0
  @state() private selectedCategoryId = 0
  @state() private selectedPriorityId = 0
  @state() private attachedFileSizeError = false
  @state() private disableFilePicker = false
  @state() private isServiceSelected = false
  @state() private showAlertBox = false
  @state() private submitted = false

  private readonly attachmentSizeInBytes = 5_242_880

  async firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties)
    this.availableServices = await getServices()
    if (this.availableServices?.length === 1) {
      this.setCategoriesAndPriorities(this.availableServices[0].id)
    }
  }

  updated() {
    if (this.alertBox) {
      this.alertBox.scrollIntoView()
    }
  }

  static get styles() {
    return createIncidentStyles
  }

  private createCategoryPlaceholder() {
    const catPlaceholder = document.createElement('chameleon-option') as OptionComponent
    catPlaceholder.id = 'category-placeholder'
    catPlaceholder.value = 'category-placeholder'
    catPlaceholder.innerText = getTranslation('Not set')
    return catPlaceholder
  }

  private createPriorityPlaceholder() {
    const priPlaceholder = document.createElement('chameleon-option') as OptionComponent
    priPlaceholder.id = 'priority-placeholder'
    priPlaceholder.value = 'priority-placeholder'
    priPlaceholder.innerText = getTranslation('Not set')
    return priPlaceholder
  }

  private async handleSelectService(event: CustomEvent) {
    this.selectedServiceId = parseInt((event.target as HTMLInputElement).value)
    if (this.selectedServiceId != 0) {
      this.servicePlaceholder?.remove()
      if (this.categorySelect && !this.categoryPlaceholder) {
        this.categorySelect.prepend(this.createCategoryPlaceholder())
        this.selectedCategoryId = 0
      }
      if (this.prioritySelect && !this.priorityPlaceholder) {
        this.prioritySelect.prepend(this.createPriorityPlaceholder())
        this.selectedPriorityId = 0
      }
      this.setCategoriesAndPriorities(this.selectedServiceId)
    }
  }

  private handleCategoryChange(event: Event) {
    this.selectedCategoryId = parseInt((event.target as HTMLInputElement).value)
    this.categoryPlaceholder?.remove()
  }

  private handlePriorityChange(event: Event) {
    this.selectedPriorityId = parseInt((event.target as HTMLInputElement).value)
    this.priorityPlaceholder?.remove()
  }

  private handleSubjectKeyup(event: Event) {
    this.subject = (event.target as HTMLInputElement).value
  }

  private handleDescriptionKeyup(event: Event) {
    this.description = (event.target as HTMLInputElement).value
  }

  private readonly handleFileAttachment = (event: CustomEvent) => {
    this.selectedFiles = event.detail.selectedFiles
  }

  close() {
    if (!this.submitted) {
      this.tracking({ name: TrackingEventTicketName.TICKET_CANCEL })
    }
    getDialogService().close()
  }

  private readonly setCategoriesAndPriorities = async (serviceId: number) => {
    this.serviceData = await getDataForSelectedService(serviceId)
    this.categories = this.serviceData?.categories ?? []
    this.priorities = this.serviceData?.service_priorities ?? []
    this.isServiceSelected = true
  }

  private tracking(event: TrackingEventTicket) {
    switch (event.name) {
      case TrackingEventTicketName.TICKET_SUBMIT:
        getShellAnalytics().track(event.name, {
          action: 'Click',
          event: 'Submit',
          ticketPriority: this.selectedPriorityId,
          ticketCategory: this.selectedCategoryId,
          ticketDate: this.getDueDate(),
          ticketTitle: this.subject,
          ticketDescription: this.description,
          ticketAttach: this.selectedFiles.length >= 1,
        })
        return
      case TrackingEventTicketName.TICKET_ERROR:
        getShellAnalytics().track(event.name, {
          event: 'Validation error',
          error: event.payload.message,
        })
        return
      case TrackingEventTicketName.TICKET_CANCEL:
        getShellAnalytics().track(event.name, {
          action: 'Click',
          event: 'Cancel',
        })
        return
    }
  }

  private renderSubtitle() {
    return html` <chameleon-typography variant="body-medium" class="subtitle"
      >${t(
        'Share any helpful details, and an admin from your organization will get back to you soon.',
      )}</chameleon-typography
    >`
  }

  private renderServices() {
    return this.availableServices?.length > 1
      ? html`
          <chameleon-select
            data-test="create-incident-service"
            label=${t('Service')}
            class="incident-spacing"
            @change=${this.handleSelectService}
          >
            <chameleon-option id="service-placeholder">${t('Select a service')}</chameleon-option>
            ${repeat(
              this.availableServices,
              services => services.name,
              service => html`<chameleon-option value=${service.id}>${service.name}</chameleon-option>`,
            )}
          </chameleon-select>
        `
      : nothing
  }

  private renderCategoryAndPriority() {
    return html` <div class="category-priority incident-spacing">${this.renderCategory()}${this.renderPriority()}</div>`
  }

  private renderCategory() {
    return html`
      <chameleon-select
        disabled=${!this.isServiceSelected}
        @change=${this.handleCategoryChange}
        selected-value=${ifDefined(this.selectedCategoryId)}
        data-test="create-incident-category"
        id="create-incident-category"
        label=${t('Category')}
        size="medium"
        ><chameleon-option id="category-placeholder" value="category-placeholder">${t('Not set')}</chameleon-option>
        ${repeat(
          this.categories,
          category => category.name,
          category => html`<chameleon-option value=${category.id}>${category.name}</chameleon-option>`,
        )}
      </chameleon-select>
    `
  }

  private renderPriority() {
    return html`
      <chameleon-select
        disabled=${!this.isServiceSelected}
        @change=${this.handlePriorityChange}
        selected-value=${ifDefined(this.selectedPriorityId)}
        data-test="create-incident-priority"
        id="create-incident-priority"
        label=${t('Priority')}
        size="medium"
        ><chameleon-option id="priority-placeholder" value="priority-placeholder">${t('Not set')}</chameleon-option>
        ${repeat(
          this.priorities,
          priority => priority.description,
          priority => html`<chameleon-option value=${priority.id}>${priority.description}</chameleon-option>`,
        )}
      </chameleon-select>
    `
  }

  private renderSubject() {
    return html`
      <chameleon-text-field
        disabled=${!this.isServiceSelected}
        fullwidth
        placeholder="Enter something"
        data-test="create-incident-subject"
        class="incident-spacing"
        @keyup=${this.handleSubjectKeyup}
      >
        ${t('Subject')}*
        <span slot="helpertext">Required</span>
      </chameleon-text-field>
    `
  }

  private renderAlert() {
    return this.showAlertBox
      ? html` <chameleon-alert-v2 variant="danger" class="alert-box">
          <chameleon-svg slot="icon">${unsafeSVG(SVG_ALERT_OUTLINED)}</chameleon-svg>
          ${t(`Couldn't submit your ticket. Go ahead and try again.`)}
        </chameleon-alert-v2>`
      : nothing
  }

  private renderDescription() {
    return html`
      <chameleon-text-area
        disabled=${!this.isServiceSelected}
        fullwidth
        resize="vertical"
        maxlength="280"
        data-test="create-incident-description"
        class="incident-spacing"
        @keyup=${this.handleDescriptionKeyup}
      >
        ${t('Description (Optional)')}
      </chameleon-text-area>
    `
  }

  private renderDueDate() {
    return html` <goto-date-picker
      ?disabled=${!this.isServiceSelected}
      class="incident-spacing"
      label=${t('Needed by (optional)')}
      ?requirefuturedate=${true}
    ></goto-date-picker>`
  }

  private renderFilePickerSection() {
    return html`
      <div class="file-upload" data-test="create-incident-filePicker">
        <goto-file-picker
          @change=${this.handleFileAttachment}
          ?disabled=${this.disableFilePicker || !this.isServiceSelected}
        ></goto-file-picker>
        ${this.selectedFiles.length > 0 ? this.renderSelectedFileChips() : nothing}
      </div>
      <div class="file-picker-description">${this.renderAttachFileMessage()}</div>
    `
  }

  private renderActionButtons() {
    return html`
      <div slot="actions" data-test="create-incident-actionButtons">
        <chameleon-button
          class="submit-button"
          @click=${() => this.handleSubmit()}
          ?disabled=${this.attachedFileSizeError || !this.isServiceSelected}
          >${t('Submit')}</chameleon-button
        >
        <chameleon-button data-test="cancel-button" @click=${() => this.close()} variant="secondary"
          >${t('Cancel')}</chameleon-button
        >
      </div>
    `
  }

  private renderAttachFileMessage() {
    return this.attachedFileSizeError
      ? html`<chameleon-typography
          data-test="attached-file-message-error"
          variant="caption-medium"
          color="type-color-danger"
          tag="label"
          class="image-upload-description"
        >
          ${t('A file smaller than 5MB is required')}</chameleon-typography
        >`
      : html`<chameleon-typography
          data-test="attached-file-message"
          variant="caption-medium"
          color=${this.isServiceSelected ? 'type-color-secondary' : 'type-color-disabled'}
          tag="label"
          class="image-upload-description"
        >
          ${t('5MB max file size')}</chameleon-typography
        >`
  }

  private renderSelectedFileChips() {
    return html`<div class="attached-files">
      ${repeat(
        this.selectedFiles,
        file => file.name,
        file => this.renderFileChip(file.name, file.size),
      )}
    </div>`
  }

  private renderFileChip(name: string, size: number) {
    this.disableFilePicker = true
    if (size > this.attachmentSizeInBytes) {
      this.attachedFileSizeError = true
    }
    return html`
      <chameleon-chip-v2 closable @close=${() => this.removeFileHandler(name)} ?error=${this.attachedFileSizeError}
        >${name}</chameleon-chip-v2
      >
    `
  }

  private removeFileHandler(name: string) {
    this.selectedFiles = this.selectedFiles.filter(file => file.name !== name)
    this.disableFilePicker = false
    this.attachedFileSizeError = false
  }

  private async handleSubmit() {
    this.showAlertBox = false

    const fileInfo = await this.getFileAttachmentInfo(this.selectedFiles[0])
    const postTicketResult = await postTicket({
      serviceId: this.selectedServiceId,
      categoryId: this.selectedCategoryId,
      priorityId: this.selectedPriorityId,
      title: this.subject,
      description: this.description,
      date: this.getDueDate(),
      file: !fileInfo?.attachmentError ? fileInfo : undefined,
    })

    if (postTicketResult.status === ResolveApiCallResult.SUCCESS) {
      this.tracking({ name: TrackingEventTicketName.TICKET_SUBMIT })
    }

    if (postTicketResult.status === ResolveApiCallResult.FAILURE) {
      this.tracking({ name: TrackingEventTicketName.TICKET_ERROR, payload: { message: postTicketResult.message } })
    }

    if (postTicketResult.status === ResolveApiCallResult.SUCCESS && !fileInfo?.attachmentError) {
      displaySubmissionSuccessSnackbar()
      this.submitted = true
      this.close()
    } else {
      this.showAlertBox = true
    }
  }

  private async getFileAttachmentInfo(file: File | undefined): Promise<FileAttachmentInfo | undefined> {
    if (!file) {
      return undefined
    }
    return await getFileInfo(file)
  }

  private getDueDate() {
    return this.datePicker?.['date']
  }

  render() {
    return html`
      <chameleon-dialog open closable size="large" @closed=${this.close} close-button-label=${t('Close dialog')}>
        <span slot="title">${t('New service ticket')}</span>
        ${this.renderAlert()} ${this.renderSubtitle()}${this.renderServices()} ${this.renderCategoryAndPriority()}
        ${this.renderSubject()} ${this.renderDescription()} ${this.renderDueDate()}
        ${getFeatureFlagValue(FeatureFlagsVariations.SHELL_RESOLVE_USER_ACCESS_ATTACHMENT)
          ? this.renderFilePickerSection()
          : nothing}
        ${this.renderActionButtons()}
      </chameleon-dialog>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-create-incident': GoToCreateIncident
  }
}
