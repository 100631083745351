import type { WindowReferencedNotification } from '../../services/display-notification'
import { ShellElement } from '../../common/shell-element'

/**
 * Manage list of notifications for re-use between different ways of displaying them.
 *
 * Bookmarks:
 * - https://lit.dev/docs/components/events/
 * - https://lit.dev/docs/composition/controllers/
 * - https://www.fast.design/docs/fast-element/observables-and-state
 */

export abstract class AbstractNotificationLitElement<T extends WindowReferencedNotification> extends ShellElement {
  protected notifications: T[] = []

  connectedCallback() {
    super.connectedCallback()
    this.setupEventHandlers()
  }

  protected addNotification(data: T): void {
    // https://jira.ops.expertcity.com/browse/SCORE-472
    const { target, ...event } = data
    const defaultView = target ?? this.ownerDocument.defaultView
    if (defaultView === this.ownerDocument.defaultView) {
      const notification = {
        ...event,
      }
      this.notifications.push(notification as T)
      this.requestUpdate()
    }
  }

  /**
   * Instead of overwriting this._notifications or splicing it, at least we don't lose references in limbo.
   * In both case, we're mutating the element properties, but splicing doesn't overwrite completely.
   *
   * Better yet, open to discussion:
   * - https://github.com/microsoft/fast/blob/604390a1/packages/web-components/fast-element/src/observation/array-observer.ts
   * - https://www.fast.design/docs/fast-element/observables-and-state
   */
  protected abstract removeNotification(data: T): void

  /**
   * This method is called at connectedCallback and anything added to
   * unsubscribeFunctions will be taken care of at disconnectedCallback.
   */
  protected abstract setupEventHandlers(): void
}
