import { ShellElement } from '../../../common/shell-element'
import { html, nothing, type PropertyValues } from 'lit'
import { property, state } from 'lit/decorators.js'
import { SVG_EDIT_OUTLINED, SVG_PRESENCE_DND, SVG_TRASH_OUTLINED } from '@getgo/chameleon-icons'
import { type OneTimePeriod } from '../schedule-manager/models'
import scheduleCustomHoursCardStyles from './schedule-custom-hours-card.styles.scss'
import { t } from '../../../directives/translate'
import { formatDateTimeRange } from '../../../services/i18n'
import { dateTimeFormatOptions } from '../schedule-settings-models'
import { isOneTimePeriodInThePast } from '../helpers'

export const GoToScheduleCustomHoursCardEvents = {
  edit: 'edit',
  delete: 'delete',
}

export class GoToScheduleCustomHoursCard extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours-card'

  @property({ type: Object }) oneTimePeriod: OneTimePeriod = {
    name: '',
    startDate: '',
    endDate: '',
    dndEnabled: false,
  }
  @property({ type: Boolean }) isScheduleEnabled = false

  @state() isScheduleInThePast: boolean = false

  static get styles() {
    return scheduleCustomHoursCardStyles
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties)
    if (changedProperties.has('oneTimePeriod')) {
      this.oneTimePeriodHasPassed()
    }
  }

  private oneTimePeriodHasPassed() {
    if (this.oneTimePeriod.endDate && this.oneTimePeriod.startDate) {
      this.isScheduleInThePast = isOneTimePeriodInThePast(this.oneTimePeriod.endDate)
    }
  }

  private getTypographyColor(isEnabled: boolean): string {
    return isEnabled ? 'type-color-default' : 'type-color-disabled'
  }

  render() {
    return html`<div class="custom-hours-card">
      <div class="custom-hours-card-information">
        <div class="custom-hours-card-information-title custom-hours-card-information-row">
          <chameleon-typography
            variant="body-small-strong"
            tag="h3"
            color=${this.getTypographyColor(this.isScheduleEnabled)}
            >${this.oneTimePeriod.name}</chameleon-typography
          >
          ${this.renderPastStatus()}
        </div>
        <chameleon-typography
          class="custom-hours-card-information-row"
          variant="caption-medium"
          color=${this.getTypographyColor(this.isScheduleEnabled)}
          >${this.getFormattedDate(this.oneTimePeriod.startDate, this.oneTimePeriod.endDate)}</chameleon-typography
        >
        ${this.renderDNDSection()}
      </div>
      <div class="custom-hours-card-actions">${this.renderDeleteButton()} ${this.renderEditButton()}</div>
    </div>`
  }

  private handleEditClick() {
    this.dispatchEvent(
      new CustomEvent<{ oneTimePeriod: OneTimePeriod }>(GoToScheduleCustomHoursCardEvents.edit, {
        bubbles: true,
        detail: { oneTimePeriod: this.oneTimePeriod },
      }),
    )
  }

  private handleDeleteClick() {
    this.dispatchEvent(
      new CustomEvent<{ oneTimePeriod: OneTimePeriod }>(GoToScheduleCustomHoursCardEvents.delete, {
        bubbles: true,
        detail: { oneTimePeriod: this.oneTimePeriod },
      }),
    )
  }

  private renderEditButton() {
    return html`<chameleon-icon-button
        id="edit-custom-hours"
        size="small"
        class="custom-hours-card-edit"
        variant="secondary"
        ?disabled=${!this.isScheduleEnabled}
        @click=${() => this.handleEditClick()}
        ><chameleon-svg>${SVG_EDIT_OUTLINED}</chameleon-svg></chameleon-icon-button
      >
      <chameleon-tooltip-v3 position="bottom-center" trigger-id="edit-custom-hours"
        >${t('Edit')}
      </chameleon-tooltip-v3> `
  }

  private renderDeleteButton() {
    return html`<chameleon-icon-button
        id="delete-custom-hours"
        size="small"
        class="custom-hours-card-delete"
        variant="secondary"
        ?disabled=${!this.isScheduleEnabled}
        @click=${() => this.handleDeleteClick()}
        ><chameleon-svg>${SVG_TRASH_OUTLINED}</chameleon-svg></chameleon-icon-button
      >
      <chameleon-tooltip-v3 position="bottom-center" trigger-id="delete-custom-hours"
        >${t('Delete')}
      </chameleon-tooltip-v3> `
  }

  private getSvgClass() {
    return `custom-hours-card-dnd-enabled-icon ${this.isScheduleEnabled ? '' : 'disabled-svg'}`
  }

  private renderDNDSection() {
    if (this.isScheduleInThePast) {
      return nothing
    }

    if (this.oneTimePeriod.dndEnabled) {
      return html`<div class="custom-hours-card-dnd-enabled">
        <chameleon-svg class=${this.getSvgClass()}>${SVG_PRESENCE_DND}</chameleon-svg
        ><chameleon-typography variant="caption-medium" color=${this.getTypographyColor(this.isScheduleEnabled)}
          >${t('Notifications will be turned off during this time')}</chameleon-typography
        >
      </div>`
    }
    return html`<chameleon-typography
      variant="caption-medium"
      class="custom-hours-card-dnd-disabled"
      color=${this.getTypographyColor(this.isScheduleEnabled)}
      >${t('Notifications may still come through, depending on your profile status')}</chameleon-typography
    >`
  }

  private renderPastStatus() {
    if (this.isScheduleInThePast) {
      return html`<chameleon-chip-v2
        size="xsmall"
        class="custom-hours-card-information-past"
        name="Past"
        disabled=${!this.isScheduleEnabled}
        >${t('Past')}</chameleon-chip-v2
      >`
    }
    return nothing
  }

  private getFormattedDate(startDate: string, endDate: string) {
    return formatDateTimeRange(new Date(startDate), new Date(endDate), dateTimeFormatOptions)
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours-card': GoToScheduleCustomHoursCard
  }
}
