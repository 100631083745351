import { getExternalInterface } from '../../external-interface'
import { shouldUsePlugin } from '../../external-interface/utils'
import { type UserPreferencesAPI, type UserPreferencesConnectorParams } from '../models'
import { UserPreferencesConnectorAdapter } from './user-preferences-connector-adapter'
import { UserPreferencesServiceConnector } from './user-preferences.connector'
import { UserAccountPreferencesServiceConnector } from './user-account-preferences.connector'
import { FeatureFlagsVariations } from '../../feature-flags/models'
import { getFeatureFlagValue } from '../../feature-flags'

let userPreferencesConnector: UserPreferencesAPI

export const createUserPreferencesConnector = (params?: UserPreferencesConnectorParams): UserPreferencesAPI => {
  const userPreferencesAPI: UserPreferencesAPI = getFeatureFlagValue<boolean>(
    FeatureFlagsVariations.SHELL_USER_PREFERENCES_V2_ENABLED,
  )
    ? new UserAccountPreferencesServiceConnector()
    : new UserPreferencesServiceConnector(params)
  if (getExternalInterface().isCompanion || (getExternalInterface().isIntegration && shouldUsePlugin())) {
    return new UserPreferencesConnectorAdapter(userPreferencesAPI)
  }
  return userPreferencesAPI
}

export const getUserPreferencesConnector = (params?: UserPreferencesConnectorParams): UserPreferencesAPI => {
  if (!userPreferencesConnector) {
    userPreferencesConnector = createUserPreferencesConnector(params)
  }

  return userPreferencesConnector
}

export type UserPreferencesConnector = UserPreferencesServiceConnector
