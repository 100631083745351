import { html } from 'lit'
import { ShellElement } from '../../common/shell-element'
import { getShellApiInstance } from '../../common/shell-api-helpers'

export class GoToIntegrationUserMenu extends ShellElement {
  static readonly tagName = 'goto-integration-user-menu'

  private readonly givenName = getShellApiInstance().user.name.givenName
  private readonly familyName = getShellApiInstance().user.name.lastName
  private readonly url = getShellApiInstance().user.avatar?.isDefault
    ? ''
    : (getShellApiInstance().user.avatar?.urlMedium ?? '')

  render() {
    return html`<goto-avatar-popover-menu
      .familyName=${this.familyName}
      .givenName=${this.givenName}
      .profileImageUrl=${this.url}
    ></goto-avatar-popover-menu>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-integration-user-menu': GoToIntegrationUserMenu
  }
}
