import { getSearchHistory, removeFromSearchHistory } from './../helpers'
import { SVG_CLOSE_FILLED, SVG_HISTORY_FILLED } from '@getgo/chameleon-icons'
import { html, nothing } from 'lit'
import { property, query } from 'lit/decorators.js'
import { repeat } from 'lit/directives/repeat.js'
import { ShellElement } from '../../../common/shell-element'
import searchHistory from './search-history.styles.scss'
import { t } from '../../../directives/translate'
import type { GlobalSearchQueryUI } from '../global-search-models'
import { executeSearch } from '../namespace'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import type { ListboxComponent } from '@getgo/chameleon-web'
import type { ListboxEvent } from '../search-field/global-search-field'
import { trackGlobalSearch } from '../analytics/analytics'

export interface SearchHistoryProps {
  readonly searchHistory: readonly GlobalSearchQueryUI[]
}
export class SearchHistory extends ShellElement implements SearchHistoryProps {
  static readonly tagName = 'goto-search-history'

  @property({ type: Array }) searchHistory: readonly GlobalSearchQueryUI[] = []
  @query('chameleon-listbox') private readonly chameleonListbox: ListboxComponent | undefined

  static get styles() {
    return searchHistory
  }

  public getListbox() {
    return this.chameleonListbox
  }

  protected updated(): void {
    this.dispatchEvent(new CustomEvent<ListboxEvent>('listboxrendered', { detail: { listbox: this.getListbox() } }))
  }

  private async handleDeleteClick(event: UIEvent, query: GlobalSearchQueryUI) {
    const eventType = event.detail ? 'click' : 'keydown'
    trackGlobalSearch({
      searchString: query.query,
      eventName: 'GoTo > Global Search Result Delete History Item',
      eventType,
      properties: {},
    })
    event.stopPropagation()
    event.preventDefault()
    await removeFromSearchHistory(query)
    this.searchHistory = await getSearchHistory()
  }

  private handleSearchHistoryItemClick(e: UIEvent, query: GlobalSearchQueryUI) {
    const eventType = e.detail ? 'click' : 'keydown'
    trackGlobalSearch({
      searchString: query.query,
      eventName: 'GoTo > Global Search Result History Item',
      eventType,
      properties: {},
    })
    executeSearch(query)
    this.dispatchEvent(new Event('item-click'))
  }

  private renderSearchQueryItem(query: GlobalSearchQueryUI) {
    return html` <chameleon-listbox-item
      @click=${(e: UIEvent) => this.handleSearchHistoryItemClick(e, query)}
      class="search-history-item"
      ><div class="listbox-item-container">
        <chameleon-svg class="history-icon">${unsafeSVG(SVG_HISTORY_FILLED)}</chameleon-svg>
        <chameleon-typography variant="body-medium" class="history-query">${query.query}</chameleon-typography>
        <chameleon-interactive-sub-item
          class="delete-sub-item"
          @click=${(event: UIEvent) => this.handleDeleteClick(event, query)}
          label=${t('Delete')}
          ><chameleon-icon-button
            size="small"
            variant="primary"
            id="delete-sub-item-icon"
            class="delete-search-history-item"
            label=${t('Delete search history item')}
          >
            <chameleon-svg>${unsafeSVG(SVG_CLOSE_FILLED)}</chameleon-svg></chameleon-icon-button
          ></chameleon-interactive-sub-item
        >
      </div></chameleon-listbox-item
    >`
  }

  render() {
    return html`${this.searchHistory?.length
      ? html`<chameleon-typography
            class="recent-searches-header"
            variant="caption-small-strong"
            color="type-color-secondary"
            >${t('Recent searches')}</chameleon-typography
          >
          <chameleon-listbox class="search-history">
            ${repeat(
              this.searchHistory.slice(0, 5),
              searchQuery => searchQuery,
              searchQuery => this.renderSearchQueryItem(searchQuery),
            )}</chameleon-listbox
          >`
      : nothing}`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-search-history': SearchHistory
  }
}
