/* istanbul ignore file */
import type { SingleSpaLitProps } from '../../common'
import { singleSpaLit } from '../../common'
import { html } from 'lit'
import type { GoToSettingsProps } from './settings'
import { GOTO_CONTENT_SELECTOR } from '../../common/container'

type GoToSettingsLitProps = SingleSpaLitProps & Partial<GoToSettingsProps>

const lifecycles = singleSpaLit<GoToSettingsLitProps>({
  template: props => html`<goto-settings .settingLinks=${props?.settingLinks ?? []}></goto-settings>`,
  domElement: GOTO_CONTENT_SELECTOR,
})

export default lifecycles
