import { KEYBOARD_SHORTCUT_SETTINGS_ROUTE, SHELL_SETTINGS_ROUTE } from './../../common/routes'
import { noop, navigateToUrl } from '../../common/helpers'
import { openNewTabFor } from '../../common/dom-helpers'
import { environment } from '../../environments'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { getShellAuth, getShellNamespaces, getShellAnalytics } from '../../common/shell-api-helpers'
import type { MenuItem, SeparatorMenuItem } from '../menu-item/menu-item-models'
import { MenuItemType } from '../menu-item/menu-item-models'
import type { ResolveNamespace } from '../../services/namespaces/resolve-namespace'
import { RESOLVE_NAMESPACE } from '../../services/namespaces/resolve-namespace'
import { html } from 'lit'
import { setupUserReport } from '../user-report'
import { getExternalInterface } from '../../services/external-interface/external-interface-adapter'
import { isGoToMeetingCustomer } from '../../services/identity/entitlement'
import { isIntegrationMobile } from '../../core/integrations-helpers'

export const separatorItem: SeparatorMenuItem = {
  type: MenuItemType.SEPARATOR,
}

export const separatorItemForIntegration: SeparatorMenuItem = {
  type: MenuItemType.SEPARATOR,
  dataTest: 'integration-separator-item',
  isVisible: () => getExternalInterface().isIntegration,
}

const editProfileItem: MenuItem = {
  href: '#',
  type: MenuItemType.NORMAL,
  executeFunction: () => openNewTabFor(environment().profileUrl),
  labelKey: asTranslationKey('Edit Profile'),
  isExternalLink: true,
  dataTest: 'edit-profile',
}

const aboutItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: noop,
  labelKey: asTranslationKey('About'),
  dataTest: 'about',
  isVisible: () => false, // The version is displayed in the last item of avatar popover menu. When chameleon supports a way to implement a sub menu we should enable this. https://jira.ops.expertcity.com/browse/SCORE-1217
}

const updateItem: MenuItem = {
  type: MenuItemType.CUSTOM,
  render: () =>
    html`<goto-avatar-update-menu-item role=${'menuitem'} id="update-menu-item"></goto-avatar-update-menu-item>`,
  dataTest: 'update',
}

const keyboardShortcutSettingsItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: KEYBOARD_SHORTCUT_SETTINGS_ROUTE,
  executeFunction: () => navigateToUrl(KEYBOARD_SHORTCUT_SETTINGS_ROUTE),
  labelKey: asTranslationKey('Keyboard shortcuts'),
  isVisible: () =>
    !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_HOTKEY_SETTINGS) && !getExternalInterface().isIntegration,
  dataTest: 'keyboard-shortcuts',
}

const settingsItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: SHELL_SETTINGS_ROUTE,
  executeFunction: () => navigateToUrl(SHELL_SETTINGS_ROUTE),
  labelKey: asTranslationKey('Settings'),
  isVisible: () => true,
  dataTest: 'settings',
}

const createIncidentItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => {
    const resolveNamespace = getShellNamespaces().retrieve<ResolveNamespace>(RESOLVE_NAMESPACE)
    resolveNamespace.commands.openCreateIncidentDialog.execute()
    getShellAnalytics().track('GoTo > Open Ticket Resolve', { action: 'Click', event: 'Open', from: 'Topbar' })
  },
  labelKey: asTranslationKey('Contact my admin'),
  isVisible: () => {
    const resolveNamespace = getShellNamespaces().retrieve<ResolveNamespace>(RESOLVE_NAMESPACE)
    return resolveNamespace.commands.openCreateIncidentDialog.isHandled()
  },
  dataTest: 'createResolveIncident',
}

const gotoConnectQuickStartGuideItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => openNewTabFor(environment().gotoConnectQuickStartGuideUrl),
  labelKey: asTranslationKey('Quick start guide'),
  isExternalLink: true,
  isVisible: () =>
    getExternalInterface().isIntegration &&
    !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_SHOW_GTC_QUICK_START_GUIDE),
  dataTest: 'gtc-quick-start-guide',
}

const helpItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => openNewTabFor(environment().helpUrl),
  labelKey: asTranslationKey('Get help'),
  isExternalLink: true,
  isVisible: () => getExternalInterface().isIntegration,
  dataTest: 'help',
}

const tutorialsItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => openNewTabFor(environment().tutorialsAndVideosUrl),
  labelKey: asTranslationKey('Tutorials and videos'),
  isExternalLink: true,
  isVisible: () => getExternalInterface().isIntegration,
  dataTest: 'tutorials-and-videos',
}

const communityForumItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => openNewTabFor(environment().communityForumUrl),
  labelKey: asTranslationKey('Community forum'),
  isExternalLink: true,
  isVisible: () => getExternalInterface().isIntegration,
  dataTest: 'community-forum',
}

const feedbackG2MItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => openNewTabFor(environment().feedbackGoToMeetingUrl),
  isExternalLink: true,
  labelKey: asTranslationKey('Give feedback'),
  isVisible: () => isGoToMeetingCustomer() && getExternalInterface().isIntegration,
  dataTest: 'feedbackG2M',
}

const feedbackItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => {
    const { openFeedbackDialog } = setupUserReport(window)
    openFeedbackDialog()
  },
  labelKey: asTranslationKey('Give feedback'),
  isVisible: () => !isGoToMeetingCustomer() && getExternalInterface().isIntegration,
  dataTest: 'feedback',
}

const logoutItem: MenuItem = {
  type: MenuItemType.NORMAL,
  href: '#',
  executeFunction: () => getShellAuth().logout(),
  labelKey: asTranslationKey('Sign out'),
  dataTest: 'logout',
}

export const menuItems: readonly MenuItem[] = [
  separatorItem,
  editProfileItem,
  separatorItem,
  aboutItem,
  updateItem,
  keyboardShortcutSettingsItem,
  settingsItem,
  createIncidentItem,
  separatorItemForIntegration,
  gotoConnectQuickStartGuideItem,
  helpItem,
  tutorialsItem,
  communityForumItem,
  feedbackG2MItem,
  feedbackItem,
  separatorItem,
  logoutItem,
]

export const menuItemsIntegrationMobile: readonly MenuItem[] = [
  separatorItem,
  editProfileItem,
  separatorItem,
  helpItem,
  tutorialsItem,
  communityForumItem,
  feedbackItem,
  separatorItem,
  logoutItem,
]

export const getMenuItems = (): readonly MenuItem[] => (isIntegrationMobile() ? menuItemsIntegrationMobile : menuItems)
