import { formatDateTime, formatDateTimeRange, formatLocale, getCurrentLocale } from '../../services/i18n'
import { type WorkPeriod } from './schedule-manager/models'
import { getUserTimezone } from '../../common/user-helpers'
import { type EndTimeOptions, timeFormatOptions } from './schedule-settings-models'

export const timeIntervalMinutes = 15

const isISOTimeStringValid = (timeString: string) => {
  const regExp = /^([0-9]|[01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
  return regExp.test(timeString)
}

const parseTime = (timeString: string) => {
  if (!isISOTimeStringValid(timeString)) {
    return null
  }

  return timeString.split(':').map(Number)
}

export const newDateTime = (ISOTime: string) => {
  const parsedTime = parseTime(ISOTime)
  if (parsedTime) {
    const [hours, minutes, seconds] = parsedTime
    return new Date().setHours(hours, minutes, seconds, 0)
  }
}

export const formatISOTime = (ISOTime: string): string => {
  const dateTime = newDateTime(ISOTime) ?? new Date().setHours(0, 0, 0, 0)
  return formatDateTime(dateTime, timeFormatOptions)
}

export const formatISOTimeRange = (ISOStartTime: string, ISOEndTime: string): string => {
  const parsedStartTime = parseTime(ISOStartTime)
  const parsedEndTime = parseTime(ISOEndTime)
  if (parsedStartTime && parsedEndTime) {
    const [startHours, startMinutes, startSeconds] = parsedStartTime
    const [endHours, endMinutes, endSeconds] = parsedEndTime
    return formatDateTimeRange(
      new Date().setHours(startHours, startMinutes, startSeconds),
      new Date().setHours(endHours, endMinutes, endSeconds),
      timeFormatOptions,
    )
  }

  return formatDateTime(new Date().setHours(0, 0, 0, 0), timeFormatOptions)
}

export const initialTimeOfTheDay = '00:00:00'
export const lastTimeOfTheDay = '23:59:59'

export const getListOfTimeForOneDayWithInterval = (intervalInMinutes: number) => {
  const result = []

  const currentTime = new Date()
  currentTime.setHours(0, 0, 0, 0)

  const originalDay = currentTime.getDay()

  while (currentTime.getDay() === originalDay) {
    result.push(currentTime.toTimeString().split(' ')[0])
    currentTime.setMinutes(currentTime.getMinutes() + intervalInMinutes)
  }
  return result
}

//TODO: update with assertSchema in this story: https://jira.ops.expertcity.com/browse/SCORE-3512
export const isWorkPeriod = (partialWorkPeriod: Partial<WorkPeriod>): partialWorkPeriod is WorkPeriod =>
  !!(partialWorkPeriod.dayOfWeek && partialWorkPeriod.startTime && partialWorkPeriod.endTime)

export const dateTimeIntervalsOverlap = (startA: number, endA: number, startB: number, endB: number) =>
  startA < endB && endA > startB

/**
 * Maps the end time options with a hidden property for the end time dropdown
 * @returns the list of end time options
 */
export const getAllEndTimeOptions = (): EndTimeOptions[] =>
  getListOfTimeForOneDayWithInterval(timeIntervalMinutes)
    .map(int => ({ value: int, hidden: false }))
    .concat({ value: lastTimeOfTheDay, hidden: false })

/**
 * Sets to true the hidden property on the invalid end time options based on the start time
 * @param startTime the currently selected start time
 * @param endTimeOptions the possible end time options
 * @returns the list of end time options with the hidden property set
 */
export const getValidEndTimeOptions = (startTime: string, endTimeOptions: EndTimeOptions[]) => {
  const indexTime = endTimeOptions.findIndex(({ value }) => value === startTime)
  return endTimeOptions.map((option, index) => ({ ...option, hidden: index <= indexTime }))
}

/**
 * Gets the next valid end time based on the currently selected start time and end time
 * @param startTime the currently selected start time
 * @param endTime the currently selected end time
 * @param endTimeOptions the possible end time options
 * @returns the next valid end time
 */
export const getNextValidEndTime = (startTime: string, endTime: string, endTimeOptions: EndTimeOptions[]): string => {
  const startIndex = endTimeOptions.findIndex(({ value }) => value === startTime)
  const endIndex = endTimeOptions.findIndex(({ value }) => value === endTime)
  if (startIndex !== -1 && endIndex !== -1 && startIndex >= endIndex) {
    return endTimeOptions[startIndex + 1].value
  }
  return endTime
}

/**
 * Formats a given date into a specific timezone and returns it in the desired format
 * @param date the date object to format
 * @param timezone the target timezone in which the date should be formatted
 * @param format the desired output format:
 *    - 'date' returns only the date in 'YYYY-MM-DD' format
 *    - 'time' returns only the time in 'HH:mm:ss' format
 *    - 'datetime' returns the full date and time in 'YYYY-MM-DDTHH:mm:ss' format
 * @returns a formatted string representing the date in the specified timezone and format
 */
export const formatDateToTimezone = (date: Date, timezone: string, format: 'date' | 'time' | 'datetime'): string => {
  const formatter = new Intl.DateTimeFormat('UTC', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // ensures 24h format
  })

  const parts = formatter.formatToParts(date)
  const components = parts.reduce(
    (acc, part) => {
      acc[part.type] = part.value
      return acc
    },
    {} as Record<Intl.DateTimeFormatPartTypes, string>,
  )

  if (components.hour === '24') {
    components.hour = '00'
  }

  const { year, month, day, hour, minute, second } = components

  if (format === 'date') {
    return `${year}-${month}-${day}`
  } else if (format === 'time') {
    return `${hour}:${minute}:${second}`
  } else {
    return `${year}-${month}-${day}T${hour}:${minute}:${second}`
  }
}

/**
 * Gets the current local date, time or date-time of the user based on the user's timezone
 * @param format 'date' for YYYY-MM-DD, 'time' for HH:mm:ss or 'datetime' for YYYY-MM-DDTHH:mm:ss
 * @returns the formatted current local date, time or date-time as a string
 */
export const getCurrentLocalDateFormatted = (format: 'date' | 'time' | 'datetime') => {
  const userTimezone = getUserTimezone()
  const currentDate = new Date()
  return formatDateToTimezone(currentDate, userTimezone, format)
}

/**
 * Rounds the current time up to the next specified interval
 * @param roundingIntervalMins the interval in minutes
 * @returns the Date object with rounded UTC time
 */
export const getRoundedDateTime = (roundingIntervalMins: number): Date => {
  const now = new Date()

  const minutes = now.getMinutes()
  const roundedMinutes = (Math.floor(minutes / roundingIntervalMins) + 1) * roundingIntervalMins

  const roundedDate = new Date(now)
  roundedDate.setMinutes(roundedMinutes)
  roundedDate.setSeconds(0)
  roundedDate.setMilliseconds(0)

  return roundedDate
}

/**
 * @returns the user locale in the format accepted by chameleon-date-picker
 */
export const getUserLocaleInDatePickerFormat = () => formatLocale(getCurrentLocale(), '-')

/**
 * Calculates the maximun calendar end date a year after the start day or the current date
 * @param startDay in the format 'YYYY-MM-DD'
 * @returns the maximum calendar end date
 */
export const getMaximumCalendarEndDate = (startDay: string) => {
  const startDate = new Date(startDay)
  startDate.setFullYear(startDate.getFullYear() + 1)
  return formatDateToTimezone(startDate, getUserTimezone(), 'date')
}
