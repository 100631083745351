import { css, html } from 'lit'
import { property } from 'lit/decorators.js'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'
import { type HighlightTextRange, highlightKeywords } from './utils'
import { type GotoTextHighlightComponent, GOTO_TEXT_HIGHLIGHT } from '@goto/shell-common'
import { ShellElement } from '../../common/shell-element'
/**
 * Component that highlights keywords
 */
export class GotoTextHighlight extends ShellElement implements GotoTextHighlightComponent {
  static readonly tagName = GOTO_TEXT_HIGHLIGHT
  @property({ type: Boolean })
  ignoreCase = false
  @property({ type: Boolean })
  ignoreAccents = false
  @property({ type: Boolean })
  phoneNumbers = false
  @property({ type: Array })
  keywords: readonly string[] = []
  @property({ type: String })
  value = ''

  render() {
    return html`
      <div class="highlight-container">
        ${unsafeHTML(
          highlightKeywords(
            this.value,
            this.keywords,
            (range: HighlightTextRange) => `<span class="highlight">${range.value}</span>`,
            { ignoreAccents: this.ignoreAccents, ignoreCase: this.ignoreCase, phoneNumbers: this.phoneNumbers },
          ),
        )}
      </div>
    `
  }

  static styles = css`
    .highlight {
      background-color: var(--goto-highlight-background-color, #f2dd06b2);
      color: var(--goto-highlight-type-color, var(--goto-type-color-default));
      font-weight: bold;
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-text-highlight': GotoTextHighlight
  }
}
