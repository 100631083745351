import { state } from 'lit/decorators.js'
import { GoToBaseNavigation } from './base-navigation'
import { getEventBus } from '../../../services/namespaces'
import { type NavigationEvents, NavigationNamespace } from '../../../services/navigation/event-bus'
import { getNavigationItemsService } from '../navigation-item/navigation-items-service'
import { type NavigationItemsService } from '../navigation-item/model'
import { type NavigationItemUI } from '@goto/shell-common'

interface GoToNavigationProps {
  readonly links: readonly NavigationItemUI[]
}

export abstract class GotoBaseNavigationLinks extends GoToBaseNavigation implements GoToNavigationProps {
  @state() links: readonly NavigationItemUI[] = []

  protected readonly navigationItemsService: NavigationItemsService

  constructor() {
    super()
    this.navigationItemsService = getNavigationItemsService()
  }

  connectedCallback() {
    super.connectedCallback()
    this.setNavLinks()

    const { linksUpdated: linksUpdated } = getEventBus().subscribeTo<
      typeof NavigationNamespace,
      typeof NavigationEvents
    >(NavigationNamespace)

    linksUpdated.on(this.handleLinksUpdated)

    this.unsubscribeFunctions.push(() => {
      linksUpdated.removeListener(this.handleLinksUpdated)
    })
  }

  abstract linksUpdated(): Promise<void>

  private setNavLinks() {
    this.links = this.navigationItemsService.getNavigationItemsFromCache()
    this.linksUpdated().then(() => this.requestUpdate())
  }

  private readonly handleLinksUpdated = async () => {
    this.setNavLinks()
  }
}
