export const GOTO_CONTENT_ID = 'goto--content'
export const GOTO_CONTENT_SELECTOR = `#${GOTO_CONTENT_ID}`

// This is a special container for gtc
// This does not respect the shell application template but is needed for legacy reason
export const EXPERIENCE_GTC_CONTAINER_ID = 'goto--connect'
export const EXPERIENCE_GTC_CONTAINER_SELECTOR = `#${EXPERIENCE_GTC_CONTAINER_ID}`

export const GOTO_ROOT_ID = 'goto-root'
export const GOTO_ROOT_SELECTOR = `#${GOTO_ROOT_ID}`

export const GOTO_TOPBAR_CONTAINER_ID = 'goto--topbar-container'
export const GOTO_TOPBAR_CONTAINER_SELECTOR = `#${GOTO_TOPBAR_CONTAINER_ID}`

export const GOTO_NAVIGATION_ID = 'goto--navigation'
export const GOTO_NAVIGATION_SELECTOR = `#${GOTO_NAVIGATION_ID}`

export const GOTO_CRITICAL_BANNER_CONTAINER_ID = 'goto--critical-banner-container'
export const GOTO_CRITICAL_BANNER_NOTIFICATION_SELECTOR = `#${GOTO_CRITICAL_BANNER_CONTAINER_ID}`

export const GOTO_BANNER_NOTIFICATION_ID = 'goto--banner-notification'
export const GOTO_BANNER_NOTIFICATION_SELECTOR = `#${GOTO_BANNER_NOTIFICATION_ID}`

export const GOTO_SNACKBAR_NOTIFICATION_ID = 'goto--snackbar-notification'
export const GOTO_SNACKBAR_NOTIFICATION_SELECTOR = `#${GOTO_SNACKBAR_NOTIFICATION_ID}`

export const GOTO_MODAL_NOTIFICATION_ID = 'goto--modal-notification'
export const GOTO_MODAL_NOTIFICATION_SELECTOR = `#${GOTO_MODAL_NOTIFICATION_ID}`

export const GOTO_DIALOG_CONTAINER_ID = 'goto--dialog-container'
export const GOTO_DIALOG_CONTAINER_SELECTOR = `#${GOTO_DIALOG_CONTAINER_ID}`

export const GOTO_SETTINGS = 'goto--settings-content'
export const GOTO_SETTINGS_SELECTOR = `#${GOTO_SETTINGS}`

export const GOTO_SPECIFIC_AREA = 'goto--specific-area'
export const GOTO_SPECIFIC_AREA_SELECTOR = `#${GOTO_SPECIFIC_AREA}`

export const CHAMELEON_THEME_PROVIDER = 'chameleon-theme-provider'
export const REBRANDING_2021_THEME = 'rebranding2021'
export const CHAMELEON_LAUNCH_SCREEN = 'chameleon-launch-screen'
