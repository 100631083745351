import { html, nothing } from 'lit'
import { state } from 'lit/decorators.js'
import { ShellElement } from '../../../common/shell-element'
import { t } from '../../../directives/translate'
import scheduleSettingsStyles from './schedule-settings.styles.scss'
import { environment } from '../../../environments'
import { type RadioGroupComponent } from '@getgo/chameleon-web'
import { ScheduleStatus, type ScheduleChangePayload, type ShellUserSchedule } from '../schedule-manager/models'
import { getScheduleManager } from '../schedule-manager/schedule-manager'
import { getShellAnalytics, getShellApiInstance } from '../../../common/shell-api-helpers'
import { getTranslation } from '../../../services/i18n/i18nUtils'
import { SETTINGS_IS_DIRTY_ID } from '../../settings/settings-helpers'
import { scheduleSettingsName } from '../../../experiences/navigation-links'
import { PICTOGRAM_ALERT_STATIC } from '@getgo/chameleon-pictograms'
import { reloadPage } from '../../../common/dom-helpers'
import { userHasMultiplePBXAccounts } from '../../../common/user-helpers'

export class GoToScheduleSettings extends ShellElement {
  static readonly tagName = 'goto-schedule-settings'

  @state() private isDirty: boolean = false
  @state() private isSaving: boolean = false
  @state() private isLoading: boolean = true
  @state() private hasError: boolean = false
  @state() private modifiedUserSchedule!: ShellUserSchedule
  @state() private displayMultipleAccountsAlert = false

  static get styles() {
    return scheduleSettingsStyles
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback()
    getScheduleManager().subscribe(this.handleUserScheduleChange)
    await this.getUserSchedule()
    if (!this.hasError) {
      // only display the alert after the user schedule is fetched successfully
      this.displayMultipleAccountsAlert = await userHasMultiplePBXAccounts()
    }
    getShellAnalytics().track('GoTo > Schedule settings', { action: 'page visited' })
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    getScheduleManager().unsubscribe(this.handleUserScheduleChange)
  }

  private async getUserSchedule() {
    if (this.isDirty) {
      this.isLoading = false
      return
    }
    await this.fetchUserSchedule()
  }

  private async fetchUserSchedule() {
    try {
      await getScheduleManager().fetchUserSchedule()
      this.hasError = false
    } catch {
      this.hasError = true
    }
    this.isLoading = false
  }

  private handleUserScheduleChange = (payload: ScheduleChangePayload) => {
    this.modifiedUserSchedule = payload.userSchedule
    this.isDirty = payload.state.isDirty
    this.updateIsDirtyBadge()
  }

  private updateIsDirtyBadge() {
    getShellApiInstance().badgeNumbers.setBadgeNumber({
      id: SETTINGS_IS_DIRTY_ID,
      settingsName: scheduleSettingsName,
      value: this.isDirty ? 1 : 0,
    })
  }

  private getTimeZone() {
    return this.modifiedUserSchedule.timezone
  }

  private getEnabled() {
    return this.modifiedUserSchedule.enabled
  }

  private getFooterClass() {
    return this.isDirty ? 'schedule-footer footer-visible' : 'schedule-footer footer-hidden'
  }

  private getTimeZoneColor() {
    return this.getEnabled() ? 'type-color-default' : 'type-color-disabled'
  }

  private handleScheduleStatusChange(event: Event) {
    const scheduleStatusRadioGroup = event.currentTarget as RadioGroupComponent
    const newScheduleEnabled = scheduleStatusRadioGroup.value === ScheduleStatus.WORK_SCHEDULE
    getScheduleManager().updateUserScheduleEnabled(newScheduleEnabled)
    getShellAnalytics().track('GoTo > Schedule settings - Status radio buttons', {
      action: 'click',
      scheduleEnabledStatus: newScheduleEnabled,
    })
  }

  private showSuccessfulSaveNotification() {
    getShellApiInstance().display.snackbar({
      title: getTranslation('Changes saved successfully'),
      id: 'schedule-settings-successful-save-notification',
    })
  }

  private showFailedSaveNotification() {
    getShellApiInstance().display.snackbar({
      title: getTranslation('Your changes were not saved'),
      message: getTranslation('There was a problem saving your changes. Try again later.'),
      id: 'schedule-settings-failed-save-notification',
    })
  }

  private handleAlertCloseClicked() {
    this.displayMultipleAccountsAlert = false
  }

  private async handleScheduleSave() {
    this.isSaving = true
    try {
      await getScheduleManager().saveUserSchedule()
      this.showSuccessfulSaveNotification()
    } catch {
      this.showFailedSaveNotification()
    } finally {
      this.isSaving = false
    }
  }

  private handleScheduleCancel() {
    getShellAnalytics().track('GoTo > Schedule settings - Cancel button', { action: 'click', button: 'Cancel' })
    getScheduleManager().resetUserSchedule()
  }

  private getScheduleSettingsContainerClass() {
    return `schedule-settings-container-${this.hasError ? 'error' : 'content'}`
  }

  render() {
    return html`
      <form class="schedule-settings-page">
        <div class=${`schedule-settings-scrollable-content${this.hasError ? ' error-container' : ''}`}>
          <chameleon-typography class="settings-header" tag="h1" variant="heading-large">
            ${t('Schedule')}
          </chameleon-typography>
          <div class=${this.getScheduleSettingsContainerClass()}>
            <chameleon-typography variant="body-medium" id="set-description"
              >${t(
                'Choose when you’d like to receive calls and notifications. To allow your schedule to change your availability, your profile status must be set to auto-update.',
              )}</chameleon-typography
            >
            ${this.renderMultipleAccountsAlert()} ${this.renderSchedulePage()}
          </div>
        </div>
        ${this.renderFooter()}
      </form>
    `
  }

  private renderMultipleAccountsAlert() {
    return this.displayMultipleAccountsAlert
      ? html`<chameleon-alert-v2 class="multiple-accounts-alert" closable @close=${this.handleAlertCloseClicked}>
          ${t(
            'Your do not disturb hours apply to all your accounts. To avoid schedule conflicts, we recommend maintaining 1 work schedule for all accounts.',
          )}
        </chameleon-alert-v2>`
      : nothing
  }

  private renderSchedulePage() {
    return this.hasError ? this.renderSchedulePageError() : this.renderSchedulePageContent()
  }

  private renderSchedulePageError() {
    return html`<div class="error-empty-state">
      <chameleon-empty-state>
        <chameleon-svg slot="icon" class="error-pictogram">${PICTOGRAM_ALERT_STATIC}</chameleon-svg>
        <p slot="title">${t('Something went wrong')}</p>
        ${t('Go ahead and give it another try.')}
        <div slot="actions">
          <chameleon-button @click=${reloadPage} variant="secondary">${t('Reload')}</chameleon-button>
        </div></chameleon-empty-state
      >
    </div> `
  }

  private renderSchedulePageContent() {
    return html` <div class="work-section">
        ${this.renderEnableSection()} <goto-schedule-work-hours ?isLoading=${this.isLoading}></goto-schedule-work-hours>
      </div>
      <div class="custom-section">
        <goto-schedule-custom-hours ?isLoading=${this.isLoading}></goto-schedule-custom-hours>
      </div>
      ${this.renderTimeZoneSection()}`
  }

  private renderEnableSection() {
    return this.isLoading
      ? html`<div class="enable-section-loading">
          <chameleon-skeleton variant="rectangle" size="xsmall"></chameleon-skeleton>
          <chameleon-skeleton variant="rectangle" size="xsmall"></chameleon-skeleton>
        </div> `
      : html`
          <chameleon-radio-group
            label-id="set-description"
            .value=${this.getEnabled() ? ScheduleStatus.WORK_SCHEDULE : ScheduleStatus.NO_HOURS_SET}
            @change=${this.handleScheduleStatusChange}
          >
            <chameleon-radio-button value=${ScheduleStatus.NO_HOURS_SET}>${t('No hours set')}</chameleon-radio-button>
            <chameleon-radio-button value=${ScheduleStatus.WORK_SCHEDULE}>
              ${t('Work schedule')}</chameleon-radio-button
            >
          </chameleon-radio-group>
        `
  }

  private renderTimeZoneSection() {
    return html`
      <div class="time-zone-section">
        <div class="time-zone-section-header">
          <chameleon-typography data-test="timezone-header" class="small-header" variant="body-medium-strong"
            >${t('Time zone')}</chameleon-typography
          >
          <div class="time-zone-set">
            ${this.renderTimeZone()}
            <chameleon-button-link
              data-test="change-timezone-link"
              href="${environment().profileUrl}"
              target="blank"
              variant="secondary"
              size="medium"
              ?disabled=${!this.getEnabled()}
              >${t('Change my time zone')}</chameleon-button-link
            >
          </div>
        </div>
      </div>
    `
  }

  private renderTimeZone() {
    return this.isLoading
      ? html`<chameleon-skeleton variant="rectangle" size="xsmall"></chameleon-skeleton>`
      : html` <chameleon-typography data-test="current-timezone" variant="body-medium" .color=${this.getTimeZoneColor()}
          >${t('Profile time zone: ')}${this.getTimeZone()}
        </chameleon-typography>`
  }

  private renderFooter() {
    return html`
      <div class=${this.getFooterClass()}>
        <chameleon-button
          data-test="cancel-schedule"
          size="medium"
          variant="tertiary"
          @click=${this.handleScheduleCancel}
          >${t('Cancel')}</chameleon-button
        >
        <chameleon-button
          data-test="save-schedule"
          size="medium"
          @click=${this.handleScheduleSave}
          is-loading=${this.isSaving}
          >${t('Save')}</chameleon-button
        >
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-settings': GoToScheduleSettings
  }
}
