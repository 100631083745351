import { LitElement, html, nothing } from 'lit'
import { t } from '../../directives/translate'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { SVG_EXTERNAL_LINK_OUTLINED, SVG_GO_PILOT_CHAT_OUTLINED } from '@getgo/chameleon-icons'
import { openNewTabFor } from '../../common/dom-helpers'
import { environment } from '../../environments'
import { getShellAnalytics, getShellNamespaces } from '../../common/shell-api-helpers'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { repeat } from 'lit/directives/repeat.js'
import { setupUserReport } from '../user-report'
import { isGoToMeetingCustomer } from '../../services/identity/entitlement'
import { SHELL_HELP_NAMESPACE, type ShellHelpNamespace } from '../../services/namespaces/shell-help-namespace'
import { type Namespace } from '../../services/namespaces'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'

export interface TopbarMenuItem {
  readonly labelKey: string
  readonly dataTest: string
  readonly executeFunction: () => void
  readonly isExternal: () => boolean
}

export const gotoConnectQuickStartGuideTopbarMenuItem: TopbarMenuItem = {
  executeFunction: () => openNewTabFor(environment().gotoConnectQuickStartGuideUrl),
  labelKey: asTranslationKey('Quick start guide'),
  dataTest: 'gtc-quick-start-guide',
  isExternal: () => true,
}

export const externalTopbarMenuItems: readonly TopbarMenuItem[] = [
  {
    executeFunction: () => openNewTabFor(environment().helpUrl),
    labelKey: asTranslationKey('Get help'),
    dataTest: 'get-help',
    isExternal: () => true,
  },
  {
    executeFunction: () => openNewTabFor(environment().tutorialsAndVideosUrl),
    labelKey: asTranslationKey('Tutorials and videos'),
    dataTest: 'tutorials-and-videos',
    isExternal: () => true,
  },
  {
    executeFunction: () => openNewTabFor(environment().communityForumUrl),
    labelKey: asTranslationKey('Community forum'),
    dataTest: 'community-forum',
    isExternal: () => true,
  },
]

export const feedbackTopbarMenuItem: TopbarMenuItem = {
  executeFunction: () => {
    if (isGoToMeetingCustomer()) {
      openNewTabFor(environment().feedbackGoToMeetingUrl)
    } else {
      const { openFeedbackDialog } = setupUserReport(window)
      openFeedbackDialog()
    }
  },
  labelKey: asTranslationKey('Give feedback'),
  dataTest: 'feedback',
  isExternal: () => isGoToMeetingCustomer(),
}

export const assistantTopbarMenuItem: TopbarMenuItem = {
  executeFunction: () => {
    const helpNamespace = getShellNamespaces().retrieve<ShellHelpNamespace>(SHELL_HELP_NAMESPACE)
    helpNamespace.commands.openGotoAssistant.execute()
  },
  labelKey: asTranslationKey('Ask the GoTo assistant'),
  dataTest: 'goto-assistant',
  isExternal: () => false,
}

export class GoToTopbarHelpMenu extends LitElement {
  static readonly tagName = 'goto-topbar-help-menu'

  private shellHelpNamespace: Namespace<ShellHelpNamespace> | undefined

  connectedCallback() {
    super.connectedCallback()
    this.shellHelpNamespace = getShellNamespaces().retrieve<ShellHelpNamespace>(SHELL_HELP_NAMESPACE)
  }

  private renderMenuItem(menuItem: TopbarMenuItem) {
    const { labelKey, executeFunction, dataTest, isExternal } = menuItem
    return html`
      <chameleon-menu-item
        @menuitemclick=${() => {
          getShellAnalytics().track('GoTo > Help menu', { action: 'Click', target: labelKey, from: 'Topbar' })
          executeFunction()
        }}
        aria-label=${t(labelKey)}
        data-test=${dataTest}
      >
        ${t(labelKey)} ${this.createAssistantStartSlot(dataTest)} ${this.createExternalLinkEndSlot(isExternal)}
      </chameleon-menu-item>
    `
  }

  private readonly gotoAssistantIsHandled = () => this.shellHelpNamespace?.commands.openGotoAssistant.isHandled()

  private readonly renderAssistantMenuItem = () =>
    this.gotoAssistantIsHandled()
      ? html`${this.renderMenuItem(assistantTopbarMenuItem)} <chameleon-menu-separator></chameleon-menu-separator>`
      : nothing

  private readonly renderGotoConnectQuickStartGuideMenuItem = () =>
    getFeatureFlagValue(FeatureFlagsVariations.SHELL_SHOW_GTC_QUICK_START_GUIDE)
      ? this.renderMenuItem(gotoConnectQuickStartGuideTopbarMenuItem)
      : nothing

  private readonly createExternalLinkEndSlot = (isExternalLink: () => boolean) =>
    isExternalLink()
      ? html` <chameleon-svg slot="end">${unsafeSVG(SVG_EXTERNAL_LINK_OUTLINED)}</chameleon-svg>`
      : nothing

  private readonly createAssistantStartSlot = (dataTest: string) =>
    dataTest === 'goto-assistant'
      ? html` <chameleon-svg slot="start">${unsafeSVG(SVG_GO_PILOT_CHAT_OUTLINED)}</chameleon-svg>`
      : nothing

  render() {
    return html`
      <chameleon-menu label=${t('Help menu')}>
        <chameleon-menu-section>${t('Help')}</chameleon-menu-section>
        ${this.renderAssistantMenuItem()}
        ${this.renderGotoConnectQuickStartGuideMenuItem()}
        ${repeat(
          externalTopbarMenuItems,
          menuItem => menuItem.dataTest,
          menuItem => this.renderMenuItem(menuItem),
        )}
        <chameleon-menu-separator></chameleon-menu-separator>
        ${this.renderMenuItem(feedbackTopbarMenuItem)}
      </chameleon-menu>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-topbar-help-menu': GoToTopbarHelpMenu
  }
}
