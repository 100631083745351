import { html, nothing } from 'lit'
import { state } from 'lit/decorators.js'
import navigationStyles from './navigation-rail.styles.scss'
import { navigateToUrl } from '../../../common'
import { getTranslation } from '../../../services/i18n/i18nUtils'
import { getFeatureFlagValue } from '../../../services/feature-flags'
import { FeatureFlagsVariations } from '../../../services/feature-flags/models'
import { linkIsActive } from '../navigation-item/helpers'
import { getResolvedIcon } from '../../../common/icon-helpers'
import { ifDefined } from 'lit/directives/if-defined.js'
import { setStoreCurrentRoute } from '../../../common/store-current-route'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

import { t } from '../../../directives/translate'
import { getExternalInterface } from '../../../services/external-interface'
import { GotoBaseNavigationLinks } from '../base-navigation/base-navigation-links'
import { NavigationKeyboardShortcutService } from '../../../services/navigation/navigation-keyboard-shortcut-service'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import { type NavigationItemUI } from '@goto/shell-common'
import { GoToBaseNavigation } from '../base-navigation/base-navigation'

export type NavigationRailState = 'expanded' | 'collapsed'

const EXPANDED_NAVRAIL_STATE: NavigationRailState = 'expanded'
const COLLAPSED_NAVRAIL_STATE: NavigationRailState = 'collapsed'

const TOGGLE_NAVRAIL_EVENT = 'navrail-toggle'

export interface NavigationRailPreferenceState {
  [GoToNavRail.tagName]: {
    state: NavigationRailState
  }
}
const getExpandedStateCacheKey = () => `shell.${GoToNavRail.tagName}.state`

export class GoToNavRail extends GotoBaseNavigationLinks {
  static readonly tagName = 'goto-nav-rail'

  private readonly navigationKeyboardShortcutService: NavigationKeyboardShortcutService

  @state() private state: NavigationRailState = EXPANDED_NAVRAIL_STATE

  static get styles() {
    return navigationStyles
  }

  constructor() {
    super()
    this.navigationKeyboardShortcutService = new NavigationKeyboardShortcutService()
  }
  connectedCallback() {
    super.connectedCallback()
    this.subscribeToNavrailToggle()
  }

  async linksUpdated() {
    await this.setNavigationItems()
    await this.subscribeNavigationToKeyboardShortcutByIndex()
  }

  private async subscribeToNavrailToggle(): Promise<void> {
    const userPreferencesService = getShellApiInstance().userPreferences
    this.state = (await userPreferencesService?.getPreference(
      getExpandedStateCacheKey(),
      EXPANDED_NAVRAIL_STATE,
    )) as NavigationRailState

    const listener = () => {
      this.state = this.toggleState(this.state)
      userPreferencesService?.setPreference<NavigationRailPreferenceState>('shell', {
        [GoToNavRail.tagName]: { state: this.state },
      })
      this.requestUpdate()
    }
    this.addEventListener(TOGGLE_NAVRAIL_EVENT, listener)
    this.unsubscribeFunctions.push(() => {
      this.removeEventListener(TOGGLE_NAVRAIL_EVENT, listener)
    })
  }

  private toggleState(currentState: NavigationRailState): NavigationRailState {
    return currentState === EXPANDED_NAVRAIL_STATE ? COLLAPSED_NAVRAIL_STATE : EXPANDED_NAVRAIL_STATE
  }

  private async subscribeNavigationToKeyboardShortcutByIndex() {
    this.navigationKeyboardShortcutService.subscribeNavItemShortcuts(this.navigationItems)
  }

  async setNavigationItems() {
    this.navigationItems = [...this.links]
    this.subscribeToBadgeEvent()
  }

  render() {
    return getExternalInterface().isIntegration
      ? nothing
      : html`
          <chameleon-navigation-rail
            class="nav-rail fade-in"
            size="medium"
            collapse-label="${getTranslation('Collapse')}"
            expand-label="${getTranslation('Expand')}"
            state="${this.state}"
            label=${t('Left navigation menu')}
          >
            ${this.renderNavItems()} ${this.renderAppSwitcher()}</chameleon-navigation-rail
          >
        `
  }

  private renderNavItems() {
    return this.navigationItems.length > 0
      ? this.navigationItems.map(navItem =>
          navItem.externalLink ? this.renderNavRailLink(navItem) : this.renderNavRailItem(navItem),
        )
      : nothing
  }

  private renderNavRailLink(navItem: NavigationItemUI) {
    const active = linkIsActive(navItem.pathname)

    return html`
      <chameleon-navigation-rail-link
        href="${navItem.pathname}"
        data-test="${ifDefined(navItem.dataTest)}"
        ?selected=${active}
      >
        ${navItem.displayName}
        <chameleon-svg slot="start">${unsafeSVG(getResolvedIcon(navItem.icon, { active }))} </chameleon-svg>
        ${this.renderBadge(navItem)}
      </chameleon-navigation-rail-link>
    `
  }

  private renderNavRailItem(navItem: NavigationItemUI) {
    const active = linkIsActive(navItem.pathname)
    return html`
      <chameleon-navigation-rail-item
        @change=${() => {
          if (!active) {
            setStoreCurrentRoute(navItem.pathname)
            navigateToUrl(navItem.pathname)
          }
        }}
        data-test="${ifDefined(navItem.dataTest)}"
        ?selected=${active}
      >
        ${navItem.displayName}
        <chameleon-svg slot="start">${unsafeSVG(getResolvedIcon(navItem.icon, { active }))} </chameleon-svg>
        ${this.renderBadge(navItem)}
      </chameleon-navigation-rail-item>
    `
  }

  private renderBadge(navItem: NavigationItemUI) {
    const badgeCount = this.getBadgeCount(navItem)

    return badgeCount && badgeCount > 0
      ? html`
          <chameleon-badge-counter
            size="small"
            slot="end"
            data-test="badge-${ifDefined(navItem.dataTest)}"
            label=${t('New notifications')}
          >
            ${GoToBaseNavigation.badgeCountAsString(badgeCount)}</chameleon-badge-counter
          >
        `
      : nothing
  }

  private renderAppSwitcher() {
    return getFeatureFlagValue(FeatureFlagsVariations.SHELL_APPLICATION_SWITCHER)
      ? html`
          <goto-app-switcher
            role=${'menuitem'}
            class="chameleon-custom-navigation-rail-item"
            slot="secondary-items"
          ></goto-app-switcher>
        `
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-nav-rail': GoToNavRail
  }
}
