import { setToLocalStorage } from '../../../common/dom-helpers'
import { authenticatedFetch } from '../../auth'
import { emitUserPreferencesUpdatedEvent } from '../events'
import { LOCAL_STORAGE_KEY, type UserPreferencesConnectorParams } from '../models'
import { AbstractUserPreferencesServiceConnector } from './user-preferences.connector.abstract'

export class UserPreferencesServiceConnector extends AbstractUserPreferencesServiceConnector {

  protected readonly apiBaseUrl: string

  constructor(params?: UserPreferencesConnectorParams) {
    super()
    this.apiBaseUrl = params?.apiUrl ?? ''
  }

  getPreferences = async (): Promise<any> => {
    if (!this.apiBaseUrl) {
      return this.getPreferencesFromLocalStorage() ?? {}
    }
    const query = {
      method: 'GET',
    }
    return (await this.queryAPI(`${this.apiBaseUrl}/preferences`, query)) ?? {}
  }

  getPreference = async <T, R>(path: string, defaultValue?: T): Promise<R> => {
    if (!this.apiBaseUrl) {
      const preferences = await this.getPreferences()
      return this.getPreferenceFromLocalStorage(preferences, path, defaultValue)
    }
    const query = {
      method: 'GET',
      body: { path, defaultValue },
    }
    return await this.queryAPI(`${this.apiBaseUrl}/preference`, query)
  }

  setPreference = async <T>(path: string, data: T): Promise<void> => {
    if (!this.apiBaseUrl) {
      const preferences = await this.getPreferences()
      return this.setPreferenceInLocalStorage(preferences, path, data)
    }
    const query = {
      method: 'POST',
      body: { path, data },
    }
    await this.queryAPI(`${this.apiBaseUrl}/preference`, query)
  }

  deletePreference = async (path: string): Promise<void> => {
    if (!this.apiBaseUrl) {
      const preferences = await this.getPreferencesFromLocalStorage()
      return this.deletePreferenceFromLocalStorage(preferences, path)
    }
    const query = {
      method: 'DELETE',
      body: { path },
    }
    await this.queryAPI(`${this.apiBaseUrl}/preference`, query)
  }

  private readonly queryAPI = async <R>(url: string, query?: any): Promise<R> => {
    const response = await authenticatedFetch(url, query)
    return await response.json()
  }

  private readonly getPreferenceFromLocalStorage = (preferences: any, path: string, defaultValue: any) => {
    const foundPreference = this.getPreferenceByPath(preferences, path)
    return foundPreference ?? defaultValue
  }

  private readonly setPreferenceInLocalStorage = (preferences: any, path: string, data: any) => {
    const updatedPreferences = this.setPreferenceByPath(preferences, path, data)
    setToLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updatedPreferences))
    emitUserPreferencesUpdatedEvent({ path, data })
  }
}
