/* istanbul ignore file */
import { html } from 'lit'
import { singleSpaLit } from '../../common'
import { GOTO_ROOT_SELECTOR } from '../../common/container'

const lifecycles = singleSpaLit({
  template: html`<goto-error></goto-error>`,
  domElement: GOTO_ROOT_SELECTOR,
})

export default lifecycles
