import { getFromLocalStorage, setToLocalStorage } from '../../../common/dom-helpers'
import { authenticatedFetch } from '../../auth'
import { emitUserPreferencesUpdatedEvent } from '../events'
import { LOCAL_STORAGE_KEY, USER_ACCOUNT_PREFERENCES_KEY, type UserPreferences } from '../models'
import { type PatchParams } from '@goto/shell-bff-api'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import { environment } from '../../../environments'
import { getFeatureFlagValue } from '../../feature-flags'
import { FeatureFlagsVariations } from '../../feature-flags/models'
import { AbstractUserPreferencesServiceConnector } from './user-preferences.connector.abstract'
import { getShellLogger } from '../../../common/logger'
import { safeParseJSON } from '../../../common/helpers/json'
import { type retryOnOptions } from '../../../common/fetch-utils'
export class UserAccountPreferencesServiceConnector extends AbstractUserPreferencesServiceConnector {
  async getPreferences(): Promise<UserPreferences> {
    const remotePreferences = safeParseJSON<UserPreferences>(getFromLocalStorage(USER_ACCOUNT_PREFERENCES_KEY), {})
    const localPreferences = this.getPreferencesFromLocalStorage()
    return { ...localPreferences, ...remotePreferences }
  }

  async getPreference<T, R>(path: string, defaultValue?: T): Promise<R> {
    const accountValue = this.getValueFromSpecificLocalStorage(USER_ACCOUNT_PREFERENCES_KEY, path)
    const localValue = this.getValueFromSpecificLocalStorage(LOCAL_STORAGE_KEY, path)
    if (accountValue) {
      this.deletePreference(path)
      return accountValue
    }
    if (localValue) {
      if (this.shouldMigrateField(path)) {
        await this.setUserSettingsProperty(path, localValue)
      }
      return localValue
    }
    return defaultValue as R
  }

  async setPreference<T>(path: string, data: T): Promise<void> {
    await this.setUserSettingsProperty(path, data)
    emitUserPreferencesUpdatedEvent({ path, data })
  }

  async deletePreference(path: string): Promise<void> {
    try {
      const preferences = this.getPreferencesFromLocalStorage()
      this.deletePreferenceFromLocalStorage(preferences, path)
    } catch {
      // Do nothing
    }
  }

  /**
   * @description Sets a property to the server.
   * @param path of the property.
   * @param data to be set.
   */
  private async setUserSettingsProperty(path: string, data: unknown): Promise<void> {
    try {
      const response: Response = await this.patchUserSettings(path, data)
      if (response.ok) {
        const userPreferences = await response.json()
        setToLocalStorage(USER_ACCOUNT_PREFERENCES_KEY, JSON.stringify(userPreferences))
        this.deletePreference(path)
        return
      }

      throw new Error(`Failure during the patchUserSettings`)
    } catch (err) {
      getShellLogger().info(
        `[User Preferences] - [path: ${path}] - The PATCH call failed (error: ${err}). The data remains in local storage.`,
      )
      const preferences = this.getPreferencesFromLocalStorage()
      try {
        const updatedPreferences = this.setPreferenceByPath(preferences ?? {}, path, data)
        setToLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updatedPreferences))
      } catch {
        getShellLogger().info(`[User Preferences] - [path: ${path}] - Path you're trying to set doesn't exist`)
      }
    }
  }

  /**
   * @description Sends a PATCH request to the server.
   * @param path of the property.
   * @param data to be set.
   * @returns Response.
   */
  private async patchUserSettings(path: string, data: unknown): Promise<Response> {
    const {
      context: { account: { key: accountKey } = {} },
      user: { key: userKey },
    } = getShellApiInstance()

    const patchParams: PatchParams = {
      // logic to match with the getPreference naming convention and the user settings sdk path.
      // "notifications.voicemail.context" will change to "/notifications/voicemail/context" but "theme" will be "theme".
      path: path.includes('.') ? `/${path.replace(/\./g, '/')}` : path,
      value: data,
    }
    const url = `${environment().getShellBffServiceBaseUrl()}/user-settings/accounts/${accountKey}/users/${userKey}`
    const response = await authenticatedFetch(url, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      retry: {
        maxRetryNumber: 3,
        retryAfterInMilliseconds: 500,
        retryOn: this.shouldRetryPatchUserSettingsCall,
      },
      credentials: undefined,
      body: JSON.stringify([patchParams]),
    })

    return response
  }

  private shouldRetryPatchUserSettingsCall = async (options: retryOnOptions<Response>) => {
    const { error, value: { status } = {} } = options
    if (status && status >= 500) {
      return true
    }
    if (error) {
      return true
    }
    return false
  }

  /**
   * @description Checks the feature flag value to see if it has to be migrated from the old storage.
   * @param field to be checked.
   * @returns boolean.
   */
  private shouldMigrateField(field: string): boolean {
    const fieldsToMigrate = getFeatureFlagValue<string>(
      FeatureFlagsVariations.SHELL_USER_PREFERENCES_V2_MIGRATION,
    )?.split(',')
    return fieldsToMigrate ? fieldsToMigrate.includes(field) : false
  }

  private getValueFromSpecificLocalStorage(storageName: string, path: string) {
    const localStorageValue = getFromLocalStorage(storageName)
    const preferences = safeParseJSON<UserPreferences>(localStorageValue, {})
    return this.getPreferenceByPath(preferences, path)
  }
}
