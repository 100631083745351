/* istanbul ignore file */
import { html } from 'lit'
import { singleSpaLit } from '../../common'
import { GOTO_CONTENT_SELECTOR } from '../../common/container'
import { type GoToIntegrationRedirectProxyLitProps } from './types'

const lifecycles = singleSpaLit<GoToIntegrationRedirectProxyLitProps>({
  template: props => {
    const { data = {} } = props ?? {}
    return html`<goto-integration-redirect-proxy .data=${data}></goto-integration-redirect-proxy>`
  },
  domElement: GOTO_CONTENT_SELECTOR,
})

export default lifecycles
