import { retry } from '../../common/fetch-utils'
import { LitElement, html, nothing } from 'lit'
import { state } from 'lit/decorators.js'
import calendarSettingsStyles from './calendar-settings.styles.scss'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { resolvePromise } from '../../directives/resolved-promise'
import { t } from '../../directives/translate'
import { getShellApiInstance, getShellAnalytics } from '../../common/shell-api-helpers'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import type { CalendarConnector, CalendarConnectionResponse } from '../../services/calendar'
import { getCalendarConnector, ProviderName } from '../../services/calendar'
import { openNewWindowFor, reloadPage } from '../../common/dom-helpers'
import { ifDefined } from 'lit/directives/if-defined.js'
import { snackbar } from '../../services/display-notification'
import { SVG_EXTERNAL_LINK_OUTLINED, SVG_GOOGLE, SVG_OFFICE_365 } from '@getgo/chameleon-icons'
import { CALENDAR_CONNECTED_ROUTE } from '../../common/routes'
import type { CalendarCustomEvent } from '../../services/calendar/events'
import type { Testable } from '../../common'
import { isContainer } from '../../services/container/helpers'

interface CalendarSetting extends Testable {
  readonly displayName: string
  readonly calendarName: string
  readonly icon: string
  readonly providerName: ProviderName
  readonly connected: boolean
  readonly disabled: boolean
  readonly description: string
}

interface CalendarConnectedResponse {
  readonly office365Settings: CalendarSetting
  readonly googleSettings: CalendarSetting
  readonly id: string
  readonly calendar: CalendarConnector
}

export const CALENDAR_ORIGINATOR = 'calendar'
export const ORIGINATOR = 'originator'

export class GoToCalendarSettings extends LitElement {
  static readonly tagName = 'goto-calendar-settings'
  @state()
  private calendarSettings: CalendarConnectedResponse | null = null

  static get styles() {
    return calendarSettingsStyles
  }

  constructor() {
    super()
    this.getCalendarSettings()
  }

  async renderCalendarOptions() {
    if (!this.calendarSettings) {
      return html` <chameleon-typography class="loading-text" tag="p" variant="body-medium"
        >${t('Loading your calendar settings...')}</chameleon-typography
      >`
    }
    return html`${this.createCalendarTemplate(
      this.calendarSettings.office365Settings,
      this.calendarSettings.calendar,
      this.calendarSettings.id,
    )}
    ${this.createCalendarTemplate(
      this.calendarSettings.googleSettings,
      this.calendarSettings.calendar,
      this.calendarSettings.id,
    )}`
  }

  async getCalendarSettings() {
    const calendar = getCalendarConnector()
    const { user } = getShellApiInstance()

    if (!user.key || !calendar) {
      return nothing
    }

    let calendarResponse: CalendarConnectionResponse | undefined

    try {
      calendarResponse = await calendar.getConnection(user.key)
    } catch {
      // handle error https://jira.ops.expertcity.com/browse/SCORE-342
    }

    const office365Settings = {
      displayName: asTranslationKey('Office 365'),
      calendarName: 'Office 365',
      icon: SVG_OFFICE_365,
      providerName: ProviderName.OFFICE365_DIRECT,
      connected: calendarResponse?.providerSubType === ProviderName.OFFICE365_DIRECT,
      disabled: calendarResponse?.providerSubType === ProviderName.GOOGLE_DIRECT,
      dataTest: 'office-365',
      description: 'View your events in the Meetings tab and sync your GoTo availability to your calendar status',
    }

    const googleSettings = {
      displayName: asTranslationKey('Google'),
      calendarName: 'Google',
      icon: SVG_GOOGLE,
      providerName: ProviderName.GOOGLE_DIRECT,
      connected: calendarResponse?.providerSubType === ProviderName.GOOGLE_DIRECT,
      disabled: calendarResponse?.providerSubType === ProviderName.OFFICE365_DIRECT,
      dataTest: 'google',
      description: 'View your events in the Meetings tab',
    }

    this.calendarSettings = { office365Settings, googleSettings, id: user.key, calendar }
  }

  createCalendarTemplate(setting: CalendarSetting, calendar: CalendarConnector, id: string) {
    const disconnectedHandler = () => {
      this.disconnectCalendar(calendar, id, setting.providerName).then(() => {
        const detail: CalendarCustomEvent = {
          eventName: 'disconnect',
        }
        this.dispatchEvent(new CustomEvent('calendar', { bubbles: true, detail }))
      })
    }

    const connectedHandler = () => {
      this.connectCalendar(calendar, id, setting.providerName)
      this.verifyCalendarConnection(calendar, id)
    }

    const dataTest = setting.disabled ? `disabled-${setting.dataTest}` : `connect-${setting.dataTest}`
    const dataTestDisconnected = `disconnect-${setting.dataTest}`
    return html`
      <div class="calendar-item-wrapper" data-test="${ifDefined(setting.dataTest)}">
        <chameleon-svg class="icon">${setting.icon}</chameleon-svg>
        <div class="text">
          <chameleon-typography class="name" tag="h2" variant="heading-small">
            ${t(setting.displayName)} Calendar
          </chameleon-typography>
          <chameleon-typography class="description" color="type-color-secondary"
            >${t(setting.description)}</chameleon-typography
          >
        </div>
        ${setting.connected
          ? html`<chameleon-button
              class="disconnect-button"
              data-test="${dataTestDisconnected}"
              variant="secondary"
              @click=${disconnectedHandler}
            >
              ${t('Disconnect')}
            </chameleon-button>`
          : html`<chameleon-button
              class="connect-button"
              data-test="${dataTest}"
              @click=${connectedHandler}
              title="${setting.disabled
                ? t('You can only connect one calendar at a time')
                : t(`Connect {{calendarName}}`, () => ({ calendarName: setting.calendarName }))}"
              ?disabled=${setting.disabled}
              >${t('Connect')}
              <chameleon-svg slot="end">${unsafeSVG(SVG_EXTERNAL_LINK_OUTLINED)}</chameleon-svg>
            </chameleon-button>`}
      </div>
    `
  }

  async disconnectCalendar(calendar: CalendarConnector, userKey: string, providerName: ProviderName) {
    try {
      await calendar.disconnect(userKey)
      this.calendarSettings = {
        // TODO properly handle me https://jira.ops.expertcity.com/browse/SCAPI-467

        ...this.calendarSettings!,
        office365Settings: {
          ...(this.calendarSettings?.office365Settings as unknown as CalendarSetting),
          connected: false,
          disabled: false,
        },
        googleSettings: {
          ...(this.calendarSettings?.googleSettings as unknown as CalendarSetting),
          connected: false,
          disabled: false,
        },
      }

      getShellAnalytics().track('GoTo > Settings - Calendar', {
        action: 'Click',
        button: 'Disconnect',
        calendar: providerName,
      })
    } catch {
      // TODO handle error https://jira.ops.expertcity.com/browse/SCORE-342
      throw new Error()
    }
  }

  async connectCalendar(calendar: CalendarConnector, userKey: string, providerName: ProviderName) {
    const { protocol, host } = globalThis.location
    const redirectUrl = `${protocol}//${host}${CALENDAR_CONNECTED_ROUTE}`

    try {
      const authorizationUrl = await calendar.connect(userKey, providerName, redirectUrl)
      const newWindowOptions = { height: '571px', width: '708px', left: '200px', top: '200px' }
      openNewWindowFor(authorizationUrl, newWindowOptions)

      snackbar({
        id: 'snackbar_calendar',
        title: getTranslation('Restart your app to see your calendar integrated in GoTo.'),
        actions: [
          {
            label: getTranslation('Restart app'),
            handler() {
              reloadPage()
            },
          },
        ],
      })

      getShellAnalytics().track('GoTo > Settings - Calendar', {
        action: 'Click',
        button: 'Connect',
        calendar: providerName,
      })
    } catch {
      // handle error https://jira.ops.expertcity.com/browse/SCORE-342
    }
  }

  verifyCalendarConnection(calendar: CalendarConnector, userKey: string) {
    const checkConnection = async () => {
      const calendarIsConnected = await calendar.isConnected(userKey)
      if (calendarIsConnected) {
        this.getCalendarSettings()
      }

      if (this.calendarSettings?.office365Settings.connected || this.calendarSettings?.googleSettings.connected) {
        return true
      }
      return false
    }

    retry(checkConnection, {
      retryAfterInMilliseconds: 500,
      maxRetryNumber: 60,
      retryOn: options => Promise.resolve(!options.value),
    })
  }

  render() {
    return html`
      <chameleon-typography class="settings-header" tag="h1" variant="heading-large"
        >${t('Calendar')}</chameleon-typography
      >
      <chameleon-typography class="settings-description-line-1" tag="p" variant="body-medium">
        ${t('Connect your calendar to see all your events in GoTo. You can sync only 1 calendar at a time.')}
      </chameleon-typography>
      <chameleon-typography class="settings-description-line-2" tag="p" variant="body-medium">
        ${isContainer()
          ? t('Restart the GoTo App after connecting to finish.')
          : t('Reload your browser after connecting to finish.')}
      </chameleon-typography>
      <div class="calendar-options">${resolvePromise(this.renderCalendarOptions())}</div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-calendar-settings': GoToCalendarSettings
  }
}
