import { Container, Plugins } from '@getgo/container-client'
import { getAvatarData } from '../avatar-helpers'
import type { ShellApi, ShellAuthContext } from '../models'
import { performanceAsyncWrapper } from '../../common/performance-decorator'
import { getShellLogger } from '../../common/logger'
import type { GoToLicense } from '../../services/feature-flags'
import type { UserAdminInfo } from '../../services/identity/models'

import { getEmailsFromUserProfile, getIdFromUserProfile } from '../../services/identity/userProfile'
import { fetchUserInfoFromInternalAPIs } from '../identity-helpers'
import { setupContextApi } from './context-api'
import { convertUserInfoToUserAdminInfo, convertUserInfoToUserProfileInfo } from '../../services/identity/helpers'
import { ShellUserInfoImpl } from '../user-info/user-info'
import { isInternal } from '../../services/feature-flags/utils'
import { getUserContextPermissions } from '../../services/identity/permissions'
import { getPbxIdFromShellLocalStorage } from '../../services/context'
import { asyncTryAndReturn } from '../../common/catcher'
import { type OrganizationInfo } from '../../services/context/models'

export const getAuthContextUserProfileId = (authContext: Pick<ShellAuthContext, 'userProfile'>): string | undefined =>
  authContext.userProfile?.id

export const getAuthContextAccountKey = (
  authContext: Pick<ShellAuthContext, 'meFromExternalAdmin'>,
): string | undefined => authContext.meFromExternalAdmin?.accountKey

export const getAuthContextAccountKeys = (authContext: Pick<ShellAuthContext, 'accountKeys'>): readonly string[] =>
  authContext.accountKeys ?? []

export const getAuthContextUserProfileEntitlements = (
  authContext: Pick<ShellAuthContext, 'userProfile'>,
): readonly string[] => authContext.userProfile?.entitlements ?? []

export const getAuthContextPbxId = (authContext: Pick<ShellAuthContext, 'pbxId'>): string => authContext.pbxId ?? ''

export const getAuthContextPbxInfo = (
  authContext: Pick<ShellAuthContext, 'contextApi'>,
): OrganizationInfo | undefined => authContext.contextApi?.pbx?.info

export const getAuthContextCurrentAccountName = (authContext: Pick<ShellAuthContext, 'currentAccount'>): string =>
  authContext.currentAccount?.name ?? ''

export const getAuthContextCurrentAccountLicenseDescriptions = (
  authContext: Pick<ShellAuthContext, 'currentAccount'>,
): readonly string[] => {
  const licenseDescriptions: string[] = []
  authContext.currentAccount?.licenses.forEach(license => licenseDescriptions.push(license.description))
  return licenseDescriptions
}

export const getAuthContextCurrentAccountKey = (authContext: Pick<ShellAuthContext, 'currentAccount'>): string =>
  authContext.currentAccount?.key ?? 'guest'

export const getAuthContextCurrentPartnerAccountKey = (authContext: Pick<ShellAuthContext, 'currentAccount'>): string =>
  authContext.currentAccount?.partnerAccountKey ?? ''

export const getAuthContextIsCalendarConnected = (
  authContext: Pick<ShellAuthContext, 'isCalendarConnected'>,
): Promise<boolean> => authContext.isCalendarConnected ?? Promise.resolve(false)

export const getAuthContextLicenses = (authContext: Pick<ShellAuthContext, 'licenses'>): readonly GoToLicense[] =>
  authContext.licenses ?? []

export const getAuthContextLicenseDescriptions = (authContext: Pick<ShellAuthContext, 'licenses'>): string[] => {
  const descriptions = new Set<string>()
  authContext.licenses?.forEach(license => descriptions.add(license.description))

  return Array.from(descriptions)
}

export const getAuthContextProductEnabledDates = (authContext: Pick<ShellAuthContext, 'productEnabledDates'>) =>
  authContext.productEnabledDates ?? []

export const getAuthContextIsUserInternal = (authContext: Pick<ShellAuthContext, 'user'>): boolean =>
  !!authContext.user?.emails?.some(email => isInternal(email.value))

export const getAuthContextUserTimezone = (authContext: Pick<ShellAuthContext, 'user'>): string | undefined =>
  authContext.user.location.timeZone

export const getAuthContextUserCreationDate = (authContext: Pick<ShellAuthContext, 'user'>): string | undefined =>
  authContext.user.meta.createdDate?.toISOString()

export const getAuthContextRoles = (authContext: Pick<ShellAuthContext, 'user'>): string[] => {
  const roles = new Set<string>()
  const userAccounts = authContext.user.accounts
  if (userAccounts.length) {
    userAccounts.forEach(account => {
      if (account.licenses.length) {
        account.licenses.forEach(license => {
          if (license.roles.length) {
            license.roles.forEach(role => roles.add(role))
          }
        })
      }
    })
  }
  return Array.from(roles)
}

const fetchUserInformationFromInternalAPIs = performanceAsyncWrapper(
  'shell_internalApiFetches',
  fetchUserInfoFromInternalAPIs,
)

const getAllAccountsKeys = (userAdminInfo: UserAdminInfo): readonly string[] =>
  userAdminInfo.accounts?.map(account => account?.key)

const getAllLicenses = (userAdminInfo: UserAdminInfo): readonly GoToLicense[] =>
  userAdminInfo.accounts
    ?.map(account => (account.licenses ?? []).map(license => ({ ...license, accountKey: account?.key })))
    ?.filter(licenses => licenses.length > 0)
    ?.flat()

/**
 * Create a ShellAuthContext with the available ShellApi
 * This is used on the children to avoid refetching all the APIs
 * @param shell
 * @returns
 */
export const getAuthContextFromShellApi = (shell?: ShellApi): ShellAuthContext => {
  const authContext: ShellAuthContext = {} as ShellAuthContext
  if (!shell) {
    return authContext
  }

  const { identity, context: contextApi, user } = shell

  authContext.user = user
  authContext.me = identity?.me
  authContext.meFromExternalAdmin = identity?.meFromExternalAdmin

  const entitlements = user.entitlements ?? []
  const emails = getEmailsFromUserProfile(user.emails)
  const firstEmail = emails[0]?.value
  const name = firstEmail
  const id = getIdFromUserProfile(user.key)
  const currentAccountKey = contextApi?.account?.key ?? ''

  authContext.userProfile = {
    entitlements,
    emails,
    firstEmail,
    name,
    id,
  }

  const pbxId = contextApi?.pbx?.id ?? ''
  const pbxRegion = contextApi?.pbx?.info?.region ?? ''
  authContext.contextApi = contextApi
  authContext.pbxId = pbxId
  authContext.pbxRegion = pbxRegion
  authContext.currentAccount = user.getAccountByKey(currentAccountKey)

  authContext.permissions = identity?.permissions
  authContext.avatar = identity?.avatar
  authContext.accountKeys = identity?.meFromExternalAdmin ? getAllAccountsKeys(identity.meFromExternalAdmin) : []
  authContext.licenses = identity?.meFromExternalAdmin ? getAllLicenses(identity.meFromExternalAdmin) : []
  return authContext
}

/**
 * Create a ShellAuthContext based by aggregating API.
 * This is used on the main window
 * @returns Promise<ShellAuthContext>
 */
export const getAuthContextFromApi = async (): Promise<ShellAuthContext> => {
  const authContext: ShellAuthContext = {
    user: new ShellUserInfoImpl({}),
  } as ShellAuthContext

  const userInfo = await fetchUserInformationFromInternalAPIs()

  getShellLogger().setContext('shell-user-key', userInfo?.user?.key ?? 'guest')

  if (!userInfo) {
    return authContext
  }

  const userProfileInfo = convertUserInfoToUserProfileInfo(userInfo)
  authContext.me = userProfileInfo
  authContext.meFromExternalAdmin = convertUserInfoToUserAdminInfo(userInfo)

  const entitlements = userProfileInfo?.entitlements ?? []
  const emails = getEmailsFromUserProfile(userProfileInfo?.emails)
  const firstEmail = emails[0]?.value
  const name = firstEmail
  const id = getIdFromUserProfile(userInfo.user?.key)

  authContext.userProfile = {
    entitlements,
    emails,
    firstEmail,
    name,
    id,
  }

  // This could go elsewhere
  if (id && Container.isPluginAvailable('UserId')) {
    Plugins.UserId.storeCurrAuthenticatedUserKey(id)
  }

  /**
   * Warn if no entitlements are available.
   * This should not be possible but some users data are missing entitlements.
   */
  if (entitlements.length === 0) {
    getShellLogger().warn('User does not have entitlements.')
  }

  let contextSetupPromises = []

  const cachedPbxId = getPbxIdFromShellLocalStorage(userInfo.user?.key) ?? ''

  contextSetupPromises = [
    asyncTryAndReturn({
      func: () => (cachedPbxId ? getUserContextPermissions(cachedPbxId) : Promise.resolve(undefined)),
      defaultReturn: undefined,
    }),
    asyncTryAndReturn({ func: () => setupContextApi(firstEmail, id), defaultReturn: undefined }),
    asyncTryAndReturn({ func: () => getAvatarData(authContext.user), defaultReturn: undefined }),
  ]

  let [userContextResponse, contextApi, avatar] = await Promise.all(contextSetupPromises)

  const pbxId = contextApi?.pbx?.id ?? ''

  if (cachedPbxId !== pbxId) {
    userContextResponse = await getUserContextPermissions(pbxId)
  }

  const pbxRegion = contextApi?.pbx?.info?.region ?? ''
  const permissions = userContextResponse?.permissions ?? []
  const licenses = userContextResponse?.license ? [userContextResponse.license] : []

  authContext.user = new ShellUserInfoImpl({
    userInfo,
    permissions,
    avatar,
    licenses,
  })

  const currentAccountKey = contextApi?.account?.key ?? ''

  authContext.currentAccount = authContext.user.getAccountByKey(currentAccountKey)
  authContext.contextApi = contextApi
  authContext.pbxId = pbxId
  authContext.pbxRegion = pbxRegion
  authContext.permissions = permissions
  authContext.avatar = avatar
  authContext.accountKeys = authContext.meFromExternalAdmin ? getAllAccountsKeys(authContext.meFromExternalAdmin) : []
  authContext.licenses = authContext.meFromExternalAdmin ? getAllLicenses(authContext.meFromExternalAdmin) : []
  authContext.userCreationDate = authContext.user.meta.createdDate?.toISOString()

  return authContext
}
