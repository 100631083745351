import { html, nothing, LitElement } from 'lit'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { getTranslation } from '../../services/i18n/i18nUtils'
import agentStateIndicatorStyles from './agent-state-indicator.styles.scss'

export class AgentStateIndicator extends LitElement {
  static readonly tagName = 'goto-shell-agent-state-indicator'
  static get styles() {
    return agentStateIndicatorStyles
  }
  private getASIndicatorMessage = () => {
    if (getFeatureFlagValue('ccc-new-agent-state-model')) {
      return getTranslation('Agent state sync enabled')
    }
    if (getFeatureFlagValue('ccc-new-agent-state-model-headsup')) {
      return getTranslation('Agent state sync pending')
    }
  }

  render() {
    const indicatorMessage = this.getASIndicatorMessage()
    return indicatorMessage ? html`${indicatorMessage}` : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-shell-agent-state-indicator': AgentStateIndicator
  }
}
