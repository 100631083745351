import { html, LitElement } from 'lit'
import { query } from 'lit/decorators.js'
import { type ButtonComponent } from '@getgo/chameleon-web'

import { getShellLogger } from '../../../common/logger'
import { t } from '../../../directives/translate'
import { getShellApiInstance, getShellAnalytics } from '../../../common/shell-api-helpers'

import { UserPresenceNoteField } from './user-presence-note-field'
import { transKeys, GOTO_EDIT_USER_PRESENCE_NOTE_DIALOG } from './variables'
import elementStyles from './edit-user-presence-note-dialog.styles.scss'
import { getDialogService } from '../../../services/dialog/public-facade'

export class EditUserPresenceNoteDialog extends LitElement {
  static readonly tagName = GOTO_EDIT_USER_PRESENCE_NOTE_DIALOG

  // TODO fix type https://jira.ops.expertcity.com/browse/SCAPI-536
  @query(UserPresenceNoteField.tagName) private noteInput!: UserPresenceNoteField
  // TODO fix type https://jira.ops.expertcity.com/browse/SCAPI-536
  @query('#btn-save') private saveButton!: ButtonComponent

  static get styles() {
    return elementStyles
  }

  private timer?: NodeJS.Timeout

  connectedCallback(): void {
    super.connectedCallback()

    this.fetchUserNote().then((userNote: string) => {
      this.saveButton.disabled = false
      this.noteInput.disabled = false
      this.noteInput.value = userNote
      this.timer = setTimeout(() => this.noteInput.focus(), 100)
    })
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()

    if (typeof this.timer !== 'undefined') {
      clearTimeout(this.timer)
    }
  }

  close() {
    getDialogService().close()
  }

  render() {
    return html`
      <chameleon-dialog
        open
        closable
        size="large"
        @closing=${this.handleClosingEvent}
        @closed=${this.handleClosedEvent}
        close-button-label=${t('Close status dialog')}
      >
        <span slot="title" id="user-note-dialog-title">${t(transKeys.dialogTitle)}</span>
        <goto-user-presence-note-field
          @keyup=${this.handleOnKeyup}
          .hasErrorsState=${this.handleHasErrorsState.bind(this)}
          disabled
        ></goto-user-presence-note-field>
        ${this.renderActionButtons()}
      </chameleon-dialog>
    `
  }

  private renderActionButtons() {
    return html`
      <div slot="actions">
        <chameleon-button
          id="btn-save"
          @click=${() => {
            // Mixpanel; Modal: Save button clicked
            getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'Click', event: 'Save' })
            this.save()
          }}
          disabled
          >${t(transKeys.saveButton)}</chameleon-button
        >
        <chameleon-button
          variant="neutral"
          @click=${() => {
            getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'UI Tap', event: 'Cancel' })
            this.close()
          }}
        >
          ${t(transKeys.cancelButton)}
        </chameleon-button>
      </div>
    `
  }

  private handleHasErrorsState(hasErrors: boolean) {
    this.saveButton.disabled = hasErrors
  }

  private async handleOnKeyup(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'Enter', event: 'Save' })
      this.save()
    }
  }

  handleClosingEvent = () => {
    this.noteInput.reset()
    this.saveButton.disabled = true
    getShellAnalytics().track('GoTo > Custom Status Modal', { action: 'UI Tap', event: 'Close' })
  }

  handleClosedEvent = () => {
    getDialogService().close()
  }

  private save() {
    this.saveButton.disabled = true
    if (!this.noteInput.hasErrors) {
      this.persistUserNote(this.noteInput.value).then(() => this.close())
    }
  }

  private async fetchUserNote() {
    const { presence } = getShellApiInstance()
    try {
      return (await presence?.getUserPresence())?.userNote?.trim() ?? ''
    } catch (err) {
      getShellLogger().error(`Couldn't fetch userNote: ${err}`)
    }
  }

  private async persistUserNote(userNote: string) {
    const { presence } = getShellApiInstance()
    try {
      await presence?.setUserPresence({ userNote })
    } catch (err) {
      getShellLogger().error(`Couldn't update userNote: ${err}`)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-edit-user-presence-note-dialog': EditUserPresenceNoteDialog
  }
}
