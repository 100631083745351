import { html, LitElement, nothing } from 'lit'
import { state } from 'lit/decorators.js'
import updateAppAvatarMenuItem from './update-app-avatar-menu-item.styles.scss'
import { t } from '../../../directives/translate/translate'
import { noop } from '../../../common'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import type { UpdateEvents } from '../../../services/update'
import { updateAvailable, UpdateNamespace } from '../../../services/update'
import type { Listener } from '../../../services/namespaces'
import type { UpdateInfo } from '@getgo/container-client'
import {
  areShellUpdatesEnabled,
  checkForUpdates,
  isCheckForUpdateInProgress,
  displayUpdateConfirmModal,
  displayUpdateErrorModal,
  displayUpToDateSnackbar,
  getAutoUpdate,
  calculateUpdateAgeInDays,
} from '../../../services/update/helpers'

export interface DisableUpdateMenuItemEvent {
  readonly disableUpdateMenuItem: boolean
}

export enum UpdateMenuState {
  CHECKING_FOR_UPDATES = 'checking-for-updates',
  CHECK_FOR_UPDATES = 'check-for-updates',
  UPDATE_AVAILABLE = 'update-available',
}

export class GoToAvatarUpdateMenuItem extends LitElement {
  static readonly tagName = 'goto-avatar-update-menu-item'
  @state() private updateAge: number | undefined
  @state() private updateInProgress = false
  @state() private shellUpdatesEnabled = false
  private unsubscribeUpdateAvailable = noop
  private updateMenuState: UpdateMenuState = UpdateMenuState.CHECK_FOR_UPDATES

  static get styles() {
    return updateAppAvatarMenuItem
  }

  async connectedCallback() {
    super.connectedCallback()
    if (await areShellUpdatesEnabled()) {
      this.shellUpdatesEnabled = true
      this.listenToUpdateAvailableEvent()
      // Needed as of the layer between the chameleon-item-menus in this component and the chameleon-menu in the parent is preventing the events from being forwarded
      this.addEventListener('keydown', this.handleKeyDown)
      this.addEventListener('click', this.handleClick)
    } else {
      this.dispatchEvent(
        new CustomEvent('removeUpdateMenuItem', {
          bubbles: true,
          composed: true,
        }),
      )
    }
  }

  disconnectedCallback() {
    this.unsubscribeUpdateAvailable()
    this.unsubscribeUpdateAvailable = noop
    this.removeEventListener('keydown', this.handleKeyDown)
    this.removeEventListener('click', this.handleClick)
    super.disconnectedCallback()
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.triggerAction(event)
    }
  }

  private handleClick(event: MouseEvent) {
    this.triggerAction(event)
  }

  private triggerAction(event: Event) {
    if (this.updateMenuState === UpdateMenuState.CHECK_FOR_UPDATES) {
      event.preventDefault()
      this.triggerCheck()
    }
    if (this.updateMenuState === UpdateMenuState.UPDATE_AVAILABLE) {
      displayUpdateConfirmModal()
    }
  }

  private listenToUpdateAvailableEvent() {
    const { eventBus } = getShellApiInstance()
    const { updateAvailable, checkForUpdatesStartedOrStopped } = eventBus.subscribeTo<
      typeof UpdateNamespace,
      typeof UpdateEvents
    >(UpdateNamespace)

    const updateAvailableHandler: Listener<UpdateInfo> = (updateInfo: UpdateInfo) => {
      this.updateAge = calculateUpdateAgeInDays(updateInfo)
    }

    const checkForUpdatesStartedOrStoppedHandler: Listener<boolean> = (inProgress: boolean) => {
      this.updateInProgress = inProgress
    }

    updateAvailable.on(updateAvailableHandler)
    checkForUpdatesStartedOrStopped.on(checkForUpdatesStartedOrStoppedHandler)

    this.updateInProgress = isCheckForUpdateInProgress()

    this.unsubscribeUpdateAvailable = () => {
      updateAvailable.removeListener(updateAvailableHandler)
      checkForUpdatesStartedOrStopped.removeListener(checkForUpdatesStartedOrStoppedHandler)
    }

    const update = getAutoUpdate()
    if (update) {
      updateAvailableHandler(update)
    }
  }

  private renderMenuItem() {
    this.updateMenuState = UpdateMenuState.UPDATE_AVAILABLE
    return html`
      <chameleon-menu-item data-test="update-button" class="update-button" aria-label=${t('Update GoTo app')}>
        ${t('Update GoTo app')}
        <goto-update-icon .updateAge=${this.updateAge as number} slot="end"></goto-update-icon>
      </chameleon-menu-item>
    `
  }

  private handleupdateAvailable(update: UpdateInfo) {
    updateAvailable(update)
    displayUpdateConfirmModal()
  }

  private triggerCheck() {
    checkForUpdates(this.handleupdateAvailable, displayUpToDateSnackbar, displayUpdateErrorModal)
  }

  private renderCheckForUpdatesButton() {
    this.updateMenuState = UpdateMenuState.CHECK_FOR_UPDATES
    return html`<chameleon-menu-item class="check-button" data-test="check-button" aria-label=${t('Check for updates')}>
      ${t('Check for updates')}</chameleon-menu-item
    >`
  }

  private renderCheckingForUpdatesButton() {
    this.updateMenuState = UpdateMenuState.CHECKING_FOR_UPDATES
    return html`<chameleon-menu-item class="with-spinner" data-test="checking-button" disabled>
      ${t('Checking for updates...')}
      <div slot="end" class="update-spinner"></div>
    </chameleon-menu-item>`
  }

  render() {
    if (!this.shellUpdatesEnabled) {
      return nothing
    }

    return this.updateAge !== undefined
      ? this.renderMenuItem()
      : this.updateInProgress
        ? this.renderCheckingForUpdatesButton()
        : this.renderCheckForUpdatesButton()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-avatar-update-menu-item': GoToAvatarUpdateMenuItem
  }
}
