import { onWindowUnload } from '../../../common/helpers/window'
import { type ExternalInterfaceCallback, getExternalInterface } from '../../external-interface'
import { type UserPreferencesAPI } from '../models'

const USER_PREFERENCES_CONNECTOR_MESSAGE = 'user-preferences-connector'

interface UserPreferencesConnectorPayload {
  method: 'deletePreference' | 'setPreference'
  path: string
  data?: object
}

/**
 * Class that adapts a preferences connector with external interface
 */
export class UserPreferencesConnectorAdapter implements UserPreferencesAPI {
  constructor(private readonly userPreferencesConnector: UserPreferencesAPI) {
    getExternalInterface().addCallback(USER_PREFERENCES_CONNECTOR_MESSAGE, this.handleMessage)
    onWindowUnload(() => {
      getExternalInterface().removeCallback(USER_PREFERENCES_CONNECTOR_MESSAGE, this.handleMessage)
    })
  }

  private readonly handleMessage: ExternalInterfaceCallback<UserPreferencesConnectorPayload> = payload => {
    switch (payload.method) {
      case 'deletePreference':
        this.userPreferencesConnector.deletePreference(payload.path)
        break
      case 'setPreference':
        this.userPreferencesConnector.setPreference(payload.path, payload.data)
        break
    }
  }

  getPreferences = () => this.userPreferencesConnector.getPreferences()

  getPreference = <T, R>(path: string, defaultValue?: T): Promise<R> =>
    this.userPreferencesConnector.getPreference(path, defaultValue)

  setPreference = <T>(path: string, data: T): Promise<void> => {
    getExternalInterface().send({
      type: USER_PREFERENCES_CONNECTOR_MESSAGE,
      payload: {
        method: 'setPreference',
        path,
        data,
      },
    })
    return this.userPreferencesConnector.setPreference(path, data)
  }

  deletePreference = (path: string): Promise<void> => {
    getExternalInterface().send({
      type: USER_PREFERENCES_CONNECTOR_MESSAGE,
      payload: {
        method: 'deletePreference',
        path,
      },
    })
    return this.userPreferencesConnector.deletePreference(path)
  }
}
