import type { ExternalUserKey } from '../../core/models'
import { authenticatedFetch } from '../auth'
import type {
  ChannelId,
  CreateSubscriptionsRequest,
  CreateSubscriptionsResponse,
  GetPresenceResponse,
  SubscriptionId,
  UserPresence,
} from './models'

export class PresenceService {
  private readonly endpoints = {
    presence: `${this.apiBaseUrl}/presence`,
    subscriptions: `${this.apiBaseUrl}/subscriptions`,
    subscriptionsById: (subscriptionId: string) => `${this.apiBaseUrl}/subscriptions/${subscriptionId}`,
    userPresence: `${this.apiBaseUrl}/user-presence`,
  }

  constructor(private readonly apiBaseUrl: string) {}

  getUserPresence() {
    return authenticatedFetch<UserPresence>(this.endpoints.userPresence, { method: 'GET' })
  }

  setUserPresence(userPresence: Partial<UserPresence>) {
    return authenticatedFetch<UserPresence>(this.endpoints.userPresence, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userPresence),
    })
  }

  getPresence(userKeys: readonly ExternalUserKey[]) {
    const params = new URLSearchParams()
    userKeys.forEach(userKey => params.append('userKey', userKey))

    return authenticatedFetch<GetPresenceResponse>(`${this.endpoints.presence}?${params.toString()}`, {
      method: 'GET',
    })
  }

  createSubscription(request: CreateSubscriptionsRequest) {
    return authenticatedFetch<CreateSubscriptionsResponse>(this.endpoints.subscriptions, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    }).then(async response => await response.json())
  }

  deleteSubscriptionByChannelId(channelId: ChannelId) {
    return authenticatedFetch<void>(`${this.endpoints.subscriptions}?channelId=${channelId}`, {
      method: 'DELETE',
      retry: {
        maxRetryNumber: 3,
        retryAfterInMilliseconds: 300,
      },
    })
  }

  deleteSubscriptionBySubscriptionId(subscriptionId: SubscriptionId) {
    return authenticatedFetch<void>(this.endpoints.subscriptionsById(subscriptionId), { method: 'DELETE' })
  }
}
