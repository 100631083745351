import { getFromLocalStorage, setToLocalStorage } from '../../../common/dom-helpers'
import { safeParseJSON } from '../../../common/helpers/json'
import { emitUserPreferencesUpdatedEvent } from '../events'
import { type UserPreferencesAPI, LOCAL_STORAGE_KEY, type UserPreferences } from '../models'

export abstract class AbstractUserPreferencesServiceConnector implements UserPreferencesAPI {
  abstract getPreferences(): Promise<UserPreferences>

  abstract getPreference<T, R>(path: string, defaultValue?: T): Promise<R>

  abstract setPreference<T>(path: string, data: T): Promise<void>

  abstract deletePreference(path: string): Promise<void>

  /**
   * Deletes the value for a given string path
   * E.g. "shortcuts.keyboard.f1" would delete the F1 shortcut preference
   * @param preferences - the current user preferences object
   * @param path - the string path to an existing entry in the user preferences
   * @returns updated user preferences with removed preference
   */
  protected deletePreferenceByPath(preferences: any, path: string): any {
    const [head, ...rest] = path.split('.')

    if (rest.length) {
      return {
        ...preferences,
        [head]: this.deletePreferenceByPath(preferences[head], rest.join('.')),
      }
    }

    delete preferences[head]
    return preferences
  }

  /**
   * Sets the value for a given string path
   * E.g. ("shortcuts.keyboard.f1", "backspace") would set F1 shortcut to "backspace"
   * @param preferences - the current user preferences object
   * @param path - the string path to a new or existing entry in the user preferences
   * @param data - the value to be set for the given string path
   * @returns updated user preferences
   */
  protected setPreferenceByPath<T extends Record<string, unknown>, V>(preferences: T, path: string, data: V): T {
    const [head, ...rest] = path.split('.')

    return {
      ...preferences,
      [head]: rest.length
        ? this.setPreferenceByPath((preferences[head] as Record<string, unknown>) || {}, rest.join('.'), data)
        : (data as unknown),
    }
  }

  /**
   * Retrieves ALL of the user preferences from the local storage
   * @returns User Preferences object
   */
  protected getPreferencesFromLocalStorage() {
    const preferences = getFromLocalStorage(LOCAL_STORAGE_KEY)
    if (preferences) {
      return safeParseJSON<UserPreferences>(preferences, {})
    }
  }

  /**
   * Finds the value for a given string path
   * E.g. "shortcuts.keyboard.f1" would return the F1 keyboard shortcut value
   * @param path - the string path to an entry in the user preferences
   * @returns the value of a given string path
   */
  protected getPreferenceByPath(preferences: any, path: string): any {
    path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    path = path.replace(/^\./, '') // strip a leading dot
    const splitPath = path.split('.')
    for (let i = 0, n = splitPath.length; i < n; ++i) {
      const key = splitPath[i]
      if (key in preferences) {
        preferences = preferences[key]
      } else {
        return
      }
    }
    return preferences
  }

  protected deletePreferenceFromLocalStorage(preferences: any, path: string) {
    const updatedPreferences = this.deletePreferenceByPath(preferences, path)
    setToLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updatedPreferences))
    emitUserPreferencesUpdatedEvent({ path, data: null })
  }
}
