import { setToLocalStorage } from '../../common/dom-helpers'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { environment } from '../../environments'
import { authenticatedFetch } from '../auth'
import {
  USER_ACCOUNT_PREFERENCES_KEY,
  type UserPreferences,
  type UserPreferencesAPI,
} from './models'
import { getShellLogger } from '../../common/logger'

export class UserPreferencesService implements UserPreferencesAPI {
  constructor(private readonly userPreferencesConnector: UserPreferencesAPI) {}

  getPreferences = async (): Promise<UserPreferences> => await this.userPreferencesConnector.getPreferences()

  getPreference = async <T, R>(path: string, defaultValue?: T): Promise<R> =>
    await this.userPreferencesConnector.getPreference(path, defaultValue)

  setPreference = async <T>(path: string, data: T): Promise<void> =>
    await this.userPreferencesConnector.setPreference(path, data)

  deletePreference = async (path: string): Promise<void> => await this.userPreferencesConnector.deletePreference(path)
}

/**
 * @description Fetches user preferences from the server.
 */
export const doFetchAndSetUserPreferences = async (): Promise<void> => {
  try {
    const { context: { account: { key: accountKey } = {} } = {}, user: { key: userKey } = {} } = getShellApiInstance()

    if (!accountKey || !userKey) {
      throw new Error(
        `[User Preferences] - Account or user key not found. Skipping user preferences fetch and merge. accountKey: ${accountKey}, userKey: ${userKey}`,
      )
    }

    const response = await authenticatedFetch(
      `${environment().getShellBffServiceBaseUrl()}/user-settings/accounts/${accountKey}/users/${userKey}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: undefined,
      },
    )

    const userPreferences = await response.json()

    setToLocalStorage(USER_ACCOUNT_PREFERENCES_KEY, JSON.stringify(userPreferences))
  } catch (error) {
    getShellLogger().info(`[User Preferences] - Error fetching and merging user preferences:${error}`)
  }
}
