import { html } from 'lit'
import { property } from 'lit/decorators.js'
import { ShellElement } from '../../common/shell-element'
import { type SkeletonVariant, type SkeletonSize } from '@getgo/chameleon-core'
import loadingStyles from './component.styles.scss'

export const evenWidth = 55
export const oddWidth = 75

export class GotoLoadingState extends ShellElement {
  static readonly tagName = 'goto-loading-state'

  @property({ type: Boolean }) avatar = false
  @property({ type: Boolean }) contact = false
  @property({ type: Number }) iteration = 0
  @property({ type: String }) width = '100%'
  @property() size: SkeletonSize = 'medium'
  @property() variant: SkeletonVariant = 'rectangle'

  static get styles() {
    return loadingStyles
  }

  renderLoadingStateWithAvatar() {
    const topWidth = this.iteration % 2 == 0 ? evenWidth : oddWidth

    return html`
      <div class="loading-skeleton avatar">
        <div class="loading-avatar">
          <chameleon-skeleton variant="circle"></chameleon-skeleton>
        </div>
        <div class="loading-sentence">
          <chameleon-skeleton variant="rectangle" size="${this.size}" width="${topWidth}%"></chameleon-skeleton>
        </div>
      </div>
    `
  }

  renderLoadingStateWithContact() {
    const topWidth = this.iteration % 2 == 0 ? evenWidth : oddWidth
    const bottomWidth = this.iteration % 2 == 0 ? evenWidth - 10 : oddWidth - 10

    return html`
      <div class="loading-skeleton contact">
        <div class="loading-avatar">
          <chameleon-skeleton variant="circle" size="large"></chameleon-skeleton>
        </div>
        <div class="loading-sentence">
          <chameleon-skeleton variant="rectangle" size="xsmall" width="${topWidth}%"></chameleon-skeleton>
          <chameleon-skeleton variant="rectangle" size="xxsmall" width="${bottomWidth}%"></chameleon-skeleton>
        </div>
      </div>
    `
  }

  renderDefaultLoadingState() {
    let iterativeWidth
    if (this.iteration) {
      iterativeWidth = this.iteration % 2 == 0 ? evenWidth : oddWidth
    }

    return html`<chameleon-skeleton
      variant="${this.variant}"
      size="${this.size}"
      width="${iterativeWidth ? iterativeWidth + '%' : this.width}"
    ></chameleon-skeleton>`
  }

  render() {
    if (this.avatar) {
      return this.renderLoadingStateWithAvatar()
    } else if (this.contact) {
      return this.renderLoadingStateWithContact()
    } else {
      return this.renderDefaultLoadingState()
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-loading-state': GotoLoadingState
  }
}
