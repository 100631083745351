import { randomID } from '../../common'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getUserTimezone } from '../../common/user-helpers'
import { getAccountAndPBXFromLocalStorage } from '../../services/context'
import {
  type OneTimePeriodWithId,
  type ShellOneTimePeriod,
  TimeZoneSource,
  type UserSchedule,
} from './schedule-manager/models'
import {
  dateTimeIntervalsOverlap,
  formatDateToTimezone,
  getCurrentLocalDateFormatted,
  getRoundedDateTime,
  initialTimeOfTheDay,
  lastTimeOfTheDay,
  timeIntervalMinutes,
} from './schedule-settings-utils'

export const getDefaultUserSchedule = (): UserSchedule => {
  const user = getShellApiInstance()?.user
  const accountAndPBXFromLocalStorage = getAccountAndPBXFromLocalStorage(user?.key ?? '')

  return {
    userId: accountAndPBXFromLocalStorage.pbxUserId ?? '',
    userKey: user?.key ?? '',
    orgId: accountAndPBXFromLocalStorage.pbxId ?? '',
    accountKey: accountAndPBXFromLocalStorage.accountKey ?? '',
    timezoneSource: TimeZoneSource.USER,
    timezone: user?.location?.timeZone,
    workPeriods: [],
    oneTimePeriods: [],
    dndAutomationEnabled: false,
    enabled: false,
    userCanEdit: true,
  }
}

export const getScheduleSavedStorageKey = () => {
  const userKey = getShellApiInstance()?.user?.key
  return `${userKey}-schedule-settings-saved`
}

export const createShellOneTimePeriod = (): ShellOneTimePeriod => {
  const userTimezone = getUserTimezone()

  const roundedDate = getRoundedDateTime(timeIntervalMinutes)

  const nextDateObject = new Date(roundedDate)
  nextDateObject.setMinutes(nextDateObject.getMinutes() + timeIntervalMinutes) // next valid  time slot depending on the interval

  return {
    id: randomID(),
    name: '',
    startDay: formatDateToTimezone(roundedDate, userTimezone, 'date'),
    endDay: formatDateToTimezone(nextDateObject, userTimezone, 'date'),
    startTime:formatDateToTimezone(roundedDate, userTimezone, 'time'),
    endTime: formatDateToTimezone(nextDateObject, userTimezone, 'time' ),
    dndEnabled: false,
    allDay: false,
  }
}

export const convertOneTimePeriodWithIdToShellOneTimePeriod = (
  oneTimePeriodWithId: OneTimePeriodWithId,
): ShellOneTimePeriod => {
  const [startDay, startTime] = oneTimePeriodWithId.startDate.split('T')
  const [endDay, endTime] = oneTimePeriodWithId.endDate.split('T')
  const allDay = startTime === initialTimeOfTheDay && endTime === lastTimeOfTheDay
  return {
    id: oneTimePeriodWithId.id,
    name: oneTimePeriodWithId.name,
    startDay,
    endDay,
    startTime,
    endTime,
    dndEnabled: oneTimePeriodWithId.dndEnabled,
    allDay,
  }
}

export const convertShellOneTimePeriodToOneTimePeriodWithId = (
  shellOneTimePeriod: ShellOneTimePeriod,
): OneTimePeriodWithId => {
  const startDate = `${shellOneTimePeriod.startDay}T${shellOneTimePeriod.startTime}`
  const endDate = `${shellOneTimePeriod.endDay}T${shellOneTimePeriod.endTime}`
  return {
    id: shellOneTimePeriod.id,
    name: shellOneTimePeriod.name,
    startDate,
    endDate,
    dndEnabled: shellOneTimePeriod.dndEnabled,
  }
}

// Function to combine date and time into a single Date object for comparison
const parseDateTime = (dateStr: string, timeStr: string) => new Date(`${dateStr}T${timeStr}`)

/**
 * Verify if a given oneTimePeriod overlaps with any of the existing oneTimePeriods
 * @param newOneTimePeriod the new oneTimePeriod to check for overlap
 * @param oneTimePeriods the list of existing oneTimePeriods to check against
 * @returns a boolean value indicating whether the new oneTimePeriod overlaps with any of the existing oneTimePeriods
 */
export const oneTimePeriodHasOverlap = (newOneTimePeriod: ShellOneTimePeriod, oneTimePeriods: ShellOneTimePeriod[]) => {
  if (
    !newOneTimePeriod.startDay ||
    !newOneTimePeriod.startTime ||
    !newOneTimePeriod.endDay ||
    !newOneTimePeriod.endTime
  ) {
    return false
  }
  const newStart = parseDateTime(newOneTimePeriod.startDay, newOneTimePeriod.startTime)
  const newEnd = parseDateTime(newOneTimePeriod.endDay, newOneTimePeriod.endTime)

  return oneTimePeriods.some(oneTimePeriod => {
    if (newOneTimePeriod.id !== oneTimePeriod.id) {
      const existingStart = parseDateTime(oneTimePeriod.startDay, oneTimePeriod.startTime)
      const existingEnd = parseDateTime(oneTimePeriod.endDay, oneTimePeriod.endTime)

      return dateTimeIntervalsOverlap(
        existingStart.getTime(),
        existingEnd.getTime(),
        newStart.getTime(),
        newEnd.getTime(),
      )
    }
  })
}

// Function to check if the end date is after the start date
const isEndDateIsAfterStartDate = (endDate: Date, startDate: Date) => endDate > startDate

/**
 * Verifies that the required fields are filled for the custom hours modal and that the end date is after the start date
 * @param shellOneTimePeriod the period to validate
 * @returns a boolean value indicating whether the required fields are valid
 */
export const hasAllRequiredCustomHoursFieldsFilled = (shellOneTimePeriod: ShellOneTimePeriod) => {
  if (!shellOneTimePeriod.name) {
    return false
  }
  const hasStartDate = !!shellOneTimePeriod.startDay && !!shellOneTimePeriod.startTime
  const hasEndDate = !!shellOneTimePeriod.endDay && !!shellOneTimePeriod.endTime
  if (hasStartDate && hasEndDate) {
    return isEndDateIsAfterStartDate(
      new Date(`${shellOneTimePeriod.endDay}T${shellOneTimePeriod.endTime}`),
      new Date(`${shellOneTimePeriod.startDay}T${shellOneTimePeriod.startTime}`),
    )
  }
  return false
}

/**
 * Checks if a given one-time period's end date is in the past taking into account the user's timezone
 * @param endDate the end date of the one-time period in the format 'YYYY-MM-DDTHH:mm:ss'
 * @returns true if the period has already ended, false otherwise
 */
export const isOneTimePeriodInThePast = (endDate: string): boolean => endDate < getCurrentLocalDateFormatted('datetime')

/**
 * Sorts a list of one-time periods by their end date, separating unsaved periods from future/current or past ones
 * Future and current periods are kept in their original order, while past periods are sorted in descending order and each new unsaved period is always on top of the list
 * @param periods the list of one time periods to sort
 * @returns the sorted list of one time periods
 */
export const getSortedOneTimePeriods = (
  periods: OneTimePeriodWithId[]): OneTimePeriodWithId[] => {

  const unsavedPeriods = periods.filter(period => Object.keys(period)[0] === 'id')

  // periods are already sorted with the most upcoming event first, so we keep their order as is
  const savedFutureAndCurrentPeriods = periods.filter(period =>
    !isOneTimePeriodInThePast(period.endDate) && !unsavedPeriods.includes(period)
  )

  // most recently ended first
  const savedPastPeriods = periods
    .filter(period => isOneTimePeriodInThePast(period.endDate) && !unsavedPeriods.includes(period))
    .sort((a, b) => b.endDate.localeCompare(a.endDate))
  return [...unsavedPeriods.reverse(), ...savedFutureAndCurrentPeriods, ...savedPastPeriods]
}
