import { type RegisterApplicationConfig } from 'single-spa'
import { SHELL_APP_PREFIX } from '../constants'
import { SHELL_SETTINGS_ROUTE } from '../../common/routes'
import GoToSettings from '../../packages/settings/goto-settings'

export const gotoSettingPageExperienceConfig: Omit<RegisterApplicationConfig, 'customProps'> = {
  name: `${SHELL_APP_PREFIX}/settings`,
  app: GoToSettings,
  activeWhen: [SHELL_SETTINGS_ROUTE],
}
