import { ShellElement } from '../../common/shell-element'
import { html } from 'lit'
import { property } from 'lit/decorators.js'
import { t } from '../../directives/translate'
import { getDialogService } from '../../services/dialog/public-facade'

const FRAME_SRC =
  'https://chat.dante-ai.com/embed/?kb_id=f9c9fa93-408e-4f2f-ba5e-d4b8171573e4&token=7024c561-fd12-4a21-bf6d-09f15e43c967&modeltype=gpt-4-omnimodel-mini&mode=false'
export class GotoAssistant extends ShellElement {
  static readonly tagName = 'goto-assistant'

  @property({ type: String }) searchString = ''

  close() {
    getDialogService().close()
  }

  render() {
    return html`
      <chameleon-dialog open closable size="large" @closed=${this.close} close-button-label=${t('Close dialog')}>
        <div class="goto-assistant">
          <iframe
            title="goto-assistant"
            src=${FRAME_SRC}
            allow="clipboard-write; microphone"
            width="100%"
            height="500"
            frameborder="0"
          ></iframe>
        </div>
      </chameleon-dialog>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-assistant': GotoAssistant
  }
}
