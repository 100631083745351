import { getFromLocalStorage, setToLocalStorage } from '../../common/dom-helpers'
import { getShellLogger } from '../../common/logger'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { LocalStorageKeys } from '../../environments'
import type { Theme } from '../shell-namespace'
import { emitThemeChanged } from '../shell-namespace'
import { GoToAppTheme, type MSTheme } from './models'

const DEFAULT_THEME: Theme = 'system'

export async function setChameleonTheme(theme: Theme) {
  const currentTheme = await getChameleonTheme()
  if (currentTheme !== theme) {
    saveChameleonTheme(theme)
    emitThemeChanged(theme)
  }
}

export function updateChameleonThemeProvider(themeProvider: Element, theme: Theme) {
  if (theme === 'system') {
    themeProvider.setAttribute('automatic', 'true')
    themeProvider.removeAttribute('theme')
  } else {
    themeProvider.setAttribute('theme', theme)
    themeProvider.removeAttribute('automatic')
  }
}

export function saveChameleonTheme(theme: Theme) {
  getShellApiInstance().userPreferences?.setPreference('theme', theme)
  setToLocalStorage(LocalStorageKeys.chameleonTheme, theme)
}

/** Returns the theme saved in a legacy `chameleon-theme` local storage key.
 * Due to being synchronous and not dependent on `getShellApiInstance().userPreferences`, this function is de facto what
 * is currently used to determine the theme at app load time. In contrast, see `getChameleonTheme`.
 */
export function getDefaultChameleonTheme(): Theme {
  const savedTheme = getFromLocalStorage(LocalStorageKeys.chameleonTheme) as Theme | null
  return savedTheme ?? DEFAULT_THEME
}

/** Returns the theme saved in the `getShellApiInstance().userPreferences`, but due to being async and also dependent on
 * the `userPreferences` API to be initialized, this function is not actually being used for anything else other than
 * to remember the user choice in the Appearance settings. In contrast, see `getDefaultChameleonTheme`.
 */
export async function getChameleonTheme(): Promise<Theme> {
  const userPreferences = getShellApiInstance().userPreferences
  const theme = (await userPreferences?.getPreference('theme')) as Theme | null
  return theme ?? DEFAULT_THEME
}

const resolveMSTeamsTheme = (theme: MSTheme): Theme => {
  if (theme === 'dark') {
    return GoToAppTheme.DARK
  } else if (theme === 'contrast') {
    return GoToAppTheme.HIGH_CONTRAST
  }

  return GoToAppTheme.LIGHT
}

export const subscribeToMsTeamThemeChanged = () => {
  import('@microsoft/teams-js')
    .then(({ app }) =>
      app.initialize().then(() =>
        app.getContext().then(context => {
          emitThemeChanged(resolveMSTeamsTheme(context.app.theme as MSTheme))
          app.registerOnThemeChangeHandler((data: MSTheme) => {
            emitThemeChanged(resolveMSTeamsTheme(data))
          })
        }),
      ),
    )
    .catch(err => {
      getShellLogger().error('Could not initialize msteams sdk', err)
    })
}
