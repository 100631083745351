/* eslint-disable lit/binding-positions */
import { type PropertyValues, type TemplateResult, html } from 'lit'
import { query, state } from 'lit/decorators.js'
import appearanceSettingsStyles from './appearance-settings.styles.scss'
import { t } from '../../directives/translate'
import { ShellElement } from '../../common/shell-element'
import { type RadioGroupComponent } from '@getgo/chameleon-web'
import { getChameleonTheme, getDefaultChameleonTheme, setChameleonTheme } from '../../services/theme'
import { type Theme } from '../../services/shell-namespace'

export class GoToAppearanceSettings extends ShellElement {
  static readonly tagName = 'goto-appearance-settings'

  @query('chameleon-radio-group') radioGroup!: RadioGroupComponent
  @state() private selectedTheme: Theme = getDefaultChameleonTheme()
  static get styles() {
    return appearanceSettingsStyles
  }

  async firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties)
    this.selectedTheme = await getChameleonTheme()
  }

  private async handleThemeChangeEvent(event: Event) {
    const radioGroup = event.currentTarget as RadioGroupComponent
    this.selectedTheme = radioGroup.value as Theme
    await setChameleonTheme(this.selectedTheme)
  }

  private renderRadioButtonContent(image: TemplateResult, label: TemplateResult) {
    return html`<div class="radio-button-content">
      <div>${image}</div>
      <div class="label">${label}</div>
    </div>`
  }

  private renderLightRadioButtonContent() {
    const imageElement = html`<img
      alt=${t('Light Mode')}
      class="theme-illustration light-image"
      src="/assets/theme/Light_Theme_illustration.svg"
    />`
    const labelElement = html`${t('Light')}`
    return this.renderRadioButtonContent(imageElement, labelElement)
  }

  private renderDarkRadioButtonContent() {
    const imageElement = html`<img
      alt=${t('Dark Mode')}
      class="theme-illustration dark-image"
      src="/assets/theme/Dark_Theme_illustration.svg"
    />`
    const labelElement = html`${t('Dark')}`
    return this.renderRadioButtonContent(imageElement, labelElement)
  }

  private renderSystemRadioButtonContent() {
    const imageElement = html`<img
      alt=${t('System Mode')}
      class="theme-illustration system-image"
      src="/assets/theme/System_Theme_illustration.svg"
    />`
    const labelElement = html`${t('System')}`
    return this.renderRadioButtonContent(imageElement, labelElement)
  }

  render() {
    // Note: We removed the section header (was "Theme") per UX guidelines. If we want to add more sections to this page,
    // we can add it back, but this is a reminder to name it "Mode" instead of "Theme" if we do. See SCORE-3011.
    return html`
      <chameleon-typography tag="h1" class="settings-header" variant="heading-large"
        >${t('Appearance')}</chameleon-typography
      >
      <chameleon-typography class="settings-description" tag="p" variant="body-medium"
        >${t('Change the way things look around here')}</chameleon-typography
      >

      <chameleon-radio-group
        @change=${this.handleThemeChangeEvent}
        value=${this.selectedTheme}
        orientation="horizontal"
        class="theme-option-group"
      >
        <chameleon-radio-button value="light" class="theme-radio-button light-button"
          >${this.renderLightRadioButtonContent()}</chameleon-radio-button
        >
        <chameleon-radio-button value="dark" class="theme-radio-button dark-button"
          >${this.renderDarkRadioButtonContent()}</chameleon-radio-button
        >
        <!-- The system option requires a tooltip (see figma). https://jira.ops.expertcity.com/browse/SCORE-2453
         Cannot be added due to https://jira.ops.expertcity.com/browse/CHAMELEON-2192

         Reminder: To remove the eslint-disable lit/binding-positions comment at the top of the file

         <chameleon-tooltip>
          <span slot="tooltip">${t('Automatically match the theme your system uses')}</span> -->

        <chameleon-radio-button value="system" class="theme-radio-button system-button"
          >${this.renderSystemRadioButtonContent()}</chameleon-radio-button
        >
        <!-- </chameleon-tooltip> -->
      </chameleon-radio-group>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-appearance-settings': GoToAppearanceSettings
  }
}
