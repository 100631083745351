/* istanbul ignore file */
import { AsyncDirective, directive, type PartInfo, PartType  } from 'lit/async-directive.js'
import type { Translatable } from '../../common/translate-helpers/types'
import { resolveTranslatable } from '../../common/translate-helpers/translatable'
import { getI18n } from '../../services/i18n/i18nUtils'

interface I18nPartValue {
  readonly key: string
  readonly options: Record<string, unknown>
}

/**
 * A translate directive class that updates the translation when the language changes
 * @param translatable the `Translatable` to translate
 * @param optionsFn (optional) a function that returns placeholder substitution values (e.g. `() => ({ name: 'Joe Smith' })`)
 */
class TranslateDirective extends AsyncDirective {
  // Keep the last args with which the directive was rendered. We will use them in case the language changes and we need
  // to re-render the directive.
  private lastRenderArgs?: { translatable: Translatable; optionsFn?: () => I18nPartValue['options'] }

  constructor(partInfo: PartInfo) {
    super(partInfo)

    // We allow the translate directive to be used only in HTML child positions, HTML attibute positions, or property
    // value positions (name prefixed with `.`). This is meant as a developer safeguard. If you find a case where it
    // makes sense to be used in other positions, please adjust the check below.
    const positionIsOk =
      partInfo.type === PartType.CHILD || partInfo.type === PartType.ATTRIBUTE || partInfo.type === PartType.PROPERTY
    if (!positionIsOk) {
      throw new Error('Misplaced TranslateDirective?')
    }

    // Subscribe to events.
    this.subscribeToLanguageChanged()
    this.subscribeToPageHide()
  }

  override render(translatable: Translatable, optionsFn?: () => I18nPartValue['options']) {
    this.lastRenderArgs = { translatable, optionsFn }
    return this.calcRenderValue(translatable, optionsFn)
  }

  // When the directive is disconnected from the DOM, unsubscribe from listeners and/or long-help resources, so it can
  // be garbage collected.
  override disconnected() {
    this.unsubscribeFromLanguageChanged()
  }

  // If the subtree the directive is in was disconnected and subsequently re-connected, re-subscribe to make the
  // directive operable again.
  override reconnected() {
    this.subscribeToLanguageChanged()
  }

  private calcRenderValue(translatable: Translatable, optionsFn?: () => I18nPartValue['options']) {
    const options = optionsFn?.() ?? {}
    const translation =
      typeof translatable === 'string' ? getI18n().t(translatable, options) : resolveTranslatable(translatable)

    return translation
  }

  private subscribeToLanguageChanged() {
    getI18n().on('languageChanged', this.languageChangedHandler)
  }

  private unsubscribeFromLanguageChanged() {
    getI18n().off('languageChanged', this.languageChangedHandler)
  }

  private subscribeToPageHide() {
    window.addEventListener('pagehide', this.pageHideHandler)
  }

  // Keep as arrow function so `this` is captured correctly when this function is called from a different context.
  private readonly languageChangedHandler = () => {
    // Language changed. Re-render the directive with the last args it was rendered with.
    if (this.lastRenderArgs) {
      this.setValue(this.calcRenderValue(this.lastRenderArgs.translatable, this.lastRenderArgs.optionsFn))
    }
  }

  // Keep as arrow function so `this` is captured correctly when this function is called from a different context.
  private readonly pageHideHandler = (e: PageTransitionEvent) => {
    // We unsubscribe from language changes when the page is unloaded.
    // Not entirely sure if this is needed. Tests show that on page unload, connected directives are not disconnected
    // and remain subscribed to language changes, but whether this is a problem when the page unloads, I am not sure.
    if (!e.persisted) {
      this.unsubscribeFromLanguageChanged()
    }
  }
}

/**
 * A translate directive that updates the translation when the language changes. See `TranslateDirective` for more info.
 * @example
 * t('key', () => ({ name: 'Joe Smith' })) // 'key' maps to 'Hello {{name}}' where `{{name}}` is a placeholder
 * t('key')
 */
export const t = directive(TranslateDirective)
