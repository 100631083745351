import { LitElement, html } from 'lit'
import { query } from 'lit/decorators.js'
import { SVG_APPLE_MONOCHROMATIC } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import macOSSupportBannerStyles from './macos-support-banner.styles.scss'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { canShowTrialBanner } from '../../core/hooks/trial-banner'
import { type BannerComponent } from '@getgo/chameleon-web'

export class MacOSSupportBanner extends LitElement {
  static readonly tagName = 'goto-macos-support'
  @query('chameleon-banner') private chameleonBanner!: BannerComponent

  private learnMoreLink = 'https://support.goto.com/connect/help/how-do-i-download-and-install-the-goto-app'

  static get styles() {
    return macOSSupportBannerStyles
  }

  private getBannerMessage() {
    return getTranslation(
      `The GoTo app no longer supports your macOS version. For new GoTo updates, you'll need macOS 11.0 (Big Sur) or higher.`,
    )
  }

  private getBannerLinkText() {
    return getTranslation('Learn more')
  }

  private getClosable() {
    // If the user also has a trial banner, this banner must be closable
    if (canShowTrialBanner()) {
      return true
    }
    return false
  }

  render() {
    return html`<chameleon-banner ?closable=${this.getClosable()} @close=${() => this.chameleonBanner.remove()}>
      <chameleon-svg slot="icon">${unsafeSVG(SVG_APPLE_MONOCHROMATIC)}</chameleon-svg>
      <span>${this.getBannerMessage()}</span>
      <chameleon-link slot="action" href="${this.learnMoreLink}" target="_blank">
        ${this.getBannerLinkText()}
      </chameleon-link>
    </chameleon-banner>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-macos-support': MacOSSupportBanner
  }
}
