import { html } from 'lit'
import { property, state } from 'lit/decorators.js'
import { Presence, type PopoverPosition } from '@getgo/chameleon-core'
import { getShellLogger } from '../../common/logger'
import type { PresenceEvents, PresenceUpdated, IShellPresenceService, Status } from '../../services/presence'
import { PresenceServiceNamespace, convertPresenceToAppearance } from '../../services/presence'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { EventBus, Listener } from '../../services/namespaces'
import { t } from '../../directives/translate'
import { getAppearanceTranslationKey } from '../../common/presence-helpers'
import { ZIndex } from '../../common/z-index'
import { type GotoPresenceIndicatorComponent, GOTO_PRESENCE_INDICATOR } from '@goto/shell-common'
import { ShellElement } from '../../common/shell-element'

/**
 * @see react/presence-indicator/presence-indicator.shell.tsx
 */

export class GoToPresenceIndicator extends ShellElement implements GotoPresenceIndicatorComponent {
  static readonly tagName = GOTO_PRESENCE_INDICATOR
  @state() private presence = Presence.OFFLINE
  @state() private status: Status | undefined = undefined
  @property({ type: String }) externalUserKey = ''
  @property({ type: Boolean }) presenceSnapshot = false
  @property({ type: Boolean }) achromatic = false
  @property({ type: String }) readonly tooltipPosition: PopoverPosition = 'bottom-center'
  @property({ type: Boolean }) hidePresenceTooltip = false

  connectedCallback() {
    super.connectedCallback()
    if (this.externalUserKey) {
      this.loadUserPresence()
    }
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('externalUserKey')) {
      this.unsubscribe()
      this.loadUserPresence()
    }
  }

  private loadUserPresence() {
    const { eventBus, presence } = getShellApiInstance()
    if (this.presenceSnapshot && presence) {
      this.getPresenceSnapshot(presence)
    } else if (presence) {
      this.listenForPresenceUpdatedEvent(eventBus)
      this.subscribeToPresence(presence)
    }
  }

  private listenForPresenceUpdatedEvent(eventBus: EventBus) {
    const { presenceUpdate } = eventBus.subscribeTo<typeof PresenceServiceNamespace, typeof PresenceEvents>(
      PresenceServiceNamespace,
    )

    const presenceUpdatedHandler: Listener<PresenceUpdated> = (payload: PresenceUpdated) => {
      const { userKey, presence = Presence.OFFLINE, status } = payload
      const canUpdate = userKey === this.externalUserKey

      if (canUpdate) {
        this.presence = presence
        this.status = status
      }
    }

    presenceUpdate.on(presenceUpdatedHandler)

    this.unsubscribeFunctions.push(() => {
      presenceUpdate.removeListener(presenceUpdatedHandler)
    })
  }

  private subscribeToPresence(presence: IShellPresenceService) {
    if (presence) {
      presence
        .subscribe(this.externalUserKey)
        .then(() => {
          // https://jira.ops.expertcity.com/browse/SCAPI-46?focusedCommentId=205142
          this.unsubscribeFunctions.push(() => {
            presence.unsubscribe(this.externalUserKey)
          })
        })
        .catch(() => {
          getShellLogger().error(`failed to subscribe to presence for external user key ${this.externalUserKey}`)
        })
    }
  }

  private getPresenceSnapshot(presenceService: IShellPresenceService) {
    if (!presenceService) {
      this.presence = Presence.OFFLINE
      return
    }

    return presenceService.getPresenceSnapshots(this.externalUserKey).then(presenceSnapshot => {
      this.presence = presenceSnapshot.presence ?? Presence.OFFLINE
    })
  }

  private getTranslatedAppearanceLabel() {
    return getAppearanceTranslationKey(convertPresenceToAppearance(this.presence), false, this.status)
  }

  private getPresenceIndicatorTemplate() {
    return html`<chameleon-presence-indicator
      .presence=${this.presence}
      ?achromatic=${this.achromatic}
      label=${t(this.getTranslatedAppearanceLabel())}
      id="presence-indicator"
    ></chameleon-presence-indicator>`
  }

  private getChameleonTooltipTemplate() {
    return html`
      ${this.getPresenceIndicatorTemplate()}
      <chameleon-tooltip-v3
        delay="0"
        position="${this.tooltipPosition}"
        z-index="${ZIndex.tooltip}"
        trigger-id="presence-indicator"
      >
        ${t(this.getTranslatedAppearanceLabel())}
      </chameleon-tooltip-v3>
    `
  }

  render() {
    return html`${this.hidePresenceTooltip ? this.getPresenceIndicatorTemplate() : this.getChameleonTooltipTemplate()}`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-presence-indicator': GoToPresenceIndicator
  }
}
