/* istanbul ignore file */
import { html } from 'lit'
import type { SingleSpaLitProps } from '../../common/single-spa-lit'
import { singleSpaLit } from '../../common'
import { GOTO_CONTENT_SELECTOR } from './../../common/container'

const lifecycles = singleSpaLit<SingleSpaLitProps>({
  template: html`<goto-shell-landing-controller></goto-shell-landing-controller>`,
  domElement: GOTO_CONTENT_SELECTOR,
})

export default lifecycles
