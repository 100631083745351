import { html, nothing, type PropertyValues } from 'lit'
import { property, query, state } from 'lit/decorators.js'
import { t } from '../../../directives/translate'
import { ShellElement } from '../../../common/shell-element'
import scheduleCustomHoursModalStyles from './schedule-custom-hours-modal.styles.scss'
import {
  type ScheduleChangePayload,
  type OneTimePeriodWithId,
  type ShellOneTimePeriod,
} from '../schedule-manager/models'
import {
  formatISOTime,
  getListOfTimeForOneDayWithInterval,
  getNextValidEndTime,
  getValidEndTimeOptions,
  getCurrentLocalDateFormatted,
  getUserLocaleInDatePickerFormat,
  getMaximumCalendarEndDate,
  timeIntervalMinutes,
  getAllEndTimeOptions,
  initialTimeOfTheDay,
  lastTimeOfTheDay,
} from '../schedule-settings-utils'
import { type ChangeEvent } from 'react'
import { type CheckboxComponent } from '@getgo/chameleon-web'
import {
  hasAllRequiredCustomHoursFieldsFilled,
  convertOneTimePeriodWithIdToShellOneTimePeriod,
  convertShellOneTimePeriodToOneTimePeriodWithId,
  oneTimePeriodHasOverlap,
  createShellOneTimePeriod,
} from '../helpers'
import { getUserTimezone } from '../../../common/user-helpers'
import { getScheduleManager } from '../schedule-manager/schedule-manager'
import { objectsAreEqual } from '../../../core/helpers/compare'
import { ifDefined } from 'lit/directives/if-defined.js'

export interface NewOneTimePeriodEvent {
  readonly oneTimePeriod: OneTimePeriodWithId
}

export type ErrorType = 'name' | 'startTime' | 'endTime' | 'startDay' | 'endDay' | 'dateRange'

export const EDIT_OR_ADD_ONETIMEPERIOD = 'edit-or-add-onetimeperiod'
export const CLOSE_CUSTOM_HOURS_MODAL = 'close-custom-hours-modal'

export class GoToScheduleCustomHoursModal extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours-modal'
  @property({ type: Object }) set oneTimePeriod(oneTimePeriod: OneTimePeriodWithId) {
    this.originalShellOneTimePeriod = convertOneTimePeriodWithIdToShellOneTimePeriod(oneTimePeriod)
    this.shellOneTimePeriod = convertOneTimePeriodWithIdToShellOneTimePeriod(oneTimePeriod)
  }
  @state() private shellOneTimePeriod: ShellOneTimePeriod = createShellOneTimePeriod()
  @state() private endTimeOptions = getAllEndTimeOptions()
  @state() private isFormValid = false
  @state() private hasOverlap = false
  @state() private errors = {
    name: false,
    startTime: false,
    endTime: false,
    startDay: false,
    endDay: false,
    dateRange: false,
  }
  @query('.start-section') private startSection: HTMLElement | undefined
  @query('.end-section') private endSection: HTMLElement | undefined
  private startTimeOptions = getListOfTimeForOneDayWithInterval(timeIntervalMinutes)
  private originalShellOneTimePeriod: ShellOneTimePeriod | undefined = undefined
  private shellOneTimePeriods: ShellOneTimePeriod[] = []
  private previousStartTime: string = ''
  private previousEndTime: string = ''

  static get styles() {
    return scheduleCustomHoursModalStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToScheduleChanges()
  }

  protected firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties)
    // if a start time exists when the component is first rendered, hide the invalid end time options
    if (this.shellOneTimePeriod.startTime) {
      this.endTimeOptions = getValidEndTimeOptions(this.shellOneTimePeriod.startTime, this.endTimeOptions)
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties)
    if (changedProperties.has('shellOneTimePeriod')) {
      this.validateForm()
      this.adjustAlignment()
    }
  }

  private subscribeToScheduleChanges() {
    getScheduleManager().subscribe(this.handleUserScheduleChange)
    this.unsubscribeFunctions.push(() => getScheduleManager().unsubscribe(this.handleUserScheduleChange))
  }

  private handleUserScheduleChange = (payload: ScheduleChangePayload) => {
    this.shellOneTimePeriods = payload.userSchedule.oneTimePeriods.map(convertOneTimePeriodWithIdToShellOneTimePeriod)
  }

  private updateModifiedOneTimePeriodData(property: keyof ShellOneTimePeriod, value: string | boolean) {
    this.shellOneTimePeriod = { ...this.shellOneTimePeriod, [property]: value }
  }

  private updateError(property: ErrorType, value: boolean) {
    this.errors = { ...this.errors, [property]: value }
    this.adjustAlignment()
  }

  // adjusts the alignment of the flexbox items when there's an error helper text displayed
  private adjustAlignment() {
    if (this.errors.startDay || this.errors.startTime) {
      this.startSection?.classList.add('error-state')
    } else {
      this.startSection?.classList.remove('error-state')
    }
    if (this.errors.endDay || this.errors.endTime) {
      this.endSection?.classList.add('error-state')
    } else {
      this.endSection?.classList.remove('error-state')
    }
  }

  private adjustEndTime() {
    if (this.shellOneTimePeriod.startDay === this.shellOneTimePeriod.endDay) {
      // if the start time is after the end time, adjust the end time to the next available time and hide invalid end time options
      this.endTimeOptions = getValidEndTimeOptions(this.shellOneTimePeriod.startTime, this.endTimeOptions)
      this.updateModifiedOneTimePeriodData(
        'endTime',
        getNextValidEndTime(this.shellOneTimePeriod.startTime, this.shellOneTimePeriod.endTime, this.endTimeOptions),
      )
    } else {
      // reset the end time options if the start date is different from the end date
      this.endTimeOptions = getAllEndTimeOptions()
    }
  }

  // if the start date is after the end date, adjust the end date to match the start date
  private adjustEndDate() {
    if (this.shellOneTimePeriod.startDay > this.shellOneTimePeriod.endDay) {
      this.updateModifiedOneTimePeriodData('endDay', this.shellOneTimePeriod.startDay)
    }
  }

  private handleNameInputUpdate(event: Event) {
    this.updateModifiedOneTimePeriodData('name', (event.currentTarget as HTMLInputElement).value)
    this.updateError('name', !(event.currentTarget as HTMLInputElement).value)
  }

  private handleStartDateChange(event: ChangeEvent<HTMLInputElement>) {
    this.updateModifiedOneTimePeriodData('startDay', event.currentTarget.value)
    this.updateError('startDay', !event.currentTarget.value)
    this.adjustEndDate()
    this.adjustEndTime()
  }

  private handleEndDateChange(event: ChangeEvent<HTMLInputElement>) {
    this.updateModifiedOneTimePeriodData('endDay', event.currentTarget.value)
    this.updateError('endDay', !event.currentTarget.value)
    this.adjustEndTime()
  }

  private handleDateRangeChange(event: CustomEvent<{ start: string; end: string }>) {
    this.updateModifiedOneTimePeriodData('startDay', event.detail.start)
    this.updateModifiedOneTimePeriodData('endDay', event.detail.end)
    // There's currently a bug in the date range picker where clearing your selection will not trigger the change event.
    // Ticket in chameleon: https://jira.ops.expertcity.com/browse/CHAMELEON-3306
    this.updateError('dateRange', !event.detail.start || !event.detail.end)
  }

  private handleStartTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    this.updateModifiedOneTimePeriodData('startTime', event.currentTarget.value)
    this.updateError('startTime', !event.currentTarget.value)
    this.adjustEndTime()
  }

  private handleEndTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    this.updateModifiedOneTimePeriodData('endTime', event.currentTarget.value)
    this.updateError('endTime', !event.currentTarget.value)
    this.adjustEndTime()
  }

  private handleDNDCheckboxChange(event: ChangeEvent<CheckboxComponent>) {
    this.updateModifiedOneTimePeriodData('dndEnabled', event.target.checked)
  }

  private handleCancelClick() {
    this.dispatchEvent(new CustomEvent(CLOSE_CUSTOM_HOURS_MODAL))
  }

  private handleAlldayCheckboxChange(event: ChangeEvent<CheckboxComponent>) {
    //if the all day checkbox is checked, we save the previous times to be able to recover them if we uncheck the box
    if (event.target.checked) {
      this.previousStartTime = this.shellOneTimePeriod.startTime
      this.previousEndTime = this.shellOneTimePeriod.endTime
    }
    this.shellOneTimePeriod = event.target.checked
      ? {
          ...this.shellOneTimePeriod,
          allDay: true,
          startTime: initialTimeOfTheDay,
          endTime: lastTimeOfTheDay,
        }
      : {
          ...this.shellOneTimePeriod,
          allDay: false,
          startTime: this.previousStartTime,
          endTime: this.previousEndTime,
        }
  }

  private validateForm() {
    // verify if all the required fields are filled
    const requiredFieldsFilled = hasAllRequiredCustomHoursFieldsFilled(this.shellOneTimePeriod)
    // verify that the oneTimePeriod does not overlap with any other oneTimePeriod
    this.hasOverlap = oneTimePeriodHasOverlap(this.shellOneTimePeriod, this.shellOneTimePeriods)

    if (this.originalShellOneTimePeriod) {
      // verify if the original oneTimePeriod has been modified when editing
      const isDirty = !objectsAreEqual(this.shellOneTimePeriod, this.originalShellOneTimePeriod)
      this.isFormValid = requiredFieldsFilled && isDirty && !this.hasOverlap
    } else {
      this.isFormValid = requiredFieldsFilled && !this.hasOverlap
    }
  }

  private handleConfirmClick() {
    this.dispatchEvent(
      new CustomEvent<NewOneTimePeriodEvent>(EDIT_OR_ADD_ONETIMEPERIOD, {
        detail: { oneTimePeriod: convertShellOneTimePeriodToOneTimePeriodWithId(this.shellOneTimePeriod) },
      }),
    )
  }

  private getDatePickerHelperText(calendarType: 'start' | 'end') {
    if (calendarType === 'start') {
      return this.errors.startDay ? t('Enter a start date') : ''
    }
    if (calendarType === 'end') {
      return this.errors.endDay ? t('Enter an end date') : ''
    }
  }

  private renderSelectOption(value: string, displayValue: string, hidden?: boolean) {
    return html`<chameleon-option class=${hidden ? 'hidden' : ''} value=${value}>${displayValue}</chameleon-option>`
  }

  private renderCustomHoursNameInput() {
    return html`
      <div class="name-input-section">
        <chameleon-text-field
          placeholder=${t('Enter a name')}
          fullwidth
          value=${this.shellOneTimePeriod.name}
          @keyup=${this.handleNameInputUpdate}
          @change=${this.handleNameInputUpdate}
          ?error=${this.errors.name}
        >
          ${t('Custom hours name')}
          <span slot="helpertext" class="name-input-helper-text"
            >${this.errors.name ? t('Enter a name for your custom hours') : ''}</span
          >
        </chameleon-text-field>
      </div>
    `
  }

  private renderAllDayOrStartDateSection() {
    // There is a bug in the chameleon select component that causes the helper text to not update it's value dynamically.
    // We are keeping the logic here to update the helper text so that it can be used when the bug is fixed.
    // https://jira.ops.expertcity.com/browse/CHAMELEON-3293
    return html`
      <div class=${`${this.shellOneTimePeriod.allDay ? 'all-day-section' : 'start-section'} date-time-container`}>
        ${this.shellOneTimePeriod.allDay
          ? html`${this.renderDateRangePicker()}`
          : html` ${this.renderDatePicker('start')}
              <chameleon-select
                helper-text=${this.errors.startTime ? t('Enter a start time') : ''}
                in-dialog
                class="start-time-select"
                selected-value=${this.shellOneTimePeriod.startTime}
                @change=${this.handleStartTimeChange}
                ?error=${this.errors.startTime}
              >
                ${this.startTimeOptions.map(value => this.renderSelectOption(value, formatISOTime(value)))}
              </chameleon-select>`}
        <chameleon-checkbox
          class="all-day-checkbox"
          @change=${this.handleAlldayCheckboxChange}
          ?checked=${this.shellOneTimePeriod.allDay}
          >${t('All day')}</chameleon-checkbox
        >
      </div>
    `
  }

  private renderDateRangePicker() {
    // The oneTimePeriod must be at most one year long.
    // The max value cannot be used for now in the date range picker as it cannot dynamically calculate the max end date based on the start date while the calendar is opened.
    // Chameleon will add a maximun length attribute for this use case https://jira.ops.expertcity.com/browse/CHAMELEON-3250
    return html`
      <chameleon-date-range-picker
        input-label=${t('Date range')}
        locale=${getUserLocaleInDatePickerFormat()}
        timezone=${getUserTimezone()}
        calendar-toggle-label=${t('Toggle date range calendar')}
        min=${getCurrentLocalDateFormatted('date')}
        start=${this.shellOneTimePeriod.startDay}
        end=${this.shellOneTimePeriod.endDay}
        @change=${this.handleDateRangeChange}
        ?error=${this.errors.dateRange}
        helper-text=${this.errors.dateRange ? t('Enter a date range') : ''}
      ></chameleon-date-range-picker>
    `
  }

  private renderEndDateSection() {
    // There is a bug in the chameleon select component that causes the helper text to not update it's value dynamically.
    // We are keeping the logic here to update the helper text so that it can be used when the bug is fixed.
    // https://jira.ops.expertcity.com/browse/CHAMELEON-3293
    return html`
      <div class="end-section date-time-container">
        ${this.renderDatePicker('end')}
        <chameleon-select
          in-dialog
          class="end-time-select"
          selected-value=${this.shellOneTimePeriod.endTime}
          @change=${this.handleEndTimeChange}
          ?error=${this.errors.endTime}
          helper-text=${this.errors.endTime ? t('Enter an end time') : ''}
        >
          ${this.endTimeOptions.map(({ value, hidden }) =>
            this.renderSelectOption(value, formatISOTime(value), hidden),
          )}
        </chameleon-select>
      </div>
    `
  }

  private renderDatePicker(calendarType: 'start' | 'end') {
    return html`
      <chameleon-date-picker
        input-label=${calendarType === 'start' ? t('Start') : t('End')}
        class=${calendarType === 'start' ? 'start-date-picker' : 'end-date-picker'}
        locale=${getUserLocaleInDatePickerFormat()}
        timezone=${getUserTimezone()}
        calendar-toggle-label=${calendarType === 'start'
          ? t('Toggle start date calendar')
          : t('Toggle end date calendar')}
        min=${getCurrentLocalDateFormatted('date')}
        max=${calendarType === 'end' ? getMaximumCalendarEndDate(this.shellOneTimePeriod.startDay) : ''}
        value=${calendarType === 'start' ? this.shellOneTimePeriod.startDay : this.shellOneTimePeriod.endDay}
        @change=${calendarType === 'start' ? this.handleStartDateChange : this.handleEndDateChange}
        ?error=${calendarType === 'start' ? this.errors.startDay : this.errors.endDay}
        helper-text=${ifDefined(this.getDatePickerHelperText(calendarType))}
      ></chameleon-date-picker>
    `
  }

  private renderDNDSection() {
    return html` <div class="dnd-section">
      <chameleon-checkbox ?checked=${this.shellOneTimePeriod.dndEnabled} @change=${this.handleDNDCheckboxChange}
        >${t('Do not disturb during these hours')}</chameleon-checkbox
      >
      <chameleon-typography variant="caption-medium" color="type-color-secondary" class="dnd-description"
        >${t('Automatically turn off notifications for this time range.')}</chameleon-typography
      >
    </div>`
  }

  private renderAlert() {
    return this.hasOverlap
      ? html` <chameleon-alert-v2 variant="danger"
          >${t(`You can't set custom hours that overlap previously scheduled custom hours`)}</chameleon-alert-v2
        >`
      : nothing
  }

  render() {
    return html`
      <form>
        <chameleon-dialog open size="large">
          <div class="modal-header" slot="title">
            <chameleon-typography variant="heading-small">${t('Set your custom hours')}</chameleon-typography>
          </div>
          <div class="modal-body">
            ${this.renderAlert()}${this.renderCustomHoursNameInput()}${this.renderAllDayOrStartDateSection()}
            ${this.shellOneTimePeriod.allDay ? nothing : this.renderEndDateSection()}${this.renderDNDSection()}
          </div>
          <div class="modal-footer" slot="actions">
            <chameleon-button
              size="medium"
              @click=${this.handleConfirmClick}
              class="confirm-button"
              ?disabled=${!this.isFormValid}
              >${t('Confirm')}</chameleon-button
            >
            <chameleon-button size="medium" variant="tertiary" @click=${this.handleCancelClick} class="cancel-button"
              >${t('Cancel')}</chameleon-button
            >
          </div>
        </chameleon-dialog>
      </form>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours-modal': GoToScheduleCustomHoursModal
  }
}
