import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import hotkeySectionStyles from './keyboard-shortcut-section.styles.scss'
import type { HotkeyAction } from './keyboard-shortcut.models'
import { repeat } from 'lit/directives/repeat.js'
import { KeyCombinationValidator } from '../../services/keyboard-shortcut/keyboard-shortcut'

export class GoToKeyboardShortcutSection extends LitElement {
  static readonly tagName = 'goto-keyboard-shortcut-section'

  @property({ type: Array }) hotkeyActionList: HotkeyAction[] = []
  @property({ type: Object }) keyCombinationValidator: KeyCombinationValidator = new KeyCombinationValidator()

  static get styles() {
    return hotkeySectionStyles
  }

  render() {
    return html`
      <ul class="hotkey-section-list">
        ${repeat(
          this.hotkeyActionList,
          actionKey => actionKey.id + '_child',
          hotkeyAction =>
            html`<li>
              <goto-keyboard-shortcut-section-row
                .hotkeyAction=${hotkeyAction}
                .keyCombinationValidator=${this.keyCombinationValidator}
              ></goto-keyboard-shortcut-section-row>
            </li>`,
        )}
      </ul>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-keyboard-shortcut-section': GoToKeyboardShortcutSection
  }
}
