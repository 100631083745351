import { html, LitElement } from 'lit'
import { t } from '../../directives/translate'
import styles from './teams-mobile-landing-page.styles.scss'
import { getMobileOSDownloadInfo, isAndroidUser } from '../../common/user-agent-helpers'
import {
  SVG_DIAL_PAD_FILLED,
  SVG_MESSAGE_OUTLINED,
  SVG_PEOPLE_OUTLINED,
  SVG_PHONE_UP_OUTLINED,
} from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { getShellAnalytics } from '../../common/shell-api-helpers'
import { encodeObjectToUrlSafeBase64, getLocationOrigin, openNewTabFor } from '../../common/dom-helpers'
import { INTEGRATION_REDIRECT_PROXY_ROUTE } from '../../common/routes'
import { type IntegrationRedirectProxyData } from '../integration-redirect-proxy/integration-redirect-proxy'
import { getTranslation } from '../../services/i18n/i18nUtils'

export class TeamsMobileLanding extends LitElement {
  static readonly tagName = 'goto-teams-mobile-landing'

  static get styles() {
    return styles
  }

  private navigateToMobileApp = (route: string) => {
    this.trackMobileLandingNavigation(route)
    const url = isAndroidUser()
      ? `com.logmein.gotoconnect.android://navigate/${route}`
      : `com.logmein.gotoconnect://navigate/${route}`
    const description = getTranslation('Launching the GoTo mobile app')

    const data = {
      url,
      description,
    } as IntegrationRedirectProxyData

    const encodedData = encodeObjectToUrlSafeBase64(data)

    const params = new URLSearchParams({ data: encodedData })
    openNewTabFor(this.getIntegrationRedirectProxyUrl(params))
  }

  getIntegrationRedirectProxyUrl = (params?: URLSearchParams) => {
    const url = new URL(INTEGRATION_REDIRECT_PROXY_ROUTE, getLocationOrigin()).href
    return params ? `${url}?${params}` : url
  }

  handleDownloadMobileAppClick() {
    const { downloadUrl, type } = getMobileOSDownloadInfo()
    getShellAnalytics().track('GoTo > MS Teams > Download Mobile', { action: 'click', device: type })
    window.open(downloadUrl)
  }

  private trackMobileLandingNavigation(navLocation: string) {
    getShellAnalytics().track('GoTo > MS Teams > Mobile Landing', {
      action: 'click',
      event: `Navigate to mobile app: ${navLocation}`,
    })
  }

  render() {
    return html` <div class="landing-container">
      <div class="landing-content">
        <chameleon-typography variant="heading-small" class="container-title">
          ${t(`Call, message, and more on the GoTo mobile app`)}
        </chameleon-typography>
        <div class="grid-container">
          <div class="grid-item">
            <chameleon-button
              fullwidth=${true}
              size="xlarge"
              @click=${() => {
                this.navigateToMobileApp('Phone')
              }}
            >
              <chameleon-svg slot="start">${unsafeSVG(SVG_PHONE_UP_OUTLINED)}</chameleon-svg>
              ${t('Phone')}
            </chameleon-button>
          </div>
          <div class="grid-item">
            <chameleon-button fullwidth=${true} size="xlarge" @click=${() => this.navigateToMobileApp('Dialer')}>
              <chameleon-svg slot="start">${unsafeSVG(SVG_DIAL_PAD_FILLED)}</chameleon-svg>
              ${t('Dial')}
            </chameleon-button>
          </div>
          <div class="grid-item">
            <chameleon-button
              fullwidth=${true}
              size="xlarge"
              @click=${() => this.navigateToMobileApp('MessagingConversations')}
            >
              <chameleon-svg slot="start">${unsafeSVG(SVG_MESSAGE_OUTLINED)}</chameleon-svg>
              ${t('Messaging')}
            </chameleon-button>
          </div>
          <div class="grid-item">
            <chameleon-button fullwidth=${true} size="xlarge" @click=${() => this.navigateToMobileApp('Contacts')}>
              <chameleon-svg slot="start">${unsafeSVG(SVG_PEOPLE_OUTLINED)}</chameleon-svg>
              ${t('Contacts')}
            </chameleon-button>
          </div>
        </div>
      </div>
      <chameleon-typography variant="body-medium" class="container-description">
        ${t(`Don’t have the app? `)}
        <chameleon-link variant="body-medium" appearance="primary" @click=${this.handleDownloadMobileAppClick}
          >${t('Get it now')}</chameleon-link
        >
      </chameleon-typography>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-teams-mobile-landing': TeamsMobileLanding
  }
}
