/* istanbul ignore file
Tested in the child components - navigation-rail and navigation-bar
*/
import { state } from 'lit/decorators.js'
import { ShellElement } from '../../../common/shell-element'
import { type I18NEvents, I18NNamespace } from '../../../services/i18n'
import { getEventBus } from '../../../services/namespaces'
import { ShellNamespace, type shellEvents } from '../../../services/shell-namespace'
import { getBadgeNumbersAPI } from '../../../services/badge-numbers'
import { type NavigationItemUI } from '@goto/shell-common'
import { getTranslation } from '../../../services/i18n/i18nUtils'

export class GoToBaseNavigation extends ShellElement {
  @state() navigationItems: NavigationItemUI[] = []
  private unsubscribeBadgeEvents: (() => void)[] = []

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToRouteChangeEvent()
    this.subscribeToLocaleChangeEvent()
  }

  private subscribeToRouteChangeEvent(): void {
    const { routeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    routeChanged.on(this.rerenderNavrail)
    this.unsubscribeFunctions.push(() => routeChanged.removeListener(this.rerenderNavrail))
  }

  private subscribeToLocaleChangeEvent(): void {
    const { localeChanged } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)
    localeChanged.on(this.rerenderNavrail)
    this.unsubscribeFunctions.push(() => localeChanged.removeListener(this.rerenderNavrail))
  }

  protected subscribeToBadgeEvent(): void {
    this.unsubscribeFromAllBadgeEvents()
    this.navigationItems.map(item => {
      const listener = () => {
        this.rerenderNavrail()
      }
      getBadgeNumbersAPI().addEventListener('change', listener, { id: item.id, showInNavigation: true })
      this.unsubscribeBadgeEvents.push(() => {
        getBadgeNumbersAPI().removeEventListener('change', listener)
      })
    })
  }

  private unsubscribeFromAllBadgeEvents() {
    this.unsubscribeBadgeEvents.forEach(unsubscribe => unsubscribe())
    this.unsubscribeBadgeEvents = []
  }

  private readonly rerenderNavrail = () => {
    this.requestUpdate()
  }

  protected getBadgeCount(navItem: NavigationItemUI): number {
    return getBadgeNumbersAPI().getBadgeNumberValue({ id: navItem.id, showInNavigation: true })
  }

  /** There is some incompatibility between Chameleon component `<chameleon-badge-counter>` and lit-html 3.x. lit-html
   * rendering crashes on the next render after we provide a number bigger than 99. Example: 100 and then 101 -> crash.
   *
   * The crash is failing to set the `data` property on a null object, where the null object is supposed to be the
   * `startNode.nextSibling` and is assumed to be a Text node (in other words, it is the label after the lit marker
   * node). Once we set a number bigger than 99, `<chameleon-badge-counter>` makes modifications to the DOM to display
   * the string `99+` (instead of the original value), but this somehow results in the lit marker node being removed.
   * lit-html is sensitive to this and crashes on the next render (with the null pointer exception).
   *
   * By specifying innerText that is not convertible to a number ourselves (e.g. `99+`), than the code in question in
   * `<chameleon-badge-counter>` is not activated and the issue is avoided.
   *
   * A benefit of using a string provided by us is that it is localized.
   *
   * TODO: investigate deeper and/or file an issue with Chameleon team.
   */
  protected static badgeCountAsString(count: number): string {
    if (count > 99) {
      return getTranslation(`{{badgeCount}}+`, { badgeCount: 99 })
    } else {
      return count.toString()
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this.unsubscribeFromAllBadgeEvents()
  }
}
