import type { IGotoLoggerMessage, LogCategory, Logger, LoggingServiceConfig } from '@getgo/goto-logger'
import { LoggingService } from '@getgo/goto-logger'
import { nanoid } from 'nanoid'
import { environment, getCurrentEnvironment } from '../environments'
import { getLocationHREF } from './dom-helpers'

let loggingService: LoggingService | null = null

export const getLoggingService = () => {
  if (!loggingService) {
    loggingService = createLoggingService()
  }

  return loggingService
}

const createLoggingService = () => {
  const lsSessionId = nanoid()
  const lsConfig: Partial<LoggingServiceConfig> = {
    loggingUrl: environment().loggingServerUrl ?? '',
    fixed: {
      ls_source: 'goto-app',
      shell_entry_url: getLocationHREF(),
      env: getCurrentEnvironment(),
    },
  }

  const loggingServiceInstance = new LoggingService(lsConfig)
  loggingServiceInstance.field('ls_component', 'shell')
  loggingServiceInstance.field('ls_session_id', lsSessionId)

  return loggingServiceInstance
}

const allowedLogCategories: readonly LogCategory[] = ['error', 'warn', 'info', 'log']

export const attachToLoggingService = (logger: Logger) => {
  allowedLogCategories.forEach(logCategory => {
    const logHandler = (message: IGotoLoggerMessage) => {
      try {
        const logMessageWithContext = { ...logger.getContext(), ...message }
        const loggingService = getLoggingService()
        loggingService.log(logMessageWithContext)
      } catch (e) {
        // Do not use the logger to log the error, to avoid potential infinite loop.
        console.error(`LoggingService error`, e)
      }
    }

    logger.subscribe(logCategory, logHandler)
  })
}
