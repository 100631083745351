import { ShellElement } from './../../../common/shell-element'
import type { GlobalSearchResultUI } from './../global-search-models'
import { html, nothing } from 'lit'
import { property, query } from 'lit/decorators.js'
import searchResult from './search-result.styles.scss'
import { SVG_SEARCH_OUTLINED } from '@getgo/chameleon-icons'
import { asTranslationKey } from '../../../common/translate-helpers/i18n-utils'
import { repeat } from 'lit/directives/repeat.js'
import { NUMBER_OF_PREVIEW_ITEMS } from './samples'
import { t } from '../../../directives/translate'
import { getGlobalSearchManager } from '../global-search-manager'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import type { ListboxComponent } from '@getgo/chameleon-web'
import type { ListboxEvent } from '../search-field/global-search-field'
import { addToSearchHistory, executeSearchResultDefaultActionCommand } from '../helpers'
import { executeSearch } from '../namespace'
import { trackGlobalSearch } from '../analytics/analytics'

export interface SearchResultProps {
  readonly searchResults: readonly GlobalSearchResultUI[]
}
export class SearchResult extends ShellElement implements SearchResultProps {
  static readonly tagName = 'goto-search-result'

  @property({ type: String }) searchString = ''
  @property({ type: Array }) searchResults: SearchResultProps['searchResults'] = []
  @query('chameleon-listbox') private chameleonListbox: ListboxComponent | undefined

  private noResultFoundSearch = asTranslationKey('No results found')

  static get styles() {
    return searchResult
  }

  public getListbox() {
    return this.chameleonListbox
  }

  private handleActionClick(e: Event) {
    e.stopPropagation()
    this.dispatchEvent(new Event('item-click'))
  }

  private renderNoResultsActions() {
    const actions = getGlobalSearchManager().getActionsForSearchString(this.searchString, 'preview')
    return html`
      ${actions?.length
        ? html`<goto-global-search-actions
            @click=${this.handleActionClick}
            .actions=${actions}
            searchString=${this.searchString}
          ></goto-global-search-actions> `
        : nothing}
    `
  }

  private renderNoResultsFoundElement() {
    return html`
      <chameleon-listbox
        ><chameleon-listbox-item>
          <div class="search-result-not-found search-result-not-found--header">
            <chameleon-typography color="type-color-disabled">${this.noResultFoundSearch}</chameleon-typography>
          </div>
          <div class="search-result-not-found  search-result-not-found--body">
            <chameleon-svg slot="start" class="right">${unsafeSVG(SVG_SEARCH_OUTLINED)}</chameleon-svg>
            <chameleon-typography color="type-color-secondary" class="center">
              ${this.searchString}
            </chameleon-typography>
            ${this.renderNoResultsActions()}
          </div></chameleon-listbox-item
        ></chameleon-listbox
      >
    `
  }

  private searchResultsPreviewElement() {
    return this.searchResults.length <= NUMBER_OF_PREVIEW_ITEMS + 1
      ? this.renderSearchResultsPreviewList(this.searchResults)
      : this.renderSearchResultsPreviewListWithMore()
  }

  private renderSearchResultsPreviewList(searchResults: readonly GlobalSearchResultUI[]) {
    return html`<chameleon-listbox>
      ${repeat(
        searchResults,
        result => result.title,
        result => this.renderSearchResultPreviewItem(result),
      )}</chameleon-listbox
    >`
  }

  private handleMoreResultsClick(e: UIEvent) {
    const eventType = e.detail ? 'click' : 'keydown'
    trackGlobalSearch({
      searchString: this.searchString,
      eventName: 'GoTo > Global Search Result Preview View More',
      eventType,
      properties: {},
    })
    const query = { query: this.searchString }
    executeSearch(query)
    this.dispatchEvent(new Event('item-click'))
  }

  private renderSearchResultsPreviewListWithMore() {
    const shortenedPreviewList = this.searchResults.slice(0, NUMBER_OF_PREVIEW_ITEMS)
    return html`<chameleon-listbox>
      ${repeat(
        shortenedPreviewList,
        result => result.title,
        result => this.renderSearchResultPreviewItem(result),
      )}<chameleon-listbox-item class="more-results-listbox-item" @click=${this.handleMoreResultsClick}>
        <chameleon-button class="more-results-button" variant="tertiary" size="small"
          >${t('View more results')} (${this.searchResults.length - NUMBER_OF_PREVIEW_ITEMS})</chameleon-button
        >
      </chameleon-listbox-item></chameleon-listbox
    >`
  }

  private handleResultClick(e: UIEvent, result: GlobalSearchResultUI) {
    const eventType = e.detail ? 'click' : 'keydown'
    trackGlobalSearch({
      searchString: this.searchString,
      eventName: 'GoTo > Global Search Result Preview Item',
      eventType,
      action: result.defaultAction,
      searchResult: result.originalResult,
      properties: {},
    })
    addToSearchHistory({ query: result.title })
    executeSearchResultDefaultActionCommand(result, this.searchString)
    this.dispatchEvent(new Event('item-click'))
  }

  private handlePreviewItemActionClick() {
    this.dispatchEvent(new Event('item-click'))
  }

  private renderSearchResultPreviewItem(result: GlobalSearchResultUI) {
    return html` <chameleon-listbox-item @click=${(e: UIEvent) => this.handleResultClick(e, result)}>
      <goto-search-result-preview-item
        @preview-item-action-click=${this.handlePreviewItemActionClick}
        .searchResult=${result}
        .searchString=${this.searchString}
      ></goto-search-result-preview-item>
    </chameleon-listbox-item>`
  }

  protected updated(): void {
    this.dispatchEvent(new CustomEvent<ListboxEvent>('listboxrendered', { detail: { listbox: this.getListbox() } }))
  }

  render() {
    return html`
      ${this.searchResults.length ? this.searchResultsPreviewElement() : this.renderNoResultsFoundElement()}
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-search-result': SearchResult
  }
}
