import { html, LitElement } from 'lit'
import { state } from 'lit/decorators.js'
import { getBrandValue, getBrandAssetsPath } from '../../../services/branding/brand-helpers'
import { BrandVariationKeys } from '../../../services/feature-flags/models'
import elementStyles from './topbar-logo.styles.scss'

export class TopbarLogo extends LitElement {
  static readonly tagName = 'topbar-logo'
  @state() brandLogoUrl = getBrandAssetsPath(getBrandValue(BrandVariationKeys.TOPBAR_LOGO_ID))

  static get styles() {
    return [elementStyles]
  }

  render() {
    return html` <chameleon-goto-logo>
      <img alt="" src="${this.brandLogoUrl}" />
    </chameleon-goto-logo>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'topbar-logo': TopbarLogo
  }
}
