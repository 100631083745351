/*
  A helper to add LitHtml-based apps to single-spa
  Inspired from single-spa-html
  https://github.com/single-spa/single-spa-html
 */

import { type ChildPart, nothing, render, type TemplateResult } from 'lit'
import type { AppProps, LifeCycles } from 'single-spa'
import { removeNodes } from './dom-helpers'

export type TemplateGenerator<Props extends SingleSpaLitProps | undefined> = (props?: Props) => TemplateResult

interface SingleSpaLitOptions<Props extends SingleSpaLitProps> {
  readonly template: TemplateResult | TemplateGenerator<Props>
  readonly domElement?: string | HTMLElement | (() => HTMLElement | null | undefined)
}

export interface SingleSpaLitProps extends AppProps {
  readonly appName?: string
}

export function singleSpaLit<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>): LifeCycles<Props> {
  return {
    bootstrap: () => bootstrap(),
    mount: (props: Props) => mount(opts, props),
    unmount: (props: Props) => unmount(opts, props),
  }
}

/* istanbul ignore next not useful for unit test */
async function bootstrap() { }

async function mount<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>, props?: Props) {
  const domEl = domElementGetter(opts, props)

  // Two things to consider here:
  // 1. Sometimes the container we receive to render into is not empty and Lit rendering does not clear it for us. So we
  //    have to clear it first. For example, the pulsing logo (`<chameleon-launch-screen>`) should be erased when
  //    mounting the landing page/app.
  // 2. Even without the clearing above, other mount methods (like `singleSpaHtml` and `singleSpaReact`) are clearing
  //    the container and thus the Lit marker element on unmount, thus breaking the Lit state. So we have to restore the
  //    Lit state.
  removeNodes(domEl, domEl.firstChild)
  restoreLitState(domEl)

  render(typeof opts.template === 'function' ? opts.template(props) : opts.template, domEl)
}

async function unmount<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>, props?: Props) {
  // The intent is that `unmount` uses the exact same container used for mounting, but there is a small chance that this
  // is not the case. A few unlikely but possible situations where this may happen:
  //  - if `opts.domElement` specifies an ID selector and the container used for mounting has changed or erased its ID
  //    attribute
  //  - if `opts.domElement` is a function and provides a different element
  // If we want to enforce that, maybe the `mount` and `unmount` functions should be stateful and keep the element used
  // for mounting.
  const domEl = domElementGetter(opts, props)

  // Rendering `nothing` here is important. If we just clear the container via `removeNodes(domEl, domEl.firstChild)`,
  // Lit will still remember the previously rendered template from `mount` in its cache and will not re-render it if we
  // mount the same element again (without rendering another Lit template in between).
  render(nothing, domEl)
}

/** Restores the Lit state in the given container.
 *
 * Why is this needed?
 * 
 * TLDR: Lit stores state in the container element (property `_$litPart$`) and also uses marker elements (comment
 * elements `<!---->`). It expects its state to be coherent. We reuse the same container element for mounting different
 * `single-spa` apps and they use different methods to mount in (populate) the container. These different methods clear
 * the marker elements, thus breaking the Lit state. Additionally, we need to clear the container element on mount as
 * well, as Lit rendering in it won't do it and sometimes we receive containers with children.
 *
 * Details: See https://github.com/lit/lit/blob/main/dev-docs/design/how-lit-html-works.md#2-render for how Lit
 * rendering works.
 *
 * Additional observations that may not mentioned in the article above:
 *  - On first render in a container, Lit stores a reference to a `ChildNode` object in the `_$litPart$` property of the
 *    container element
 *  - It also creates a `startNode` marker element inside the container (comment `<!---->`) which is used to insert
 *    content after it
 *  - The `ChildNode` object keeps a pointer to this `startNode` marker element
 *  - On subsequent renders in the same container, Lit checks the `_$litPart$` property to get the `ChildNode` object
 *  - It uses the `startNode` reference inside the `ChildNode` object and its parent `startNode.parentNode` to insert
 *    the rendered content in the parent (`startNode.parentNode`) after the `startNode`
 *  - When the marker element `<!---->` (`startNode`) has been cleared by others apps using non-Lit mounting methods,
 *    it becomes a detached node (its `parentNode` is `null`), which leads to a crash in Lit
 *    - Note how Lit is using `startNode.parentNode` instead of the given container directly and how it is sensitive to
 *      `startNode` being in the container
 *  - Lit supports a `renderBefore` render option, which changes the referencing logic above, but it has its own cons.
 *
 * To fix this issue, we are restoring the `startNode` marker element (pointed by the `ChildNode` object) to be a child
 * of the given container. The code below assumes things about the Lit internals, but they are also somewhat documented
 * in the link above and the alternative solutions are not better I think.
 *
 * Potential alternative solutions:
 *  - Use stateful `mount` and `unmount` functions (the functions given to `single-spa`) that keep a reference of the
 *    `startNode` (can be obtained by the `RootPart` object returned by the Lit `render()` function). Then directly
 *    restore it in the container instead of looking up the `_$litPart$` property. Chose not to do this because of the
 *    state-keeping and due to keeping a reference to a node, which has potential for memory retention. The `startNode`
 *    would otherwise be garbage-collected if the container element is removed from the DOM tree and destroyed.
 *  - Use the `renderBefore` option in Lit by creating a new marker element manually and giving it to Lit. Lit will
 *    then use it to store the `_$litPart$` property. Then the `ChildNode` object would keep a reference to the
 *    `endNode` marker, which is the marker element we created and passed as `renderBefore` option. This creates a
 *    circular reference issue (`endNode['_$litPart$'].endNode == endNode`) which would need to be broken up manually on
 *    `unmount`. Chose not to do this due being more complex and still requiring knowledge about Lit internals.
 * 
 * Note that none of these issues exist if a container is used only for Lit rendering.
 */
function restoreLitState(domEl: HTMLElement) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const litPart: ChildPart | undefined = (domEl as any)['_$litPart$']
  if (litPart && litPart.startNode && litPart.startNode.parentNode !== domEl) {
    domEl.appendChild(litPart.startNode)
  }
}

function domElementGetter<Props extends SingleSpaLitProps>(
  opts: SingleSpaLitOptions<Props>,
  props?: Props,
): HTMLElement {
  const name = props?.appName ?? props?.name

  if (!name) {
    throw Error(
      `single-spa-lit was not given an application name as a prop, so it can't make a unique dom element container for the lit application`,
    )
  }
  const htmlId = `single-spa-application:${name}`

  let domElement: HTMLElement | null | undefined
  if (opts.domElement instanceof HTMLElement) {
    domElement = opts.domElement
  } else if (opts.domElement instanceof Function) {
    domElement = opts.domElement()
  } else if (opts.domElement) {
    domElement = document.querySelector<HTMLElement>(opts.domElement)
  }

  if (!domElement) {
    domElement = document.getElementById(htmlId)
  }
  if (!domElement) {
    domElement = document.createElement('div')
    document.body.appendChild(domElement)
  }

  if (!domElement.id) {
    domElement.id = htmlId
  }

  return domElement
}
