/* istanbul ignore file */
import { noChange } from 'lit'
import { AsyncDirective, directive  } from 'lit/async-directive.js'

// helper from lit https://lit.dev/docs/templates/custom-directives/#async-directives
class ResolvePromise extends AsyncDirective {
  render(promise: Promise<unknown>) {
    promise
      .then((resolvedValue: unknown) => {
        // Rendered asynchronously.
        this.setValue(resolvedValue)
      })
      .catch((err: unknown) => {
        // Rendered asynchronously.
        this.setValue(err)
      })

    // Rendered synchronously - the immediate render causes no changes to the DOM.
    return noChange
  }
}
export const resolvePromise = directive(ResolvePromise)
