import { LocalStorageKeys } from '../../environments'

/**
 * Interface to describe the Instance for User Preferences service
 */
export interface ShellUserPreferencesInstance extends UserPreferencesAPI {}

export type UserPreferences = any

export const LOCAL_STORAGE_KEY = LocalStorageKeys.userPreferences

export const USER_ACCOUNT_PREFERENCES_KEY = LocalStorageKeys.userAccountPreferences

/**
 * Interface to describe the User Preference Updated Event Payload
 */
export interface UserPreferenceUpdated {
  readonly path: string
  readonly data: any
}

/**
 * Interface to describe the User Preferences API
 */
export interface UserPreferencesAPI {
  /**
   * @description retrieves ALL of the User Preferences from the connected model
   */
  getPreferences(): Promise<UserPreferences>
  /**
   * @description retrieves A SPECIFIC User Preference from the connected model
   * @param path - the reference to the specific User Preference to be retrieved
   * @param defaultValue - the default value to return if no User Preference is found
   */
  getPreference <T, R>(path: string, defaultValue?: T): Promise<R>
  /**
   * @description sets or updates A SPECIFIC User Preference in the connected model
   * @param path - the reference to the specific User Preference to be set or updated
   * @param data - the data to be set
   */
  setPreference<T>(path: string, data: T): Promise<void>
  /**
   * @deprecated - this method is not recommended for use and will be removed in the future.
   * @description removes A SPECIFIC User Preference from the connected model
   * @param path - the reference to the specific User Preference to be removed
   */
  deletePreference(path: string): Promise<void>
}

export type UserPreferencesConnectorParams = { readonly apiUrl: string }
