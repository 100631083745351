import { ShellElement } from '../../../common/shell-element'
import { html, nothing } from 'lit'
import { state } from 'lit/decorators.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import {
  SVG_ADD_FILLED,
  SVG_ALERT_OUTLINED,
  SVG_CLOSE_CIRCLE_OUTLINED,
  SVG_REFRESH_FILLED,
} from '@getgo/chameleon-icons'
import { t } from '../../../directives/translate'
import { repeat } from 'lit/directives/repeat.js'
import { listOfDays, type ScheduleChangePayload, type WorkPeriod } from '../schedule-manager/models'
import { getScheduleManager } from '../schedule-manager/schedule-manager'
import scheduleWorkHoursModalStyles from './schedule-work-hours-modal.styles.scss'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import { dateTimeIntervalsOverlap, isWorkPeriod, newDateTime } from '../schedule-settings-utils'
import { getTranslation } from '../../../services/i18n/i18nUtils'
import { defaultWorkScheduleTime, createDefaultWorkPeriods } from '../schedule-settings-models'
import { cloneDeep } from '../../../core/helpers/clone'

export interface ModifiedWorkHoursEvent {
  readonly modifiedWorkPeriods: WorkPeriod[]
}

export const MODIFIED_WORK_PERIODS = 'modified-work-periods'
export const CLOSE_WORK_HOURS_MODAL = 'close-work-hours-modal'

export class GoToScheduleWorkHoursModal extends ShellElement {
  static readonly tagName = 'goto-schedule-work-hours-modal'

  @state() workPeriods: Partial<WorkPeriod>[] = []
  @state() errorRowIndices: number[] = []
  @state() maxWorkPeriodsPerDayExceeded: boolean = false

  static get styles() {
    return scheduleWorkHoursModalStyles
  }

  connectedCallback() {
    super.connectedCallback()
    getScheduleManager().subscribe(this.handleUserScheduleChange)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    getScheduleManager().unsubscribe(this.handleUserScheduleChange)
  }

  render() {
    return html`
      <chameleon-dialog open size="large">
        <div class="modal-header" slot="title">
          <chameleon-typography class="modal-header-title" variant="heading-small"
            >${t('Edit your work schedule')}</chameleon-typography
          >
          ${this.renderAlert()}
        </div>
        <div class="modal-scrollable-content">${this.renderWorkPeriods()}${this.renderAddRow()}</div>
        ${this.renderActions()}
      </chameleon-dialog>
    `
  }

  private addPlaceholderWorkPeriodIfEmpty() {
    if (this.workPeriods.length === 0) {
      this.addWorkPeriod()
    }
  }

  private handleUserScheduleChange = (payload: ScheduleChangePayload) => {
    this.workPeriods = cloneDeep(payload.userSchedule.workPeriods)
    this.addPlaceholderWorkPeriodIfEmpty()
  }

  private updateWorkPeriod(workPeriod: WorkPeriod, index: number) {
    this.workPeriods[index] = workPeriod
    this.updateWorkPeriodErrors()
    this.verifyMaxPeriodsPerDay()
  }

  private deleteWorkPeriod(deletedRowIndex: number) {
    const newWorkPeriods = [...this.workPeriods]
    newWorkPeriods.splice(deletedRowIndex, 1)
    this.workPeriods = cloneDeep(newWorkPeriods)
    this.addPlaceholderWorkPeriodIfEmpty()
    this.updateWorkPeriodErrors()
    this.verifyMaxPeriodsPerDay()
  }

  private addWorkPeriod() {
    this.workPeriods = [
      ...this.workPeriods,
      {
        dayOfWeek: undefined,
        startTime: defaultWorkScheduleTime.start,
        endTime: defaultWorkScheduleTime.end,
      },
    ]
  }

  private workPeriodHasError(workPeriodToCheckIndex: number) {
    return this.workPeriods.some(
      (workPeriod, index) =>
        workPeriodToCheckIndex !== index &&
        this.workPeriodHasOverlap(this.workPeriods[workPeriodToCheckIndex], workPeriod),
    )
  }

  private updateWorkPeriodErrors() {
    const errorRowIndicesSet: Set<number> = new Set()
    this.workPeriods.forEach((_workPeriod, index) => {
      if (this.workPeriodHasError(index)) {
        errorRowIndicesSet.add(index)
      } else {
        errorRowIndicesSet.delete(index)
      }
    })
    this.errorRowIndices = Array.from(errorRowIndicesSet)
  }

  private workPeriodHasOverlap(newWorkPeriod: Partial<WorkPeriod>, workPeriod: Partial<WorkPeriod>) {
    const existingWorkPeriodStartTime = newDateTime(workPeriod.startTime ?? '')
    const existingWorkPeriodEndTime = newDateTime(workPeriod.endTime ?? '')
    const newWorkPeriodStartTime = newDateTime(newWorkPeriod.startTime ?? '')
    const newWorkPeriodEndTime = newDateTime(newWorkPeriod.endTime ?? '')

    const workPeriodsAreOnSameDay = workPeriod.dayOfWeek === newWorkPeriod.dayOfWeek

    if (
      workPeriodsAreOnSameDay &&
      existingWorkPeriodStartTime &&
      existingWorkPeriodEndTime &&
      newWorkPeriodStartTime &&
      newWorkPeriodEndTime
    ) {
      return dateTimeIntervalsOverlap(
        existingWorkPeriodStartTime,
        existingWorkPeriodEndTime,
        newWorkPeriodStartTime,
        newWorkPeriodEndTime,
      )
    }
    return false
  }

  private verifyMaxPeriodsPerDay() {
    this.maxWorkPeriodsPerDayExceeded = false
    listOfDays.forEach(day => {
      const workPeriodsPerDay = this.workPeriods.reduce(
        (acc, workPeriod) => acc + (workPeriod.dayOfWeek === day ? 1 : 0),
        0,
      )

      if (workPeriodsPerDay > 20) {
        this.maxWorkPeriodsPerDayExceeded = true
        return
      }
    })
  }

  private handleCancelClick() {
    this.dispatchEvent(new CustomEvent(CLOSE_WORK_HOURS_MODAL))
  }

  private handleConfirmClick() {
    const validatedWorkPeriods = this.workPeriods.filter(workPeriod => isWorkPeriod(workPeriod))

    this.dispatchEvent(
      new CustomEvent<ModifiedWorkHoursEvent>(MODIFIED_WORK_PERIODS, {
        detail: { modifiedWorkPeriods: validatedWorkPeriods },
      }),
    )
  }

  private resetWorkHoursToDefault() {
    this.workPeriods = createDefaultWorkPeriods()
    this.errorRowIndices = []
  }

  private handleResetClick() {
    const modal = getShellApiInstance().display.modal({
      id: 'update-modal',
      title: getTranslation('Reset to default?'),
      closable: false,
      content: getTranslation('Your work schedule will be set to Monday through Friday, 9 AM to 5 PM.'),
      actions: [
        {
          label: getTranslation('Reset'),
          handler: () => {
            this.resetWorkHoursToDefault()
            modal.close()
          },
          size: 'medium',
        },
        {
          label: getTranslation('Cancel'),
          handler: () => {
            modal.close()
          },
          variant: 'tertiary',

          size: 'medium',
        },
      ],
    })
  }

  private renderWorkPeriodRow(workPeriod: WorkPeriod, index: number) {
    return html`
      <div class="modal-row">
        <goto-schedule-work-hours-modal-row
          ?error=${this.errorRowIndices.includes(index)}
          .currentWorkPeriod=${workPeriod}
          .change=${(w: WorkPeriod) => this.updateWorkPeriod(w, index)}
          .rowIndex=${index}
        ></goto-schedule-work-hours-modal-row>
        ${this.renderWorkPeriodDeleteButton(index)}
      </div>
    `
  }

  private renderWorkPeriodDeleteButton(index: number) {
    return this.workPeriods.length === 1 && this.noWorkHoursSet()
      ? nothing
      : html`<chameleon-icon-button id="delete-work-hours-row" @click=${() => this.deleteWorkPeriod(index)}
            ><chameleon-svg>${unsafeSVG(SVG_CLOSE_CIRCLE_OUTLINED)}</chameleon-svg></chameleon-icon-button
          ><chameleon-tooltip-v3 position="bottom-center" trigger-id="delete-work-hours-row"
            >${t('Delete')}
          </chameleon-tooltip-v3>`
  }

  private renderWorkPeriods() {
    return repeat(
      this.workPeriods,
      index => index,
      (workPeriod: WorkPeriod, index) => this.renderWorkPeriodRow(workPeriod, index),
    )
  }

  private renderAddRow() {
    return html`
      <chameleon-button class="add-row-button" variant="tertiary" @click=${() => this.addWorkPeriod()}>
        <chameleon-svg slot="start">${unsafeSVG(SVG_ADD_FILLED)}</chameleon-svg>
        ${t('Add time')}
      </chameleon-button>
    `
  }

  private renderActions() {
    return html` <div slot="actions" class="modal-footer">
      <div>
        <chameleon-button class="cancel-button" variant="tertiary" @click=${this.handleCancelClick}
          >${t('Cancel')}</chameleon-button
        >
        <chameleon-button
          ?disabled=${this.errorRowIndices.length > 0 || this.maxWorkPeriodsPerDayExceeded}
          class="confirm-button"
          @click=${this.handleConfirmClick}
          >${t('Confirm')}</chameleon-button
        >
      </div>
      <chameleon-button class="reset-button" variant="tertiary" @click=${this.handleResetClick}
        ><chameleon-svg slot="start">${SVG_REFRESH_FILLED}</chameleon-svg>${t('Reset to default')}</chameleon-button
      >
    </div>`
  }

  private noWorkHoursSet() {
    return this.workPeriods.every(workPeriod => !isWorkPeriod(workPeriod))
  }

  private renderAlert() {
    if (this.noWorkHoursSet()) {
      return html`<chameleon-alert-v2> ${t(`You have no weekly work hours.`)} </chameleon-alert-v2>`
    }
    if (this.errorRowIndices.length > 0) {
      return html` <chameleon-alert-v2 variant="danger" class="overlap-alert"
        ><chameleon-svg slot="icon">${SVG_ALERT_OUTLINED}</chameleon-svg>${t(
          'You can’t set overlapping hours as part of your schedule.',
        )}</chameleon-alert-v2
      >`
    }
    if (this.maxWorkPeriodsPerDayExceeded) {
      return html` <chameleon-alert-v2 variant="danger" class="maximum-rows-alert"
        ><chameleon-svg slot="icon">${SVG_ALERT_OUTLINED}</chameleon-svg>${t(
          'You can’t have more than 20 time ranges in a single day.',
        )}</chameleon-alert-v2
      >`
    }
    return nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-work-hours-modal': GoToScheduleWorkHoursModal
  }
}
