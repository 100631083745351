import { SVG_DOWNLOAD_OUTLINED } from '@getgo/chameleon-icons'
import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { t } from '../../../directives/translate/translate'
import updateButtonStyles from './update-chip.styles.scss'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

export class GoToUpdateChip extends LitElement {
  static readonly tagName = 'goto-update-chip'
  @property({ type: Number }) updateAge = 0

  static get styles() {
    return updateButtonStyles
  }

  render() {
    return html`<chameleon-chip-v2
      ><chameleon-svg slot="icon">${unsafeSVG(SVG_DOWNLOAD_OUTLINED)}</chameleon-svg>${t(
        'Update Available',
      )}</chameleon-chip-v2
    >`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-update-chip': GoToUpdateChip
  }
}
