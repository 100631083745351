import {
  SVG_CLOSE_FILLED,
  SVG_CHEVRON_LEFT_OUTLINED,
  SVG_CHEVRON_RIGHT_OUTLINED,
  SVG_SEARCH_OUTLINED,
} from '@getgo/chameleon-icons'
import { html, nothing } from 'lit'
import { state } from 'lit/decorators.js'
import { repeat } from 'lit/directives/repeat.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { ShellElement } from '../../../common/shell-element'
import { t } from '../../../directives/translate'
import { getEventBus } from '../../../services/namespaces/event-bus'
import { getGlobalSearchManager } from '../global-search-manager'
import {
  type GlobalSearchQueryUI,
  type GlobalSearchActionUI,
  type GlobalSearchCategoryUI,
  type GlobalSearchColumnUI,
  type GlobalSearchParams,
  type GlobalSearchResponse,
  type GlobalSearchResultUI,
} from '../global-search-models'
import {
  closeSearchPage,
  executeGlobalSearchCommand,
  getCurrentSearchQuery,
  getKeywordsFromSearchString,
} from '../helpers'
import type { globalSearchEvents, GlobalSearchQueryEventPayload } from '../namespace'
import { GLOBAL_SEARCH_NAMESPACE } from '../namespace'
import searchResultsPageStyles from './search-results-page.styles.scss'
import { getFromLocalStorage } from '../../../common/dom-helpers'
import { AvatarSize } from '../../../services/avatar/models'
import { trackGlobalSearch } from '../analytics/analytics'

const DEFAULT_ROWS_PER_PAGE = [25, 50, 100]

export const ROWS_PER_PAGE_OPTIONS = getRowsPerPageOptions() ?? DEFAULT_ROWS_PER_PAGE
export const LOADING_ROWS = 5

const DEFAULT_PAGE_DENOMINATOR = 1
const DEFAULT_PAGE_NUMERATOR = 1
const DEFAULT_NEXT_PAGE_MARKER = ROWS_PER_PAGE_OPTIONS[0] + 1

/**
 * To allow for testing page selectors on smaller results
 */
/* istanbul ignore next */
function getRowsPerPageOptions(): readonly number[] {
  const localStorageValue = getFromLocalStorage('search-results-rows-per-page')
  if (localStorageValue) {
    return JSON.parse(localStorageValue) ?? DEFAULT_ROWS_PER_PAGE
  }
  return DEFAULT_ROWS_PER_PAGE
}

export class SearchResultsPage extends ShellElement {
  static readonly tagName = 'goto-search-results-page'
  @state() private searchQuery: GlobalSearchQueryUI = { query: '' }
  @state() private searchResults: readonly GlobalSearchResultUI[] = []
  @state() private resultsPerPage: GlobalSearchResultUI[] = []
  @state() private selectedRowsPerPage = ROWS_PER_PAGE_OPTIONS[0]
  @state() private pageNumerator = DEFAULT_PAGE_NUMERATOR
  @state() private pageDenominator = DEFAULT_PAGE_DENOMINATOR
  @state() private nextPageMarker = DEFAULT_NEXT_PAGE_MARKER
  @state() private totalResults = 0
  @state() private searchError = false
  @state() loading = false
  @state() categories: readonly GlobalSearchCategoryUI[] = []

  private readonly categoryResults: Map<string, GlobalSearchResultUI[]> = new Map<string, GlobalSearchResultUI[]>()

  static get styles() {
    return searchResultsPageStyles
  }

  connectedCallback() {
    super.connectedCallback()
    if (getGlobalSearchManager().hasCategories()) {
      this.categories = getGlobalSearchManager().getCategories()
    }
    this.searchQuery = getCurrentSearchQuery()

    trackGlobalSearch({
      searchString: this.searchQuery.query,
      eventName: 'GoTo > Global Search Result Page View',
      eventType: 'load',
      properties: {},
    })

    const { resultPageQueryChange, categoriesUpdated } = getEventBus().subscribeTo<
      typeof GLOBAL_SEARCH_NAMESPACE,
      typeof globalSearchEvents
    >(GLOBAL_SEARCH_NAMESPACE)
    resultPageQueryChange.addListener(this.queryListener)
    categoriesUpdated.addListener(this.categoriesUpdatedListener)
    this.unsubscribeFunctions.push(() => {
      resultPageQueryChange.removeListener(this.queryListener)
      categoriesUpdated.removeListener(this.categoriesUpdatedListener)
    })
  }

  private readonly categoriesUpdatedListener = () => {
    this.categories = getGlobalSearchManager().getCategories()
  }

  private readonly queryListener = (event: GlobalSearchQueryEventPayload) => {
    this.searchQuery = event.query
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      if ((propName === 'searchQuery' || propName === 'categories') && this.searchQuery.query) {
        this.executeSearch()
      }
      if (propName === 'searchResults') {
        this.updateCategoryResults()
      }
    })
  }

  private executeSearch() {
    this.loading = true
    const params: GlobalSearchParams = this.convertPageSelectionsToQueryParams()
    getGlobalSearchManager()
      .searchFor(this.searchQuery, params)
      .then(this.handleSearchResponse)
      .catch(() => this.handleSearchError())
  }

  private readonly handleSearchResponse = (response: GlobalSearchResponse | undefined) => {
    this.loading = false
    this.searchError = false
    this.totalResults = response?.total?.value ?? this.totalResults
    this.searchResults = response?.items
      ? getGlobalSearchManager().convertToGlobalSearchResultUI(response.items, 'list')
      : []
    this.nextPageMarker = response?.nextPageMarker ?? this.nextPageMarker
    this.pageDenominator = this.calculatePageDenominator(this.totalResults)
  }

  private readonly handleSearchError = () => {
    this.searchResults = []
    this.loading = false
    this.searchError = true
  }

  private readonly calculatePageDenominator = (totalResults: number | undefined) => {
    if (totalResults) {
      return Math.ceil(totalResults / this.selectedRowsPerPage)
    }
    return this.pageDenominator
  }

  private readonly calculateNextPageMarker = () => {
    if (this.nextPageMarker - (this.pageNumerator - 1) * this.selectedRowsPerPage >= 1) {
      return (this.pageNumerator - 1) * this.selectedRowsPerPage
    } else {
      return this.nextPageMarker
    }
  }

  private readonly calculateCurrentPageMarker = () =>
    this.pageNumerator === DEFAULT_PAGE_NUMERATOR
      ? DEFAULT_PAGE_NUMERATOR
      : DEFAULT_PAGE_NUMERATOR + this.selectedRowsPerPage * (this.pageNumerator - 1)

  private updateCategoryResults() {
    this.categoryResults.clear()
    this.searchResults.forEach(result => {
      const category = getGlobalSearchManager().getCategory(result.originalResult)
      if (category) {
        const categoryResults = this.categoryResults.get(category.application)
        if (categoryResults) {
          categoryResults.push(result)
        } else {
          this.categoryResults.set(category.application, [result])
        }
      }
    })
    this.requestUpdate()
  }

  private executeDefaultActionCommand(e: UIEvent, result: GlobalSearchResultUI) {
    const eventType = e.detail ? 'click' : 'keydown'
    trackGlobalSearch({
      searchString: this.searchQuery.query,
      eventName: 'GoTo > Global Search Result Page Item',
      eventType,
      action: result.defaultAction,
      searchResult: result.originalResult,
      properties: {},
    })
    const defaultAction = result.defaultAction
    if (defaultAction?.command) {
      executeGlobalSearchCommand(defaultAction.command, this.searchQuery.query, result.originalResult.data)
    }
  }

  private renderTableHeader(columns: readonly GlobalSearchColumnUI[]) {
    return html`<chameleon-data-table-header-v2>
      <chameleon-data-table-row-v2 header>
        ${repeat(
          columns,
          column => column.id,
          column =>
            html`<chameleon-data-table-header-cell-v2 class="column-header"
              >${column.displayName}</chameleon-data-table-header-cell-v2
            >`,
        )}
      </chameleon-data-table-row-v2>
    </chameleon-data-table-header-v2>`
  }

  private renderTableBody(results: GlobalSearchResultUI[], columns: readonly GlobalSearchColumnUI[]) {
    this.resultsPerPage = [...results]
    if (this.selectedRowsPerPage < results.length) {
      this.resultsPerPage.length = this.selectedRowsPerPage
    }
    return html`<chameleon-data-table-body-v2>
      ${repeat(
        this.resultsPerPage,
        result =>
          html`<chameleon-data-table-row-v2
            class="table-body-data-row"
            @click=${(e: UIEvent) => this.executeDefaultActionCommand(e, result)}
            >${this.renderDataTableCells(result, columns)}</chameleon-data-table-row-v2
          >`,
      )}
    </chameleon-data-table-body-v2>`
  }

  private getKeywords(): string[] {
    return getKeywordsFromSearchString(this.searchQuery.query)
  }

  private renderDataTableCells(result: GlobalSearchResultUI, columns: readonly GlobalSearchColumnUI[]) {
    const keywords = this.getKeywords()
    return html`
      ${columns.map(column => {
        const value = getGlobalSearchManager().getResultFieldValue(result.originalResult, column.valueField) ?? ''
        if (columns.indexOf(column) === 0) {
          return html` <chameleon-data-table-cell-v2 class="table-body-data-cells with-avatar-or-icon"
            ><div class="cell-container">
              ${this.renderIconOrAvatar(result)}
              <goto-text-highlight
                ignoreCase
                ignoreAccents
                phoneNumbers
                .value=${value}
                .keywords=${keywords}
              ></goto-text-highlight></div
          ></chameleon-data-table-cell-v2>`
        }
        return html`<chameleon-data-table-cell-v2 class="table-body-data-cells"
          ><goto-text-highlight
            ignoreCase
            ignoreAccents
            phoneNumbers
            .value=${value}
            .keywords=${keywords}
          ></goto-text-highlight>
        </chameleon-data-table-cell-v2>`
      })}
    `
  }

  private renderIconOrAvatar(result: GlobalSearchResultUI) {
    return html`${result.userKey
      ? html`
          <goto-avatar
            showPresence
            .externalUserKey=${result.userKey}
            .familyName=${result.familyName}
            .givenName=${result.givenName}
            .size=${AvatarSize.MEDIUM}
            variant="primary"
            class="avatar-icon"
            label=${`${result.givenName} ${result.familyName}`}
            useChameleonAvatarV2
          ></goto-avatar>
        `
      : html`
          <chameleon-avatar-v2 .size=${AvatarSize.MEDIUM} class="round-icon" aria-hidden="true" label="">
            <chameleon-svg>${unsafeSVG(result.icon)}</chameleon-svg>
          </chameleon-avatar-v2>
        `}`
  }

  private renderRowsPerPageOptions() {
    return html`${repeat(
      ROWS_PER_PAGE_OPTIONS,
      result => html` <chameleon-option value=${result}>${result}</chameleon-option>`,
    )} `
  }

  private renderRowsPerPage() {
    return html`<div class="rows-per-page">
      <chameleon-typography variant="caption-medium-strong" color="type-color-secondary"
        >${t('Rows per page')}</chameleon-typography
      >
      <chameleon-select data-test="rows-per-page-select" @change=${this.handleSelectRows} size="small">
        ${this.renderRowsPerPageOptions()}
      </chameleon-select>
    </div>`
  }

  private renderPageSelector() {
    return html`<div class="page-selector">
      <chameleon-icon-button
        class="previous-page-button-data"
        disabled=${this.pageNumerator === DEFAULT_PAGE_NUMERATOR}
        @click="${() => {
          this.previousPage()
        }}"
        size="small"
        variant="primary"
        id="previous-page"
        label=${t('Previous page')}
      >
        <chameleon-svg>${unsafeSVG(SVG_CHEVRON_LEFT_OUTLINED)}</chameleon-svg>
      </chameleon-icon-button>
      <chameleon-tooltip-v3 trigger-id="previous-page"> ${t('Previous page')} </chameleon-tooltip-v3>
      <chameleon-typography variant="caption-medium-strong" color="type-color-secondary">
        ${t(`Page {{pageNumerator}} of {{pageDenominator}}`, () => ({
          pageNumerator: this.pageNumerator,
          pageDenominator: this.pageDenominator,
        }))}
      </chameleon-typography>

      <chameleon-icon-button
        class="next-page-button-data"
        disabled=${this.pageNumerator === this.pageDenominator}
        @click="${() => {
          this.nextPage()
        }}"
        size="small"
        variant="primary"
        id="next-page"
        label=${t('Next page')}
      >
        <chameleon-svg>${unsafeSVG(SVG_CHEVRON_RIGHT_OUTLINED)}</chameleon-svg>
      </chameleon-icon-button>
      <chameleon-tooltip-v3 trigger-id="next-page"> ${t('Next page')} </chameleon-tooltip-v3>
    </div>`
  }

  private renderPagerCount() {
    const firstResult = this.calculateCurrentPageMarker()
    const lastResult = Math.min(this.selectedRowsPerPage * this.pageNumerator, this.totalResults)

    return html`<div class="pager-count">
      <chameleon-typography variant="caption-medium-strong" color="type-color-secondary">
        ${t(`{{firstResult}} - {{lastResult}} of {{length}}`, () => ({
          firstResult: firstResult,
          lastResult: lastResult,
          length: this.totalResults,
        }))}
      </chameleon-typography>
      ${this.renderPageSelector()}
    </div>`
  }

  private readonly handleClick = () => {
    closeSearchPage()
  }

  private async handleSelectRows(event: CustomEvent) {
    const updatedValue = parseInt((event.target as HTMLInputElement).value)
    if (updatedValue !== this.selectedRowsPerPage) {
      this.selectedRowsPerPage = updatedValue
      this.pageNumerator = DEFAULT_PAGE_NUMERATOR
      this.requestUpdate('searchQuery')
    }
  }

  private async previousPage() {
    this.pageNumerator--
    this.requestUpdate('searchQuery')
  }

  private async nextPage() {
    this.pageNumerator++
    this.requestUpdate('searchQuery')
  }

  private convertPageSelectionsToQueryParams(): GlobalSearchParams {
    return {
      pageSize: this.selectedRowsPerPage,
      pageMarker: this.calculateNextPageMarker(),
    }
  }

  private getHeadingClass() {
    return this.searchError ? 'no-heading-text' : 'heading-text'
  }

  private renderSearchQueryHeading() {
    return html`<chameleon-typography variant="heading-small" class=${this.getHeadingClass()}>
        ${t('Search results for')} "${this.searchQuery.query}"
      </chameleon-typography>
      <chameleon-icon-button
        data-test="close-search-results-page"
        variant="primary"
        size="small"
        class="close-button"
        @click=${this.handleClick}
        label=${t('Close search results page')}
      >
        <chameleon-svg>${unsafeSVG(SVG_CLOSE_FILLED)}</chameleon-svg>
      </chameleon-icon-button>`
  }

  private renderLoadingTable() {
    const loadingItems: any[] = []
    for (let i = 0; i < LOADING_ROWS; i++) {
      loadingItems[i] = []
      this.categories[0].columns.map(column => {
        if (this.categories[0].columns.indexOf(column) === 0) {
          loadingItems[i].push(html`
            <chameleon-data-table-cell-v2 class="table-body-data-cells with-avatar-or-icon">
              <div class="cell-container">
                <goto-loading-state avatar size="small" iteration=${i}></goto-loading-state>
              </div>
            </chameleon-data-table-cell-v2>
          `)
        } else {
          loadingItems[i].push(html`
            <chameleon-data-table-cell-v2 class="table-body-data-cells">
              <goto-loading-state iteration=${i} size="small"></goto-loading-state>
            </chameleon-data-table-cell-v2>
          `)
        }
      })
    }

    return html`
      <chameleon-data-table-v2>
        ${this.renderTableHeader(this.categories[0].columns)}
        <chameleon-data-table-body-v2>
          ${repeat(
            loadingItems,
            result =>
              html`<chameleon-data-table-row-v2 class="table-body-data-row"> ${result} </chameleon-data-table-row-v2>`,
          )}
        </chameleon-data-table-body-v2>
      </chameleon-data-table-v2>
    `
  }

  private renderCategoryTableResults(results: GlobalSearchResultUI[]) {
    if (this.loading) {
      return this.renderLoadingTable()
    } else {
      return results.length
        ? html`<chameleon-data-table-v2>
            ${this.renderTableHeader(this.categories[0].columns)}
            ${this.renderTableBody(results, this.categories[0].columns)}
          </chameleon-data-table-v2>`
        : html`<chameleon-data-table-v2>
              ${this.renderTableHeader(this.categories[0].columns)}
            </chameleon-data-table-v2>
            ${this.renderNoResults()}`
    }
  }

  private renderNoResultsAction(action: GlobalSearchActionUI) {
    return html`<div class="no-results-action">
      <chameleon-button
        size="small"
        variant="tertiary"
        @click=${(e: UIEvent) => {
          this.handleNoResultsActionclick(e, action)
        }}
      >
        <chameleon-svg slot="start">${unsafeSVG(action.icon)}</chameleon-svg>
        ${action.displayName}
      </chameleon-button>
    </div>`
  }

  private handleNoResultsActionclick(e: UIEvent, action: GlobalSearchActionUI) {
    e.stopPropagation()
    const eventType = e.detail ? 'click' : 'keydown'
    trackGlobalSearch({
      searchString: this.searchQuery.query,
      eventName: 'GoTo > Global Search Result Page No Result Action',
      eventType,
      action,
      properties: {},
    })
    executeGlobalSearchCommand(action.command, this.searchQuery.query)
  }

  private renderNoResults() {
    return html`<chameleon-empty-state size="small">
      <chameleon-svg slot="icon">${unsafeSVG(SVG_SEARCH_OUTLINED)}</chameleon-svg>
      <div slot="actions">
        ${repeat(
          this.categories[0].emptyState.actions,
          action => action.id,
          action => this.renderNoResultsAction(action),
        )}
      </div>
      ${this.categories[0].emptyState.message}
    </chameleon-empty-state>`
  }

  renderResults() {
    // We are only getting one category for phase one. Logic will need to be adapted once we have the possibility of multiple categories
    const results = this.categoryResults.get(this.categories[0]?.application) ?? []
    return getGlobalSearchManager().hasCategories()
      ? html`<div class="search-query-heading">${this.renderSearchQueryHeading()}</div>
          <div class="results-table">${this.renderCategoryTableResults(results)}</div>
          <div class="results-table-pager pager">${this.renderRowsPerPage()} ${this.renderPagerCount()}</div>`
      : nothing
  }

  renderSearchError() {
    return html`<div class="search-query-heading">${this.renderSearchQueryHeading()}</div>
      <goto-search-error size="medium"></goto-search-error>`
  }

  render() {
    return html` ${this.searchError ? this.renderSearchError() : this.renderResults()} `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-search-results-page': SearchResultsPage
  }
}
